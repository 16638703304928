import React, { useEffect, useState } from 'react';
import loader from '../../methods/loader';
import ApiClient from '../../methods/api/apiClient';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const SmtpSetupFormReviews = ({ AddScreenHandler }) => {
    const user = useSelector(state => state.user);
    const history = useNavigate()
    const [formData, setFormData] = useState({
        service: '',
        host: '',
        username: '',
        password: '',
        port: '',
        domains: [''],
    });

    const [Eyes, setEyes] = useState(false)
    const [POSDATA, setPOSDATA] = useState([]);

    const ConnectionFinder = () => {
        try {
            const UserDomain = user?.email?.split("@");
            const filters = { status: "active", domains: UserDomain[1] || "" };
            ApiClient.get(`api/bookingsystem/listing`, filters, "", "", {
                apiCall: true,
            }).then((res) => {
                if (res.success) {
                    setPOSDATA(res?.data)
                }
            });
        } catch {
        }
    };
    useEffect(() => {
        ConnectionFinder()
    }, [])
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'domains') {
            const domains = value.split(',').map((domain) => domain.trim());
            setFormData({ ...formData, domains });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Submit the form data to your server
        let url = 'api/create/pos/smtp'
        let method = 'post';
        if (formData?.id) {
            url = 'api/update/pos/smtp'
            method = 'put'
        }
        ApiClient.allApi(url, formData, method).then(res => {
            if (res.success) {
                AddScreenHandler()
                swal({
                    icon: 'success',
                    text: 'SMTP Configuration saved successfully',
                    className: 'smallmodal'
                })
            }
        });

    };

    return (

        <div className="max-w-3xl mx-auto p-6 bg-white rounded-lg shadow-md">
            <div className="flex items-center justify-between gap-1 mb-6">
                <h4 className="text-2xl font-semibold text-gray-800">
                    SMTP Server Setup
                </h4>
            </div>
            <h2 className="text-xl font-medium text-center mb-6">Configure your SMTP settings</h2>
            <form onSubmit={handleSubmit} className="space-y-4">


                <div className="mb-4">
                    <label htmlFor="username" className="block text-sm font-medium text-gray-700">Service</label>
                    <select
                        id="username"
                        name="service"
                        value={formData?.service}
                        onChange={handleChange}
                        required
                        className="mt-1 p-3 w-full border border-gray-300 rounded-lg focus:ring-primary focus:border-primary"
                        placeholder="Your email address"
                    >
                        <option value="">Select service</option>
                        <option value={'Gmail'}>Google</option>
                        <option value={'Outlook'}>Outlook</option>
                        <option value={'Others'}>Others</option>

                    </select>
                </div>




                <div className="mb-4">
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData?.name}
                        onChange={handleChange}
                        required
                        className="mt-1 p-3 w-full border border-gray-300 rounded-lg focus:ring-primary focus:border-primary"
                        placeholder="e.g., smtp.gmail.com"
                    />

                </div>
                <div className="mb-4">
                    <label htmlFor="username" className="block text-sm font-medium text-gray-700">Pos System</label>
                    <select
                        id="posId"
                        name="posId"
                        value={formData?.posId}
                        onChange={handleChange}
                        required
                        className="mt-1 p-3 w-full border border-gray-300 rounded-lg focus:ring-primary focus:border-primary"
                        placeholder="Your email address"
                    >
                        <option value="">Select POS</option>
                        {POSDATA?.map((_itm) => (<option value={_itm?.id || _itm?._id} className='capitalize'>{_itm?.platform}</option>))}

                    </select>
                </div>
                {formData?.service == 'others' ? <div className="mb-4">
                    <label htmlFor="host" className="block text-sm font-medium text-gray-700">Host</label>
                    <input
                        type="text"
                        id="host"
                        name="host"
                        value={formData.host}
                        onChange={handleChange}
                        required
                        className="mt-1 p-3 w-full border border-gray-300 rounded-lg focus:ring-primary focus:border-primary"
                        placeholder="e.g., smtp.gmail.com"
                    />
                </div> : null}

                <div className="mb-4">
                    <label htmlFor="username" className="block text-sm font-medium text-gray-700">Username</label>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        required
                        className="mt-1 p-3 w-full border border-gray-300 rounded-lg focus:ring-primary focus:border-primary"
                        placeholder="Your email address"
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                    <div className='relative'>
                        <input
                            type={Eyes ? 'text' : "password"}
                            id="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                            className="mt-1 p-3 w-full border border-gray-300 rounded-lg focus:ring-primary focus:border-primary"
                            placeholder="Your email password"
                        />

                        {Eyes ? (
                            <FaEye
                                className="top-5 right-3 absolute text-gray-600 cursor-pointer"
                                onClick={() =>
                                    setEyes(!Eyes)
                                }
                            />
                        ) : (
                            <FaEyeSlash
                                className="top-5 right-3 absolute text-gray-600 cursor-pointer"
                                onClick={() =>
                                    setEyes(!Eyes)
                                }
                            />
                        )}
                    </div>
                </div>

                {formData?.service == 'others' ? <div className="mb-4">
                    <label htmlFor="port" className="block text-sm font-medium text-gray-700">Port</label>
                    <input
                        type="number"
                        id="port"
                        name="port"
                        min={1}
                        value={formData.port}
                        onChange={handleChange}
                        required
                        className="mt-1 p-3 w-full border border-gray-300 rounded-lg focus:ring-primary focus:border-primary"
                        placeholder="Port number"
                    />
                </div> : null}

                {formData?.service == 'others' ? <div className="mb-4">
                    <label htmlFor="domains" className="block text-sm font-medium text-gray-700">Domains</label>
                    <input
                        type="text"
                        id="domains"
                        name="domains"
                        value={formData.domains.join(', ')}
                        onChange={handleChange}
                        required
                        className="mt-1 p-3 w-full border border-gray-300 rounded-lg focus:ring-primary focus:border-primary"
                        placeholder="Comma-separated list of domains"
                    />
                </div> : null}

                <div className="flex justify-end mt-6 space-x-4">
                    <button
                        type="button"
                        onClick={() => AddScreenHandler()}
                        className="bg-white px-4 py-2 text-sm font-normal text-primary   rounded-lg shadow hover:bg-primary hover:text-white border border-primary transition-all focus:ring-2 focus:ring-primary disabled:bg-gray-200 disabled:cursor-not-allowed"
                    >
                        Back
                    </button>
                    <button
                        type="submit"
                        className="bg-primary px-4 py-2 text-sm font-normal text-white rounded-lg shadow hover:bg-primary-dark transition-all focus:ring-2 focus:ring-primary disabled:bg-primary-light disabled:cursor-not-allowed"
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>

    );
};

export default SmtpSetupFormReviews;
