export const rolePermissions = [
  { name: 'Dashboard', key: 'dashboard', parent: 'Dashboard' },
  { name: 'Chatbot', key: 'chatbot', parent: 'Customer Relations' },
  { name: 'Chat', key: 'chat', parent: 'Customer Relations' },
  { name: 'WhatsApp', key: 'whatsapp', parent: 'Customer Relations' },
  {
    name: 'Widget Settings',
    key: 'widget-settings',
    parent: 'Customer Relations'
  },
  {
    name: 'Review Insights',
    key: 'review-insights',
    parent: 'Reviews'
  },
  {
    name: 'Review Management',
    key: 'review-management',
    parent: 'Reviews'
  },
  {
    name: 'Reviews Settings',
    key: 'reviews-settings',
    parent: 'Reviews'
  },
  {
    name: 'Waiver Templates',
    key: 'waiver-templates',
    parent: 'Waivers'
  },
  {
    name: 'Waiver Settings',
    key: 'waiver-settings',
    parent: 'Waivers'
  },
  {
    name: 'Waiver Database',
    key: 'waiver-database',
    parent: 'Waivers'
  },
  { name: 'Sales Insights', key: 'sales-insights', parent: 'Sales' },
  { name: 'Sales Data', key: 'sales-data', parent: 'Sales' },
  {
    name: 'Customer Insights',
    key: 'customer-insights',
    parent: 'Sales'
  },
  { name: 'Customer Data', key: 'customer-data', parent: 'Sales' },
  { name: 'Historical Data', key: 'historical-data', parent: 'Sales' },
  {
    name: 'Refund/Cancel Data',
    key: 'refundcancel-data',
    parent: 'Sales'
  },
  {
    name: 'Price Management',
    key: 'price-management',
    parent: 'Price Management'
  },
  {
    name: 'Product Insights',
    key: 'product-insights',
    parent: 'Products'
  },
  { name: 'Product Data', key: 'product-data', parent: 'Products' },
  {
    name: 'Deleted Products',
    key: 'deleted-products',
    parent: 'Products'
  },
  {
    name: 'Historical Data',
    key: 'historical-data',
    parent: 'Products'
  },
  {
    name: 'Cancellation Policies',
    key: 'cancellation-policies',
    parent: 'Products'
  },
  { name: 'Update Logs', key: 'update-logs', parent: 'Products' },
  { name: 'Settings', key: 'settings', parent: 'Products' },
  { name: 'Budget', key: 'budget', parent: 'Products' },
  { name: 'Costing', key: 'costing', parent: 'Products' },
  {
    name: 'Resellers Insights',
    key: 'resellers-insights',
    parent: 'Reseller'
  },
  {
    name: 'List of Resellers',
    key: 'list-of-resellers',
    parent: 'Reseller'
  },
  {
    name: 'List of Invoices',
    key: 'list-of-invoices',
    parent: 'Reseller'
  },
  {
    name: 'Invoice Templates',
    key: 'invoice-templates',
    parent: 'Reseller'
  },
  {
    name: 'Contract Templates',
    key: 'contract-templates',
    parent: 'Reseller'
  },
  { name: 'Insights', key: 'insights', parent: 'Profitability' },
  {
    name: 'Profit Analysis - Month',
    key: 'profit-analysis-month',
    parent: 'Profitability'
  },
  {
    name: 'Profit Analysis - Date',
    key: 'profit-analysis-date',
    parent: 'Profitability'
  },
  {
    name: 'Profitability Data',
    key: 'profitability-data',
    parent: 'Profitability'
  },
  {
    name: 'Marketing Insights',
    key: 'marketing-insights',
    parent: 'Marketing'
  },
  { name: 'Forecasting', key: 'forecasting', parent: 'Marketing' },
  {
    name: 'Google Analytics Summary',
    key: 'google-analytics-summary',
    parent: 'Marketing'
  },
  { name: 'Google Ads', key: 'google-ads', parent: 'Marketing' },
  { name: 'Facebook Ads', key: 'facebook-ads', parent: 'Marketing' },
  {
    name: 'Instagram Analysis',
    key: 'instagram-analysis',
    parent: 'Marketing'
  },
  {
    name: 'Manage Google Tags',
    key: 'manage-google-tags',
    parent: 'Marketing'
  },

  {
    name: 'Affiliate DataBase',
    key: 'affiliate-dataBase',
    parent: 'Marketing'
  },
  {
    name: 'Current Affiliate',
    key: 'current-affiliate',
    parent: 'Marketing'
  },
  {
    name: 'Affiliate Request',
    key: 'affiliate-request',
    parent: 'Marketing'
  },


  {
    name: 'General Banners',
    key: 'general-banners',
    parent: 'Marketing'
  },

  {
    name: 'Products Banners',
    key: 'products-banners',
    parent: 'Marketing'
  },

  {
    name: 'Default Commission',
    key: 'default-commission',
    parent: 'Marketing'
  },

  {
    name: 'Inbox',
    key: 'inbox',
    parent: 'Marketing'
  },
  {
    name: 'Company Details',
    key: 'company-details',
    parent: 'Settings'
  },
  { name: 'Partners', key: 'partners', parent: 'Settings' },
  { name: 'Email List', key: 'email-list', parent: 'Settings' },
  {
    name: 'Email Templates',
    key: 'email-templates',
    parent: 'Settings'
  },
  {
    name: 'Data connection',
    key: 'data-connection',
    parent: 'Settings'
  },
  {
    name: 'Order Page Settings',
    key: 'booking-page-settings',
    parent: 'Settings'
  },
  { name: 'Users', key: 'users', parent: 'Settings' },
  { name: 'Roles', key: 'Roles', parent: 'Settings' },
  { name: 'Plan', key: 'plan', parent: 'Settings' },
  { name: 'Billing', key: 'billing', parent: 'Settings' }
]






export const rolePermission = [
  { name: "Read", key: 'read' },
  // {name:"Add",key:'add'},
  { name: "Edit", key: 'edit' },
  { name: "Delete", key: 'delete' },
]

export const roleGetAllKeys = () => {
  let keys = {};
  rolePermissions.map(itm => {
    rolePermission.map(pitm => {
      keys = { ...keys, [`${pitm.key}${itm.key}`]: false }
    })
  })

  return keys
}


export const userType = {
  id: '', fullName: '', role: '', subRole: '', email: '', mobileNo: '', dialCode: '', aboutUs: '', address: '', image: '', logo: '', social_profiles: [],
  aboutus: '',
  affiliation_types: [],
  isAffiliate: false,
  allowaffiliation: false,
  website: '', products: []
}
export const companyType = { id: '', companyName: '', addPartner: '', contactPerson: '', position: '', financialYear: '', bussinessNumber: '', companyDateFormat: '', currency: '', companyAddress: '', contactName: '', contactemail: '', companydialcode: '', companymobileno: '', companytimezone: '', productcategory: '', website: '', country: '', city: '', state: '', zipcode: '', lat: '', lng: '', isPerOrder: '', logo: '', companyCurrencyFormat: '', enableCountryDetection: true, notSignedReminder: '' }
export const CategoryType = { id: '', name: '', catType: '', subParentCategory: '', description: '', image: '', order: '', parentCategory: '', status: 'active', icon: '', banner: '', altImageName: '', altIconName: '', bannerOverlayHeading: '', bannerOverlayBody: '', description: '', featured: 'No', urlKey: '', metaTitle: '', metaDescription: '', keywords: '' }
export const roleType = { id: '', name: '', status: 'active', permissions: roleGetAllKeys() }
export const resellerCategoryType = { id: '', name: '', catType: 'Reseller', description: '', image: '' }
export const planType = { id: '', name: '', price: '', status: 'active', interval: 'Monthly', currencyId: '', category: '', recomended: '', feature: [] }
export const continentType = { id: '', name: '', status: 'active' }
export const featureType = { id: '', name: '', status: 'active' }
export const emailType = { id: '', subject: '', title: '', content: '', status: 'active' }
export const ReviewemailType = { id: '', subject: '', title: '', content: '', status: 'active', posId: null, type: '' }
export const cityType = { id: '', name: '', status: 'active', countryId: '', regionId: '', continent: '' }
export const bookingSystemType = { id: '', name: '', apiKey: '', secretKey: '' }
export const posType = { id: '', name: '', secretKey: '', apiKey: '', url: '', username: '', password: '', displayName: '', fareharborAppKey: '', fareharborUserKey: '', company_name: '', provider: '' }
export const crmType = { id: '', image: '', content: '', resellerSign: '', resellerType: '', sameAsAbove: false, resellerCollectData: [], body: [], yourSignature: [], resellerSignature: [], categoryToApply: '', products: [], showLogo: '', showAddress: '', address: '', internalName: '', externalName: '', firstSection: '', rateSheetName: '', rateSheet: '', allProducts: '', applyRateForAll: "", commisionRate: '', categoryToApply: [], applyRateForAllProducts: '', productCommisionRate: '', }
export const couponType = { id: '', title: '', status: 'active', amountOrPer: '', description: '', couponCode: '', usesPerCoupon: '', usesPerCustomer: '', dateFrom: '', dateTo: '', discountType: '', discountAmount: '', }
export const holidaysType = { id: '', name: '', status: 'active', discOrPre: '', type: '', amountOrPercent: '', number: '', applyFor: [], preOrPost: '', preDays: '', postDays: '', changesApply: '', changesDate: '', changesDateTo: '', country: '' }
export const resellerType = { id: '', name: "", logo: "", city: '', lat: '', lng: '', state: '', zipcode: '', companyName: '', website: '', trading: '', abn: '', position: '', category: "", contractDate: "", booking: "", pax: "", country: '', contactPerson: '', contactPhone: '', contactEmail: '', email: '', address: '' }
export const earlybirdpricingType = { id: '', name: '', discOrPre: '', onOfDays: '', priceTypes: [], status: 'active', changesDate: '', lastMinutePricingToDate: '', inventory: '', lastMinutePricingFromDate: '', applyPriceType: '', availablespaces: '', startDate: '', endDate: '', applyEarlyBirdPricing: [{}], changesDateTo: '', notApply: '', notApplyCondition: '', notApplicableFor: [], blackOutDates: [], amountOrPercent: '', number: '', applyToDaysTimeSlot: 'yes', daysToApply: [], timeSlots: [], country: '' }
