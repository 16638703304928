import React, { useEffect, useRef, useState } from "react";
import { DateRange } from "react-date-range";
import datepipeModel from "../../../models/datepipemodel";
import { useSelector } from "react-redux";
import "./style.scss";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { TiArrowSortedDown } from "react-icons/ti";
import { Tooltip } from "react-tooltip";
import { IoIosArrowDown } from "react-icons/io";
import moment from "moment";
import methodModel from "../../../methods/methods";

const DateRangePicker = ({
  value,
  onChange,
  disabled = false,
  placeholder = "",
  isCompare = false,
  fullWidth = true,
  showcustom = true,
  title = '',
  buttonShow = false,
  monthsCount = 2,
  disbaledDate = false,
  maxDate = null,
  allTime = false,
  outerClass = '',
  allTimeText = 'All Time'
}) => {
  const user = useSelector((state) => state.user);
  const [toggle, setToggle] = useState(false);
  const [CustomSelct, setCustomSelect] = useState(false)
  const [ItemsOpen, setItemsOpen] = useState(false);
  const [range, setRange] = useState(localStorage.getItem('selectRange'));

  const rangeList = disbaledDate ? [
    { id: "This Month", name: "This Month" },
    { id: "This Year", name: "This Year" },
  ] : [
    { id: "This Month", name: "This Month" },
    { id: "This Year", name: "This Year" },
    { id: "Last Month", name: "Last Month" },
    { id: "Last Year", name: "Last Year" },
  ];


  if (allTime) {
    rangeList.push({ id: "all", name: "All Time" },)
  }


  const compareList = [
    { id: "", name: "Reset Compare" },
    { id: "Previous Period", name: "Previous Period" },
    { id: "Previous Year(Same Date)", name: "Previous Year(Same Date)" },
  ];

  const blockDateChange = (e, close) => {
    setRange('custom')
    localStorage.setItem('selectRange', 'custom')
    onChange({ ...value, ...e, compare: "" });
    if (
      datepipeModel.datetostring(e.startDate) !=
      datepipeModel.datetostring(e.endDate)
    ) {
      setToggle(false);
    }
    if (String(e.startDate) != String(e.endDate)) {
      setItemsOpen(false)
    }

  };

  const toggleChange = () => {
    setToggle(!toggle);
  };

  const getBlockValue = () => {
    try {
      let v = {
        startDate: value["startDate"] ? new Date(value["startDate"]) : new Date(),
        endDate: value["endDate"] ? new Date(value["endDate"]) : new Date(),
        key: "selection",
      };
      return [v];
    } catch {
      return {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      };
    }
  };

  const rangeClick = (e) => {
    let startDate = "";
    let endDate = "";
    localStorage.setItem('selectRange', e)

    if (e == "Last Month") {
      let month1 = new Date();
      month1 = month1.setMonth(month1.getMonth() - 1);
      month1 = datepipeModel.datetostring(month1);

      let current = new Date();
      let monthdate;
      monthdate = datepipeModel.datetostring(monthdate);
      const year = current.getFullYear();
      const month = current.getMonth();
      const lastMonthStart = new Date(year, month - 1, 1);
      const lastMonthEnd = new Date(year, month, 0);
      startDate = `${lastMonthStart.getFullYear()}-${String(lastMonthStart.getMonth() + 1).padStart(2, "0")}-01`
      endDate = moment(lastMonthEnd).format('YYYY-MM-DD')

    } else if (e == "This Month") {
      let current = datepipeModel.datetostring(new Date());
      if (disbaledDate) {
        startDate = `${current.split("-")[0]}-${current.split("-")[1]}-${new Date().getDate()}`;
      }
      else {
        startDate = `${current.split("-")[0]}-${current.split("-")[1]}-01`;
      }

      let month2 = new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        0
      );
      endDate = datepipeModel.datetostring(month2);
    } else if (e == "This Year") {
      let current = datepipeModel.datetostring(new Date());
      if (disbaledDate) {
        startDate = `${current.split("-")[0]}-${new Date().getMonth() + 1}-${new Date().getDate()}`;
      } else {
        startDate = `${current.split("-")[0]}-01-01`;
      }

      let month2 = new Date(new Date().getFullYear() + 1, 0, 0);
      endDate = datepipeModel.datetostring(month2);
    } else if (e == "Last Year") {
      let current = new Date();
      startDate = `${current.getFullYear() - 1}-01-01`;
      let month2 = new Date(current.getFullYear(), 0, 0);
      endDate = datepipeModel.datetostring(month2);
    } else if (e == 'all') {
      startDate = ''
      endDate = ''
      setToggle(false);
    }
    setRange(e);
    if (e == 'custom' && !startDate && !endDate) {
    } else {
      setItemsOpen(false)
      onChange({ ...value, startDate, endDate, compare: "" });
    }

    if (e != "") {
      setToggle(false);
    }
  };

  const getDays = (s, e) => {
    // Define two date objects
    const startDate = new Date(s); // Replace with your start date
    const endDate = new Date(e); // Replace with your end date
    // Calculate the time difference in milliseconds
    const timeDifference = endDate - startDate;
    // Convert milliseconds to days
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference;
  };

  const previousYear = () => {
    let start = "";
    let end = "";
    if (value.startDate && value.endDate) {
      let ssplit = value.startDate.split("-");
      let esplit = value.endDate.split("-");
      let year = Number(ssplit[0]);
      let eyear = Number(esplit[0]);

      let d = new Date(`${year - 1}-${ssplit[1]}-${ssplit[2]}`);
      start = datepipeModel.datetostring(d);

      let ed = new Date(`${eyear - 1}-${esplit[1]}-${esplit[2]}`);
      end = datepipeModel.datetostring(ed);
    }
    return { start, end };
  };

  function getMonthDifference(date1, date2) {
    const year1 = date1.getFullYear();
    const month1 = date1.getMonth(); // 0-based (Jan = 0, Dec = 11)

    const year2 = date2.getFullYear();
    const month2 = date2.getMonth();

    return ((year2 - year1) * 12 + (month2 - month1)) + 1
  }

  const previousMonth = () => {
    let start = "";
    let end = "";

    if (value.startDate && value.endDate) {
      let startDate = new Date(value.startDate);
      let endDate = new Date(value.endDate);
      let newStartDate = new Date(startDate);
      newStartDate.setMonth(startDate.getMonth() - getMonthDifference(startDate, endDate));
      newStartDate.setDate(1);
      let newEndDate = new Date(endDate);
      newEndDate.setMonth(new Date(startDate).getMonth());
      if (newEndDate.getMonth() < newStartDate.getMonth() && newEndDate.getMonth() != 0) {
        newEndDate.setMonth(newEndDate.getMonth() - 1)
        newEndDate.setFullYear(new Date(startDate).getFullYear())
      } else {
        newEndDate.setFullYear(new Date(newStartDate).getFullYear())
      }
      newEndDate.setDate(0);
      start = datepipeModel.datetostring(newStartDate);
      end = datepipeModel.datetostring(newEndDate);
    }

    return { start, end };
  };




  const previousPeriod = () => {
    let start = "";
    let end = "";
    if (value.startDate && value.endDate) {
      let days = getDays(value.startDate, value.endDate) + 1;

      let d = new Date(value.startDate);
      d.setDate(d.getDate() - days);
      start = datepipeModel.datetostring(d);

      let ed = new Date(value.startDate);
      ed.setDate(ed.getDate() - 1);
      end = datepipeModel.datetostring(ed);
    }
    return { start, end };
  };

  const compareChange = (e) => {
    let start = "";
    let end = "";
    if (e == "Previous Period") {
      start = previousPeriod().start;
      end = previousPeriod().end;
    } else if (e == "Previous Year") {
      start = previousYear().start;
      end = previousYear().end;
    } else if (e == "Previous Month") {
      start = previousMonth().start;
      end = previousMonth().end;
    }

    let v = {
      ...value,
      compareStart: start,
      compareEnd: end,
      compare: e,
    };
    onChange({ ...v });
  };

  const ref = useRef();

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setItemsOpen(false)
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  const RandomNumber = Math.random(1000);


  const isRangeMatching = (rangeId) => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth(); // 0-indexed
    const startDate = value?.startDate || "";
    const endDate = value?.endDate || "";

    switch (rangeId) {
      case "Last Month": {
        const lastMonthStart = new Date(year, month - 1, 1); // First day of last month
        const lastMonthEnd = new Date(year, month, 0); // Last day of last month
        return (
          startDate === `${lastMonthStart.getFullYear()}-${String(lastMonthStart.getMonth() + 1).padStart(2, "0")}-01` &&
          endDate == moment(lastMonthEnd).format('YYYY-MM-DD')
        );
      }

      case "This Month": {
        const thisMonthStart = new Date(year, month, 1); // First day of this month
        const thisMonthEnd = new Date(year, month + 1, 0); // Last day of this month
        return (
          startDate === moment(thisMonthStart).format('YYYY-MM-DD') &&
          endDate === moment(thisMonthEnd).format('YYYY-MM-DD')
        );
      }

      case "This Year": {
        const thisYearStart = `${year}-01-01`;
        const thisYearEnd = `${year}-12-31`;
        return startDate === thisYearStart && endDate === thisYearEnd;
      }

      case "Last Year": {
        const lastYearStart = `${year - 1}-01-01`;
        const lastYearEnd = `${year - 1}-12-31`;
        return startDate === lastYearStart && endDate === lastYearEnd;
      }

      case "all": {
        // Check if both startDate and endDate are null/empty
        return !value?.startDate && !value?.endDate;
      }

      case "custom": {
        // Highlight "Custom" if the date range doesn't match any preset
        return !(
          isRangeMatching("This Month") ||
          isRangeMatching("Last Month") ||
          isRangeMatching("This Year") ||
          isRangeMatching("Last Year") ||
          isRangeMatching("all")
        );
      }

      default:
        return false;
    }
  };

  return (
    <div ref={ref}>
      <Menu as='a' className={`relative list_box_active_state inline-block text-left ${fullWidth ? "w-full" : "w-fit"}`}>
        <MenuButton data-open={ItemsOpen} disabled={disabled} data-tooltip-id={`tooltip${RandomNumber}`} className={`!px-4 w-full text-[#1D2433] !text-sm h-10 font-normal min-w-[240px] py-2.5 flex items-center justify-between gap-2 bg-[#fff] rounded-extra_large !shadow-box border !border-grey hover:bg-[#F3F2F5] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed focus:!outline-primary focus-visible:!outline-primary focus-visible:!outline-2 focus:!outline-2 focus-visible:!outline focus:!outline focus-visible:border-indigo-500 ${outerClass}`} onClick={e => setItemsOpen(!ItemsOpen)}>
          {!value?.startDate || !value?.endDate ? (
            <>{range == 'all' || (allTime || (!value.startDate && !value?.endDate)) ? allTimeText : placeholder || "Start Date - End Date"}</>
          ) : (
            <>
              {datepipeModel.date(value?.startDate, user?.companyDateFormat)} -{" "}
              {datepipeModel.date(value?.endDate, user?.companyDateFormat)}
            </>
          )}
          <IoIosArrowDown className={`h-[18px] text-bold w-5 text-[#75757A]`} />
        </MenuButton>
        {ItemsOpen && <MenuItems static className={`absolute ${outerClass ? outerClass : 'right-0'}  z-50 mt-2 min-w-[320px] origin-top-right divide-y divide-gray-100 rounded-large bg-white shadow-lg ring-1 ring-black/5 outline !outline-primary `} id="main_calender">

          <div className="!px-4 !py-3 ">

            <>
              {buttonShow ? null : <div className="grid grid-cols-2 !gap-3 !mb-3">
                {rangeList.map((itm) => {
                  return (
                    <button
                      className={`!px-4 text-typo text-sm min-w-28 font-medium py-2.5 flex items-center justify-center gap-2 bg-[#fff] rounded-extra_large shadow-btn hover:bg-[#F3F2F5] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed !outline-none ${isRangeMatching(itm?.id) && !CustomSelct ? '!bg-primary  !border-primary !text-white' : ""}`}
                      type="button"
                      disabled={disbaledDate ? itm?.name == 'This Month' || itm?.name == 'This Year' ? false : true : false}
                      onClick={(e) => { rangeClick(itm.id); setCustomSelect(false) }}>
                      {itm.name}
                    </button>
                  );
                })}
                {showcustom == true ? <button type="button"
                  className={`!px-4 text-typo text-sm min-w-28 font-medium py-2.5 flex items-center justify-center gap-2 bg-[#fff] rounded-extra_large shadow-btn hover:bg-[#F3F2F5] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed !outline-none ${isRangeMatching("custom") || CustomSelct ? " !bg-primary  !border-primary !text-white" : ""}`}
                  onClick={(e) => { rangeClick("custom"); setCustomSelect(true) }}
                >
                  Custom
                </button> : null}
              </div>}
              <div className="">
                {(showcustom == false) && !buttonShow ? (
                  <></>
                ) : (
                  <>
                    {maxDate ?
                      <DateRange
                        editableDateInputs={true}
                        onChange={(e) => blockDateChange(e.selection, setItemsOpen)}
                        moveRangeOnFirstSelection={false}
                        ranges={getBlockValue()}
                        months={monthsCount}
                        direction="horizontal"
                        rangeColors={["#481196"]}
                        maxDate={maxDate}
                        shownDate={value?.startDate ? new Date(value?.startDate) : maxDate}
                      />
                      :
                      <>
                        {disbaledDate ? <DateRange
                          editableDateInputs={true}
                          onChange={(e) => blockDateChange(e.selection, setItemsOpen)}
                          moveRangeOnFirstSelection={false}
                          ranges={getBlockValue()}
                          minDate={new Date()}
                          months={monthsCount}
                          direction="horizontal"
                          rangeColors={["#481196"]}
                        /> : <DateRange
                          editableDateInputs={true}
                          onChange={(e) => blockDateChange(e.selection, setItemsOpen)}
                          moveRangeOnFirstSelection={false}
                          ranges={getBlockValue()}
                          months={monthsCount}
                          direction="horizontal"
                          rangeColors={["#481196"]}
                        />}
                      </>
                    }
                  </>
                )}
              </div>
              {isCompare ? (
                <>
                  <Menu as="div" className="relative w-fit block mx-auto">
                    <MenuButton className="!px-4 text-typo text-sm h-10 font-medium py-2.5 flex items-center justify-center gap-2 bg-[#fff] rounded-large shadow-btn hover:bg-[#F3F2F5] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed">
                      Compare
                      <TiArrowSortedDown className={`h-4 w-4 text-[#7E8B99]`} />
                    </MenuButton>
                    <MenuItems className="absolute right-0 z-50 mt-2 min-w-[320px] origin-top-right divide-y divide-gray-100 rounded-large bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                      <div className="!px-4 !py-3 space-y-2">
                        <MenuItem>
                          <a className={`py-2 px-3.5 hover:bg-gray-200  text-typo block rounded-large text-sm ${!value.compare ? "!bg-primary !text-white font-medium hover:!text-white" : "hover:!text-typo"}`}
                            onClick={(e) => { compareChange(""); setItemsOpen(false) }}>
                            Reset Compare
                          </a>
                        </MenuItem>
                        <MenuItem>
                          <a className={`py-2 px-3.5 hover:bg-gray-200  text-typo block rounded-large text-sm ${value.compare == "Previous Month" ? "!bg-primary !text-white font-medium hover:!text-white" : "hover:!text-typo"}`}
                            onClick={(e) => { compareChange("Previous Month"); setItemsOpen(false); }}>
                            Previous Month (
                            {datepipeModel.date(
                              previousMonth().start,
                              user.companyDateFormat
                            )}
                            {" "}-{" "}
                            {datepipeModel.date(
                              previousMonth().end,
                              user.companyDateFormat
                            )}
                            )
                          </a>
                        </MenuItem>
                        <MenuItem>
                          <a className={`py-2 px-3.5 hover:bg-gray-200  text-typo block rounded-large text-sm ${value.compare == "Previous Year" ? "!bg-primary !text-white font-medium hover:!text-white" : "hover:!text-typo"}`}
                            onClick={(e) => { compareChange("Previous Year"); setItemsOpen(false) }}>
                            Previous Year(Same Date) (
                            {datepipeModel.date(
                              previousYear().start,
                              user.companyDateFormat
                            )}
                            {" "}-{" "}
                            {datepipeModel.date(
                              previousYear().end,
                              user.companyDateFormat
                            )}
                            )
                          </a>
                        </MenuItem>
                        <MenuItem>
                          <a className={`py-2 px-3.5 hover:bg-gray-200  text-typo block rounded-large text-sm ${value.compare == "Previous Period" ? "!bg-primary !text-white font-medium hover:!text-white" : "hover:!text-typo"}`}
                            onClick={(e) => { compareChange("Previous Period"); setItemsOpen(false) }}>
                            Previous Period(Custom Dates) (
                            {datepipeModel.date(
                              previousPeriod().start,
                              user.companyDateFormat
                            )}
                            {" "}-{" "}
                            {datepipeModel.date(
                              previousPeriod().end,
                              user.companyDateFormat
                            )}
                            )
                          </a>
                        </MenuItem>
                      </div>
                    </MenuItems>
                  </Menu>
                </>
              ) : (
                <></>
              )}
            </>
            {/* )}
            </MenuItem> */}
          </div>
        </MenuItems>}
      </Menu>
      {title ? <>
        <Tooltip
          id={`tooltip${RandomNumber}`}
          classNameArrow="!border-b-[1.5px] !border-r-[1.5px] border-primary !rounded-[2px]"
          className="z-[999] !opacity-100 !shadow-box border !border-grey border border-primary !rounded-large !text-center !max-w-[250px] p-2"
          place="bottom"><div className="tooltipCal">{title}</div></Tooltip>
      </> : <></>}
      <div
        className={`dropdownOverlap ${toggle ? "d-block" : ""}`}
        onClick={(e) => toggleChange(false)} />
    </div>
  )
};
export default DateRangePicker;
