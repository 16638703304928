import { Disclosure, Menu, Transition } from "@headlessui/react";
import { useEffect, useState } from "react";
import { GrChannel } from "react-icons/gr";
import { IoIosArrowDown } from "react-icons/io";
import { RiHome6Line } from "react-icons/ri";
import { TiArrowSortedDown } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { login_success } from "../../../actions/user";
import ApiClient from "../../../methods/api/apiClient";
import environment from "../../../environment";
import { MdLockOutline, MdOutlineReviews } from "react-icons/md";
import { PlanModules } from "../sidebar/Constant";
import { sidebar_base } from "../../../actions/sidebar";
import { GmailTempURL } from "../../../pages/GmailTemplate/Api";
import { TbChartBar } from "react-icons/tb";
import { FiPackage, FiSettings } from "react-icons/fi";
import { PiHandbagBold } from "react-icons/pi";
import { HiOutlineInbox, HiOutlineSpeakerphone } from "react-icons/hi";

const navItems = [
  {
    name: "Dashboard",
    hasChildren: false,
    link: "/dashboard",
    icon: <RiHome6Line className="text-[#00b884] shrink-0 text-lg" />,
    urlAllow: "readdashboard",
    planFeature: "dashboard",
    skipPermission: true,
  },
  {
    name: "Omni Channel",
    hasChildren: true,
    icon: <GrChannel className="text-[#00b884] shrink-0 text-lg" />,
    tabClass: "omni",
    subscription: "Customer Relations",
    children: [
      {
        name: "Chatbot",
        hasChildren: false,
        link: "/chatbot",
        urlAllow: "readchatbot",
        planFeature: "chatbot",
        favUnFav: "Chatbot",
      },
      {
        name: "Inbox",
        hasChildren: false,
        link: "/omni-channels/live-chat",
        urlAllow: "readchat",
        planFeature: "chat",
        favUnFav: "Chat",
      },
      {
        name: "Templates",
        hasChildren: true,
        children: [
          {
            name: "WhatsApp",
            hasChildren: false,
            link: "/whatsapp/template",
            urlAllow: "readwhatsapp",
            planFeature: "whatsapp",
            favUnFav: "WhatsApp Template",
          },
          {
            name: "Email",
            hasChildren: false,
            link: "/gmail/template",
            favUnFav: "Email Template",
          },
        ],
      },
      {
        name: "Widget Settings",
        hasChildren: false,
        link: "/omni-channel-setting/widget",
        urlAllow: "readwidget-settings",
        planFeature: "widget-settings",
        favUnFav: "Chat Widget",
      },
    ],
  },
  {
    name: "Reviews",
    hasChildren: true,
    icon: <MdOutlineReviews className="text-[#ffc800] shrink-0 text-lg" />,
    tabClass: "reviews",
    subscription: "Reviews",
    children: [
      {
        name: "Reviews Insights",
        hasChildren: false,
        link: "/review-dashboard",
        urlAllow: "readreview-insights",
        planFeature: "review-insights",
        favUnFav: "Review Insights",
      },
      {
        name: "Review Management",
        hasChildren: false,
        link: "/reviews",
        urlAllow: "readreview-management",
        planFeature: "review-management",
        favUnFav: "Review Management",
      },
      {
        name: "Review Settings",
        hasChildren: false,
        link: "/reviews/settings",
        urlAllow: "readreviews-settings",
        planFeature: "reviews-settings",
        favUnFav: "Review Settings",
      },
    ],
  },
  {
    name: "Sales",
    hasChildren: true,
    icon: <TbChartBar className="text-[#1b88c0] shrink-0 text-lg" />,
    tabClass: "sales",
    subscription: "Sales",
    children: [
      {
        name: "Sales Insights",
        hasChildren: false,
        link: "/sales",
        urlAllow: "readsales-insights",
        planFeature: "sales-insights",
        favUnFav: "Sales Insight",
      },
      {
        name: "Sales Data",
        hasChildren: false,
        link: "/sales-data",
        urlAllow: "readsales-data",
        planFeature: "sales-data",
        favUnFav: "Sales Data",
      },
      {
        name: "Sales Promo Code Data",
        hasChildren: false,
        link: "/sales-data/promo",
        urlAllow: "readsales-data",
        planFeature: "sales-data",
        favUnFav: "Sales Data",
      },
      {
        name: "Email Orders",
        hasChildren: false,
        link: "/email/bookings",
        planFeature: "email-orders",
      },
      {
        name: "Customer Insights",
        hasChildren: false,
        link: "/customers",
        urlAllow: "readcustomer-insights",
        planFeature: "customer-insights",
        favUnFav: "Customer Insights",
      },
      {
        name: "Customer Data",
        hasChildren: false,
        link: "/customer-data",
        urlAllow: "readcustomer-data",
        planFeature: "customer-data",
        favUnFav: "Customer Data",
      },
      {
        name: "Refund/Cancel Data",
        hasChildren: false,
        link: "/sales/refunds",
        urlAllow: "readrefundcancel-data",
        planFeature: "refundcancel-data",
        favUnFav: "Sales Refund Data",
      },
      {
        name: "Waivers",
        hasChildren: true,
        subscription: "Waivers",
        checkSubscription: true,
        children: [
          {
            name: "Waivers Templates",
            hasChildren: false,
            link: "/waiver/list",
            urlAllow: "readwaiver-templates",
            planFeature: "waiver-templates",
            favUnFav: "Waiver Templates",
          },
          {
            name: "Waivers Settings",
            hasChildren: false,
            link: "/wavier/settings",
            urlAllow: "readwaiver-settings",
            planFeature: "waiver-settings",
            favUnFav: "Waiver Settings",
          },
          {
            name: "Waivers Database",
            hasChildren: false,
            link: "/waidatabase",
            urlAllow: "readwaiver-database",
            planFeature: "waiver-database",
            favUnFav: "Waiver Database",
          },
        ],
      },
    ],
  },
  {
    name: "Products",
    hasChildren: true,
    icon: <FiPackage className="text-[#4529ff] shrink-0 text-lg" />,
    tabClass: "productdashboard",
    subscription: "Products",
    children: [
      {
        name: "Product Insights",
        hasChildren: false,
        link: "/product-dashboard",
        urlAllow: "readproduct-insights",
        planFeature: "product-insights",
        favUnFav: "Product Insights",
      },
      {
        name: "Product Data",
        hasChildren: false,
        link: "/product-data",
        urlAllow: "readproduct-data",
        planFeature: "product-data",
        favUnFav: "Product Data",
      },
      {
        name: "Deleted Products",
        hasChildren: false,
        link: "/delete/product-data/",
        urlAllow: "readdeleted-products",
        planFeature: "deleted-products",
        favUnFav: "Deleted Products",
      },
      {
        name: "Historical Data",
        hasChildren: false,
        link: "/products/retrivable",
        urlAllow: "readhistorical-data",
        planFeature: "historical-data",
        favUnFav: "Historical Product",
      },
      {
        name: "Cancellation Policies",
        hasChildren: false,
        link: "/cancel/policy",
        urlAllow: "readcancellation-policies",
        planFeature: "cancellation-policies",
        favUnFav: "Cancellation Policies",
      },
      {
        name: "Content Updates",
        hasChildren: true,
        children: [
          {
            name: "Update Logs",
            hasChildren: false,
            link: "/content/updates",
            urlAllow: "readupdate-logs",
            planFeature: "update-logs",
            favUnFav: "Update Logs",
          },
          {
            name: "Settings",
            hasChildren: false,
            link: "/content/settings",
            urlAllow: "readsettings",
            planFeature: "settings",
            favUnFav: "Update Logs Setting",
          },
        ],
      },
      {
        name: "Costing Templates",
        hasChildren: true,
        children: [
          {
            name: "Budget",
            hasChildren: false,
            link: "/budget/template",
            urlAllow: "readbudget",
            planFeature: "budget",
            favUnFav: "Budget Per Tour",
          },
          {
            name: "Costing",
            hasChildren: false,
            link: "/costing/template",
            urlAllow: "readcosting",
            planFeature: "costing",
            favUnFav: "Costing Per Tour",
          },
        ],
      },
    ],
  },
  {
    name: "Marketing",
    hasChildren: true,
    icon: <HiOutlineSpeakerphone className="text-[#ffc800] shrink-0 text-lg" />,
    tabClass: "marketing",
    subscription: "Marketing",
    children: [
      {
        name: "Marketing Insights",
        hasChildren: false,
        link: "/marketing/insights",
        urlAllow: "readmarketing-insights",
        planFeature: "marketing-insights",
        favUnFav: "Google Marketing",
      },
      {
        name: "Google Analytics Summary",
        hasChildren: false,
        link: "/marketing/google-analytics",
        urlAllow: "readgoogle-analytics-summary",
        planFeature: "google-analytics-summary",
        favUnFav: "Google Marketing",
      },
      {
        name: "Google Ads",
        hasChildren: false,
        link: "/marketing/google-ads",
        urlAllow: "readgoogle-ads",
        planFeature: "google-ads",
      },
      {
        name: "Meta Ads",
        hasChildren: false,
        link: "/marketing/facebook-ads",
        urlAllow: "readfacebook-ads",
        planFeature: "facebook-ads",
      },
      {
        name: "Instagram Analysis",
        hasChildren: false,
        link: "/marketing/instagram-analysis",
        urlAllow: "readinstagram-analysis",
        planFeature: "instagram-analysis",
      },
      {
        name: "Manage Google Tags",
        hasChildren: false,
        link: "/google/tags",
        urlAllow: "readmanage-google-tags",
        planFeature: "manage-google-tags",
        favUnFav: "Google Tag Manager",
      },
      {
        name: "Affiliate",
        hasChildren: true,
        planFeature: "affiliates",
        favUnFav: "",
        children: [
          {
            name: "Affiliate DataBase",
            hasChildren: false,
            link: "/affiliate",
            favUnFav: "Affiliate Database",
          },
          {
            name: "Current Affiliate",
            hasChildren: false,
            link: "/current-affiliate",
            favUnFav: "Current Affiliate",
          },
          {
            name: "Affiliate Request",
            hasChildren: false,
            link: "/request",
            favUnFav: "Affiliate Request",
          },
          {
            name: "General Banners",
            hasChildren: false,
            link: "/general/banners",
            favUnFav: "General Banners",
          },
          {
            name: "Product Banners",
            hasChildren: false,
            link: "/products/banners",
            favUnFav: "Products Banners",
          },
          {
            name: "Default Commission",
            hasChildren: false,
            link: "/default-commission",
            favUnFav: "Default Commission",
          },
          {
            name: "Inbox",
            hasChildren: false,
            link: "/affiliateInbox",
            favUnFav: "Affiliate Requese",
          },
        ],
      },
    ],
  },
  {
    name: "Settings",
    hasChildren: true,
    icon: <FiSettings className="shrink-0 text-lg mr-1" />,
    iconTitle: false,
    tabClass: "settings",
    subscription: "Settings",
    children: [
      {
        name: "Company Details",
        hasChildren: false,
        link: "/company",
        urlAllow: "readcompany-details",
        favUnFav: "Company Details",
      },
      {
        name: "Email Settings",
        hasChildren: true,
        children: [
          {
            name: "Email List",
            hasChildren: false,
            link: "/email/list",
            urlAllow: "reademail-list",
            planFeature: "email-list",
            favUnFav: "Email List",
          },
          {
            name: "Email Templates",
            hasChildren: false,
            link: "/email/templates",
            urlAllow: "reademail-templates",
            planFeature: "email-list",
            favUnFav: "Email Templates",
          },
        ],
      },
      {
        name: "Data Connection",
        hasChildren: false,
        link: "/pos",
        urlAllow: "readdata-connection",
        planFeature: "data-connection",
        favUnFav: "Data Connection",
      },
      {
        name: "Order Page Settings",
        hasChildren: false,
        link: "/booking/setting",
        urlAllow: "readbooking-page-settings",
        planFeature: "booking-page-settings",
        favUnFav: "Data Connection",
      },
      {
        name: "Users",
        hasChildren: false,
        link: "/users",
        urlAllow: "readusers",
        planFeature: "users",
        favUnFav: "Users",
      },
      {
        name: "Plan",
        hasChildren: false,
        link: "/activeplan",
        urlAllow: "readplan",
        favUnFav: "Plans",
      },
      {
        name: "Billing",
        hasChildren: false,
        link: "/card",
        urlAllow: "readbilling",
        favUnFav: "Billings",
      },
    ],
  },
  {
    name: "More",
    hasChildren: true,
    tabClass: "more",
    skipPermission: true,
    children: [
      {
        name: "Profitability",
        hasChildren: true,
        icon: <PiHandbagBold className="text-[#7b68ee] shrink-0 text-lg" />,
        tabClass: "financial",
        subscription: "Profitability",
        checkSubscription: true,
        children: [
          {
            name: "Insights",
            hasChildren: false,
            link: "/accounting/dashboard",
            urlAllow: "readinsights",
            planFeature: "insights",
            favUnFav: "Profitability Insights",
          },
          {
            name: "Profit Analysis - Month",
            hasChildren: false,
            link: "/accounting/profit/month",
            urlAllow: "readprofit-analysis-month",
            planFeature: "profit-analysis-month",
            favUnFav: "Profit Analysis- Month",
          },
          {
            name: "Profit Analysis - Date",
            hasChildren: false,
            link: "/accounting/profit/date",
            urlAllow: "readprofit-analysis-date",
            planFeature: "profit-analysis-date",
            favUnFav: "Profit Analysis- Date",
          },
          // {
          //   name: "Profitability Data",
          //   hasChildren: false,
          //   link: "/accounting/transactions",
          //   urlAllow: "readprofitability-data",
          //   planFeature: "profitability-data",
          //   favUnFav: "Profitability Data",
          // },
        ],
      },
      {
        name: "Reseller",
        hasChildren: true,
        icon: <HiOutlineInbox className="text-[#bb66e1] shrink-0 text-lg" />,
        tabClass: "crm",
        subscription: "Reseller",
        checkSubscription: true,
        children: [
          {
            name: "Reseller Insights",
            hasChildren: false,
            link: "/reseller-dashboard",
            urlAllow: "readresellers-insights",
            planFeature: "resellers-insights",
            favUnFav: "Reseller Insights",
          },
          {
            name: "List of Resellers",
            hasChildren: false,
            link: "/reseller-data",
            urlAllow: "readlist-of-resellers",
            planFeature: "list-of-resellers",
            favUnFav: "List of Resellers",
          },
        ],
      },
      {
        name: "Invoicing",
        hasChildren: true,
        tabClass: "InvoicesRes",
        subscription: "InvoiceTemplates",
        checkSubscription: true,
        children: [
          {
            name: "List of Invoices",
            hasChildren: false,
            link: "/reseller-invoices",
            urlAllow: "readlist-of-invoices",
            planFeature: "list-of-invoices",
            favUnFav: "Reseller Invoices",
          },
          {
            name: "Invoice Templates",
            hasChildren: false,
            link: "/invoice/template",
            urlAllow: "readinvoice-templates",
            planFeature: "invoice-templates",
            favUnFav: "Invoice Templates",
          },
        ],
      },
      {
        name: "Contracting",
        hasChildren: true,
        tabClass: "templatescrm",
        planFeature: "contract-templates",
        children: [
          {
            name: "Contract Templates",
            hasChildren: false,
            link: "/crm",
            urlAllow: "readcontract-templates",
            planFeature: "contract-templates",
            favUnFav: "Contract Templates",
          },
        ],
      },
    ],
  },
];

const NavMenu = ({ isOpen }) => {
  const sidebarCollapseData = useSelector((state) => state.sidebar);
  const CurrentServer = window.location.href?.includes("app.dazhboards.com")
    ? false
    : true;
  const location = useLocation();
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const ValidationNumber =
    user?.supplier_id?.id ||
    user?.subRole?.id == environment.SubRolePartner ||
    user?.subRole?._id == environment.SubRolePartner
      ? 0
      : 0;
  const navigate = useNavigate();
  const dataConnectionLink = [
    "/pos",
    "/api/review-connected",
    "/api/review",
    "/marketing-analytics",
    "/api/accounting",
    "/marketing-software",
  ];

  const dataConnection = dataConnectionLink.includes(location.pathname);
  const [CompanyData, setCompanyData] = useState(user);
  useEffect(() => {
    if (localStorage.getItem("token") && user?._id) {
      ApiClient.get(
        `api/user/profile`,
        { id: localStorage.getItem("_id") || user?._id },
        "",
        "",
        {
          apiCall: true,
        }
      ).then((res) => {
        if (res.success) {
          if (localStorage.getItem("_id") != res?.data?._id) {
          } else {
            setData(res.data);
            const data = res?.data;
            if (data?.subRole && data?.subRole?._id) {
              data["subRole"] = { ...data?.subRole, id: data?.subRole?._id };
            }
            dispatch(login_success({ ...data, image: res?.data?.image }));
          }
        }
      });
    }
  }, []);

  const menus = {
    users: ["roles", "users"],
    tourGuides: ["/tour/guide-dashboard"],
    sales: [
      "sales",
      "orders",
      "saleData",
      "sale/retrive",
      "sales/refunds",
      "dynamicprice",
      "customer",
      "/email/bookings",
      "/wavier/settings",
      "waiver",
      "waidatabase",
      "/sales-data/promo",
    ],
    catalogue: ["types", "categories", "category/Reseller"],
    emailSetting: ["email"],
    customRelation: [
      "omni-channels",
      "/whatsapp/template",
      ,
      "/omni-channel-setting/integration",
      "/chatbot",
      "/omni-channel-setting/widget",
      "gmail",
      "reviews",
      "review-dashboard",
      "review/",
    ],
    reports: [
      "transactions",
      "report/sales",
      "report/affiliates",
      "report/customers",
    ],
    costingTemp: ["costing", "budget", "/budget/template"],
    productdashboard: [
      "product-dashboard",
      "products",
      "product",
      "/cancel/policy",
      "product-data",
      "/content/settings",
      "product/detail",
      "/content/updates",
      "product/retrivable",
      "costing",
      "costing/tour",
      "budget",
      "/content/detail",
    ],
    socialMedia: ["/social/media"],
    templates: ["/gmail/", "whatsapp/template", "whtasapp/template/add"],
    whatsapp: ["whatsapp/template", "whtasapp/template/add"],
    customers: ["customers", "customer-data", "customerdetails"],
    waivers: ["/wavier/settings", "waiver", "waidatabase"],
    dynamicPricing: ["dynamicprice"],
    reviews: ["reviews", "review-dashboard", "review/"],
    plan: ["plans", "card"],
    customer: ["customer"],
    marketing: [
      "/marketing/insights",
      "coupons",
      "/google/tags",
      "/marketing/google-ads",
      "/marketing/facebook-ads",
      "/marketing/instagram-analysis",
      "/marketing/google-analytics",
      "google",
      "forecasting",
      "/social/media",
      "request",
      "affiliate",
      "messages",
      "banner",
      "affiliateInbox",
      "default-commission",
      "current-affiliate",
      "products/banners",
    ],
    financial: [
      "financial",
      "accounting",
      "/accounting/profit/date",
      "/accounting/profit/month",
    ],
    googletag: ["/google/tags"],
    settings: [
      "two-factor-auth",
      "/booking/setting",
      "partner",
      "/email/addedit/",
      "company",
      "activeplan",
      "/omni-channel-setting/social/media",
      "plans",
      "/omni-channel-setting/copy",
      "card",
      "/email/templates",
      "/email/list",
      "users",
      "/smtp/connection",
      "roles",
      "pos",
      "api/",
      "marketing-analytics",
      "reviewStep",
      "roles",
      "/omni-channel-setting/google/tag",
      "users",
      "marketing-software",
    ],
    omni: [
      "omni-channels",
      "/whatsapp/template",
      "/omni-channel-setting/integration",
      "chatbot",
      "/omni-channel-setting/widget",
      "gmail",
    ],
    affiliates: [
      "request",
      "affiliate",
      "messages",
      "banner",
      "affiliateInbox",
      "default-commission",
      "current-affiliate",
      "products/banners",
    ],
    crm: [
      "reseller-dashboard",
      "reseller-data",
      "/invoice-commission",
      "reseller-data",
      "/content/detail",
      "supplier-invoice-detail",
    ],
    contents: ["/content/updates", "/content/settings"],
    api: [
      "product",
      "pos",
      "api/",
      "marketing-analytics",
      "reviewStep",
      "review-connected",
    ],
    InvoicesRes: [
      "/invoice/template",
      "/inovoice/template/edit",
      "reseller-invoices",
      "supplier-invoice-detai",
    ],
    templatescrm: ["crm"],
    geo: ["continents", "countries", "regions", "cities"],
  };

  const moreMenus = [
    ...menus.financial,
    ...menus.crm,
    ...menus.InvoicesRes,
    ...menus.templatescrm,
  ];

  const FavUnFavReturn = (name = "") => {
    if (true) {
      return null;
    }
    if (user?.pinnedTabs?.includes(name)) {
      return (
        <span onClick={(e) => FavUnFavUpdater(e, true, name)}>
          {" "}
          <FaStar className="text-primary " />
        </span>
      );
    } else {
      return (
        <span onClick={(e) => FavUnFavUpdater(e, false, name)}>
          {" "}
          <FaRegStar className="text-primary " />
        </span>
      );
    }
  };
  const FavUnFavUpdater = (e, type, name) => {
    e.preventDefault();
    if (!CurrentServer) {
      return null;
    }
    let data5 = user?.pinnedTabs || [];
    if (type) {
      data5 = user?.pinnedTabs?.filter((item) => item != name);
    } else {
      data5.push(name);
    }

    dispatch(login_success({ ...user, pinnedTabs: data5 }));
    ApiClient.put(`api/user/profile`, { id: user?._id, pinnedTabs: data5 });
  };
  const SubsciptionData = useSelector((state) => state.Subscription);

  const PlanFeatureAvailable = (feature = "", type = null) => {
    let data = SubsciptionData;
    // if (user?.on_trial) {
    //   return true
    // } else {
    // return true
    if (!type) {
      return data?.includes(feature);
    } else {
      if (!data?.includes(feature)) {
        return <MdLockOutline />;
      }
    }

    // }
  };

  const ParentModuleSubscriptionFinder = (data) => {
    let value = false;
    data?.map((_check) => {
      if (PlanFeatureAvailable(_check)) {
        value = true;
      }
    });
    return value;
  };
  const DataConnectCheck = () => {
    if (
      ParentModuleSubscriptionFinder([
        "product-data",
        "product-insights",
        "sales-data",
        "sales-insights",
      ])
    ) {
      return "/pos";
    } else if (
      ParentModuleSubscriptionFinder(["review-management", "review-insights"])
    ) {
      return "/api/review-connected?show=false";
    } else if (ParentModuleSubscriptionFinder(["chat"])) {
      return "/omni-channel-setting/copy";
    } else if (
      ParentModuleSubscriptionFinder([
        "google-analytics-summary",
        "google-ads",
        "facebook-ads",
        "manage-google-tags",
      ])
    ) {
      return "/marketing-analytics";
    } else if (
      ParentModuleSubscriptionFinder(["profitability-data", "insights"])
    ) {
      return "/api/accounting";
    } else {
      return "/plans";
    }
  };

  const ParentModulePermission = (data) => {
    let value = data?.some((_check) => urlAllow(`read${_check}`));
    return value;
  };

  const handleItemClick = (hasChildren, link) => {
    !hasChildren && navigate(link);
  };

  const urlAllow = (url) => {
    if (user?.subRole?.id == environment.SubRolePartner) {
      return true;
    }
    let permissions =
      user?.subRole &&
      user?.subRole?.permissions &&
      user?.subRole?.permissions[0];
    let arr = url?.split(",");
    let value = false;
    arr?.map((itm) => {
      if (permissions?.[itm]) value = true;
    });
    if (user?.subRole?.id || user?.subRole?._id) {
      return value;
    } else {
      return true;
    }
  };

  const tabclass = (tab) => {
    let url = location.pathname;
    let value = false;
    if (tab === "more") {
      moreMenus.map((itm) => {
        if (url?.includes(itm)) value = true;
      });
    } else {
      menus[tab]?.map((itm) => {
        if (url?.includes(itm)) value = true;
      });
    }
    // alert(CollapsedState)
    return value;
  };

  const [CollapsedState, setCollapsedState] = useState(
    sidebarCollapseData?.value
  );

  useEffect(() => {
    let value = false;
    Object.keys(menus).map((item) => {
      if (tabclass(item)) {
        value = true;
      }
    });
    dispatch(sidebar_base({ value: value }));
  }, []);

  useEffect(() => {
    // setSidebarLoad(true)
    setCollapsedState(sidebarCollapseData?.value);
  }, [sidebarCollapseData?.value]);

  const [activeDisclosurePanel, setActiveDisclosurePanel] = useState(null);

  function togglePanels(newPanel) {
    if (activeDisclosurePanel) {
      if (
        activeDisclosurePanel.key !== newPanel.key &&
        activeDisclosurePanel.open
      ) {
        activeDisclosurePanel.close();
      }
    }

    setActiveDisclosurePanel({
      ...newPanel,
      open: !newPanel.open,
    });
  }

  return (
    <>
      {user?.subscriptionId ||
      user?.on_trial ||
      user?.supplier_id?.id ||
      user?.subRole?.id == environment.SubRolePartner ||
      user?.subRole?._id == environment.SubRolePartner ? (
        <ul className="flex gap-0 !list-none text-sm font-normal items-center flex-wrap justify-center">
          {navItems.map((item, index) => (
            <li
              key={index}
              onClick={() => handleItemClick(item.hasChildren, item.link)}
              className={`group text-black/70 px-0.5 text-sm font-normal rounded-md transition-all cursor-pointer ${
                ParentModuleSubscriptionFinder(
                  PlanModules[`${item.subscription}`]
                ) || item.skipPermission
                  ? ""
                  : "pointer-events-none"
              }`}
            >
              {item.hasChildren ? (
                <div className="relative inline-block text-left">
                  <Menu>
                    {(ParentModulePermission(
                      PlanModules[`${item.subscription}`]
                    ) ||
                      item.skipPermission) && (
                      <Menu.Button
                        className={`text-black/70  p-2 group-hover:text-primary hover:bg-[#EDECF9] whitespace-nowrap rounded-md transition-all inline-flex items-center justify-center w-full gap-0.5 font-normal text-sm ${
                          tabclass(item.tabClass)
                            ? "text-primary bg-[#EDECF9]"
                            : ""
                        }`}
                      >
                        {item.iconTitle ? item.icon : item.name}{" "}
                        {ParentModuleSubscriptionFinder(
                          PlanModules[`${item.subscription}`]
                        ) || item.skipPermission ? null : (
                          <MdLockOutline />
                        )}
                        {item.hasChildren && (
                          <IoIosArrowDown className="text-sm" />
                        )}
                      </Menu.Button>
                    )}

                    <Menu.Items className="border-grey focus:outline-none divide-gray-50 absolute left-0 w-56 mt-1 origin-top-right bg-white border divide-y rounded-lg shadow-sm z-20 p-1">
                      {item.children?.map((child, cInd) => (
                        <>
                          {((child.hasOwnProperty("urlAllow")
                            ? urlAllow(child.urlAllow)
                            : true) &&
                            (child.hasOwnProperty("planFeature")
                              ? PlanFeatureAvailable(child.planFeature)
                              : !child.checkSubscription)) ||
                          (child.hasChildren &&
                            (child.checkSubscription
                              ? ParentModuleSubscriptionFinder(
                                  PlanModules[`${child.subscription}`]
                                )
                              : !child.hasOwnProperty("planFeature"))) ? (
                            <div
                              className={`bg-white ${
                                (child.hasOwnProperty("planFeature")
                                  ? PlanFeatureAvailable(child.planFeature)
                                  : !child.checkSubscription) ||
                                (child.hasChildren &&
                                  (child.checkSubscription
                                    ? ParentModuleSubscriptionFinder(
                                        PlanModules[`${child.subscription}`]
                                      )
                                    : true))
                                  ? ""
                                  : "pointer-events-none"
                              }`}
                              key={cInd}
                            >
                              <Menu.Item>
                                {({ active }) => {
                                  if (child.hasChildren) {
                                    return (
                                      <Disclosure as="div" className="relative">
                                        {({ open, close }) => (
                                          <>
                                            <Disclosure.Button
                                              onClick={(e) => {
                                                e.stopPropagation();
                                              }}
                                              className={`${
                                                (open ||
                                                  tabclass(child.tabClass)) &&
                                                " bg-primary text-white"
                                              } w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-white group  hover:bg-primary transition-all`}
                                            >
                                              <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                                                {/* <GrChannel className="text-[#00b884] shrink-0 text-lg" /> */}
                                                <span className="sidebar_text">
                                                  {child.name}
                                                  {FavUnFavReturn(
                                                    child.favUnFavs
                                                  )}
                                                  {PlanFeatureAvailable(
                                                    child.planFeature,
                                                    child.planFeature
                                                  )}
                                                </span>
                                              </span>
                                              <TiArrowSortedDown
                                                className={`${
                                                  open
                                                    ? "-rotate-90 "
                                                    : "-rotate-90 transform"
                                                } transition-all duration-500 !text-inherit h-4 w-4 text-[#7E8B99]`}
                                              />
                                            </Disclosure.Button>
                                            <Transition
                                              enter="transition duration-300 ease-in-out"
                                              enterFrom="transform scale-95 opacity-0"
                                              enterTo="transform scale-300 opacity-300"
                                              leave="transition duration-300 ease-in-out"
                                              leaveFrom="transform scale-300 opacity-300"
                                              leaveTo="transform scale-95 opacity-0"
                                            >
                                              <Disclosure.Panel className="border-grey focus:outline-none left-full absolute top-0 w-56 ml-3 origin-top-right bg-white border rounded-lg shadow-sm">
                                                <TiArrowSortedDown
                                                  className={`absolute -left-4 top-0 rotate-90 transition-all duration-500 h-6 w-6 text-[#ccc9cc]`}
                                                />
                                                <ul className="!list-none p-1.5">
                                                  {child.children?.map(
                                                    (grandChild, gInd) => {
                                                      if (
                                                        grandChild.hasChildren
                                                      ) {
                                                        return (
                                                          <Disclosure
                                                            as="div"
                                                            className="relative"
                                                          >
                                                            {({
                                                              open,
                                                              close,
                                                            }) => (
                                                              <>
                                                                <Disclosure.Button
                                                                  onClick={(
                                                                    e
                                                                  ) => {
                                                                    e.stopPropagation();
                                                                  }}
                                                                  className={`${
                                                                    open &&
                                                                    " bg-primary text-white"
                                                                  } w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-white group  hover:bg-primary transition-all`}
                                                                >
                                                                  <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                                                                    {/* <GrChannel className="text-[#00b884] shrink-0 text-lg" /> */}
                                                                    <span className="sidebar_text">
                                                                      {
                                                                        grandChild.name
                                                                      }
                                                                      {FavUnFavReturn(
                                                                        grandChild.favUnFavs
                                                                      )}
                                                                      {PlanFeatureAvailable(
                                                                        grandChild.planFeature,
                                                                        grandChild.planFeature
                                                                      )}
                                                                    </span>
                                                                  </span>
                                                                  <TiArrowSortedDown
                                                                    className={`${
                                                                      open
                                                                        ? "-rotate-90 "
                                                                        : "-rotate-90 transform"
                                                                    } transition-all duration-500 !text-inherit h-4 w-4 text-[#7E8B99]`}
                                                                  />
                                                                </Disclosure.Button>
                                                                <Transition
                                                                  enter="transition duration-300 ease-in-out"
                                                                  enterFrom="transform scale-95 opacity-0"
                                                                  enterTo="transform scale-300 opacity-300"
                                                                  leave="transition duration-300 ease-in-out"
                                                                  leaveFrom="transform scale-300 opacity-300"
                                                                  leaveTo="transform scale-95 opacity-0"
                                                                >
                                                                  <Disclosure.Panel className="border-grey focus:outline-none left-full absolute top-0 w-56 ml-3 origin-top-right bg-white border rounded-lg shadow-sm">
                                                                    <TiArrowSortedDown
                                                                      className={`absolute -left-4 top-0 rotate-90 transition-all duration-500 h-6 w-6 text-[#ccc9cc]`}
                                                                    />
                                                                    <ul className="!list-none p-1.5">
                                                                      {grandChild.children?.map(
                                                                        (
                                                                          grand2Child,
                                                                          g2Ind
                                                                        ) => (
                                                                          <NavLink
                                                                            to={
                                                                              grand2Child.link
                                                                            }
                                                                            key={
                                                                              g2Ind
                                                                            }
                                                                            className={({
                                                                              isActive,
                                                                            }) =>
                                                                              `group flex rounded-md text-[#4A545E] font-normal items-center w-full px-2 py-2 text-sm hover:bg-primary hover:text-white transition-all` +
                                                                              (isActive
                                                                                ? " !bg-primary text-white"
                                                                                : "")
                                                                            }
                                                                          >
                                                                            {
                                                                              grand2Child.name
                                                                            }
                                                                          </NavLink>
                                                                        )
                                                                      )}
                                                                    </ul>
                                                                  </Disclosure.Panel>
                                                                </Transition>
                                                              </>
                                                            )}
                                                          </Disclosure>
                                                        );
                                                      } else {
                                                        return (
                                                          <NavLink
                                                            to={grandChild.link}
                                                            key={gInd}
                                                            className={({
                                                              isActive,
                                                            }) =>
                                                              `group flex gap-1 rounded-md text-[#4A545E] font-normal items-center w-full px-2 py-2 text-sm hover:bg-primary hover:text-white transition-all` +
                                                              (isActive
                                                                ? " !bg-primary text-white"
                                                                : "") +
                                                              ((
                                                                grandChild.hasOwnProperty(
                                                                  "planFeature"
                                                                )
                                                                  ? PlanFeatureAvailable(
                                                                      grandChild.planFeature
                                                                    )
                                                                  : true
                                                              )
                                                                ? ""
                                                                : " pointer-events-none !cursor-default")
                                                            }
                                                          >
                                                            {grandChild.name}
                                                            {FavUnFavReturn(
                                                              grandChild.favUnFavs
                                                            )}
                                                            {PlanFeatureAvailable(
                                                              grandChild.planFeature,
                                                              grandChild.planFeature
                                                            )}
                                                          </NavLink>
                                                        );
                                                      }
                                                    }
                                                  )}
                                                </ul>
                                              </Disclosure.Panel>
                                            </Transition>
                                          </>
                                        )}
                                      </Disclosure>
                                    );
                                  } else {
                                    return (
                                      <NavLink
                                        to={child.link}
                                        className={({ isActive }) =>
                                          `${
                                            active
                                              ? "bg-primary text-white"
                                              : ""
                                          } group flex rounded-md text-[#4A545E] font-normal items-center w-full px-2 py-2 text-sm ${
                                            isActive
                                              ? " !bg-primary text-white"
                                              : ""
                                          }`
                                        }
                                      >
                                        {child.name}
                                      </NavLink>
                                    );
                                  }
                                }}
                              </Menu.Item>
                            </div>
                          ) : null}
                        </>
                      ))}
                    </Menu.Items>
                  </Menu>
                </div>
              ) : (
                <>
                  {urlAllow(item.urlAllow) &&
                  PlanFeatureAvailable(item.planFeature) ? (
                    <NavLink
                      to={item.link}
                      className={({ isActive }) =>
                        "text-black/70 transition-all font-normal hover:text-primary text-sm p-2 hover:bg-[#EDECF9] rounded-md flex gap-1" +
                        (isActive ? " !bg-[#EDECF9] text-primary" : "") +
                        (PlanFeatureAvailable(item.planFeature)
                          ? ""
                          : "pointer-events-none")
                      }
                    >
                      {item.name}
                      {PlanFeatureAvailable(item.planFeature, item.planFeature)}
                    </NavLink>
                  ) : null}
                </>
              )}
            </li>
          ))}
        </ul>
      ) : null}
    </>
  );
};

export default NavMenu;
