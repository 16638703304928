import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import loader from '../../methods/loader';
import environment from '../../environment';
import ApiClient from '../../methods/api/apiClient';
import { HiOutlineSearch } from 'react-icons/hi';
import { BsTrash, BsTrash3 } from 'react-icons/bs';
import { MdClear } from 'react-icons/md';
import datepipeModel from '../../models/datepipemodel';
import Layout from '../../components/global/layout';
import { FiPlus } from 'react-icons/fi';
import SmtpSetupFormReviews from './AddEditSMTPReviews';
export default function ReviewsSMTP() {
    const user = useSelector(state => state.user);
    const [AddScreen, setAddScreen] = useState(false);
    const searchState = useSelector((state) => state.search);
    const [filters, setFilter] = useState({ search: '', id: user?.supplier_id ? user?.supplier_id?._id : user?.id || user?._id, page: 1, count: 50 })
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [loaging, setLoader] = useState(true)
    const history = useNavigate()

    const filter = (p = {}) => {
        setFilter({ ...filters, page: 1, ...p })
        getData({ page: 1, ...p })
    }


    const sorting = (key) => {
        let sorder = 'asc'
        if (filters.key == key) {
            if (filters.sorder == 'asc') {
                sorder = 'desc'
            } else {
                sorder = 'asc'
            }
        }


        let sortBy = `${key} ${sorder}`;
        filter({ sortBy, key, sorder })
    }

    useEffect(() => {
        if (user && user.loggedIn) {
            setFilter({ ...filters, search: searchState.data })
            getData({ search: searchState.data, page: 1 })
        }
    }, [searchState])




    const getData = (p = {}, loaderData = true) => {
        if (loaderData) {
            setLoader(true)
        }
        let filter = { ...filters, ...p, }

        ApiClient.get('api/pos/smtp/listing', filter, '', '', { apiCall: true }).then(res => {
            if (res.success) {
                setData(res.data);
                setTotal(res.total)
            }
            setLoader(false);
            loader(false);
        })
    }

    const clearKey = {
        search: '',
        status: ''
    }
    const clear = () => {
        let f = clearKey
        Object.keys(f).map(itm => {
            f[itm] = ''
        })
        setFilter({ ...filters, page: 1, ...f })
        getData({ search: '', page: 1, ...f })
    }

    const isClear = () => {
        let value = false
        Object.keys(clearKey).map(itm => {
            if (filters[itm]) value = true
        })
        return value
    }

    const pageChange = (e) => {
        setFilter({ ...filters, page: e })
        getData({ page: e })
    }
    const DeletePOS = (id) => {
        loader(true);
        ApiClient.delete(`api/pos/smtp/delete?id=${id}`).then((res) => {
            if (res.success) {
                getData({}, true)
            }
        })
    }
    const AddScreenHandler = () => {
        setAddScreen(false);
        getData({}, true)
    }

    return <>
        {!AddScreen ? <>
            <div className="flex items-center justify-between mb-6">
                <div className="flex flex-col gap-1">
                    <h4 className="text-2xl font-semibold text-[#111827]">Reviews SMTP Setup</h4>
                </div>
                <button type="button" onClick={() => { setAddScreen(true) }} className="!px-4 whitespace-nowrap py-2 text-sm font-normal text-primary   border border-primary  flex items-center justify-center gap-2 bg-white rounded-extra_large shadow-btn hover:bg-primary hover:text-white transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">
                    <FiPlus className="text-xl" /> Add
                </button>
            </div>

            <div className="bg-white shadow-box border !border-grey rounded-large w-full max-w-[1280px]">
                <div className="flex items-start justify-between gap-3 p-6">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            filter();
                        }}
                        className="flex items-center !gap-2">
                        <div className="relative shadow-box border !border-grey bg-white min-w-[320px] rounded-large h-10 flex items-center">
                            <HiOutlineSearch className="text-[#717275] text-xl shrink-0 absolute left-2 top-2.5 z-10" />
                            <input
                                className="w-full h-full outline-primary absolute inset-0 pl-9 focus:right-2 rounded-large focus:ring-primary p-0 placeholder:text-[#98A2B3] text-typo text-sm placeholder:font-light"
                                value={filters.search}
                                onChange={(e) => {
                                    if (e.target.value == '') {
                                        filter({ search: "" })
                                    }
                                    setFilter({ ...filters, search: e.target.value })
                                }
                                }
                                placeholder="Search"
                            />
                        </div>
                        <button className="border border-primary h-10 shadow-btn px-6 hover:bg-primary hover:text-white text-sm text-primary   transition-all rounded-extra_large">
                            Search
                        </button>
                        {filters.search ? (
                            <button
                                className="bg-white !px-4 text-sm font-normal text-primary   h-10 flex items-center justify-center gap-2 rounded-extra_large shadow-btn hover:bg-primary hover:text-white border border-primary transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed"
                                onClick={(e) => filter({ search: "" })}>
                                <MdClear className="text-xl" />
                                Clear
                            </button>
                        ) : null}
                    </form>
                </div>
                <div className="scrollbar w-full overflow-auto">
                    <table className="w-full">
                        <thead className="border-y border-[#EAECF0]">
                            <tr className="border-y border-[#EAECF0]">
                                <th
                                    scope="col"
                                    className="text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3 cursor-pointer"
                                    onClick={(e) => sorting("name")}>
                                    Name
                                </th>
                                <th
                                    scope="col"
                                    className="text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3 cursor-pointer"
                                >
                                    Service
                                </th>
                                <th
                                    scope="col"
                                    className="text-[#82838B] !border-x-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3">
                                    Last Modified
                                </th>
                                <th
                                    scope="col"
                                    className="text-[#82838B] !border-x-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loaging &&
                                data &&
                                data.map((itm, i) => {
                                    return (
                                        <tr className="data_row" key={i}>
                                            <td
                                                className="!text-typo !border-l-0 !border-b-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]"
                                            // onClick={(e) => view(itm.id)}
                                            >
                                                {itm?.name || itm?.username}
                                            </td>
                                            <td
                                                className="!text-typo !border-l-0 !border-b-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]"
                                            >
                                                {itm?.service}
                                            </td>

                                            <td className="!text-typo !border-l-0 !border-b-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]">
                                                {datepipeModel.date(
                                                    itm?.updatedAt,
                                                    user?.companyDateFormat
                                                )}
                                            </td>

                                            <td className="!text-typo cursor-pointer !px-3.5 text-sm font-normal !py-4 !border-t text-center  border-[#EAECF0]">
                                                <button
                                                    type="button"
                                                    title="Delete"
                                                    onClick={() => DeletePOS(itm?.id || itm?._id)}
                                                    className="  shrink-0   border cursor-pointer ml-5  !border-[#E9253129] hover:opacity-70 rounded-large bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl"
                                                >
                                                    <BsTrash3 />
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
                {data?.length == 0 ? (
                    <div className="py-3 text-center">No Data</div>
                ) : (
                    <></>
                )}

                {!loaging && total > filters.count ? (
                    <div className="p-6">
                        <div className="paginationWrapper">
                            <Pagination
                                currentPage={filters.page}
                                totalSize={total}
                                sizePerPage={filters.count}
                                changeCurrentPage={pageChange}
                            />
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </div>

            {loaging ? (
                <div className="loaderDiv py-4 text-center">
                    <img
                        src="/assets/img/loader.gif"
                        width="auto"
                        height="auto"
                        className="pageLoader"
                    />
                </div>
            ) : (
                <></>
            )}
        </> : <SmtpSetupFormReviews AddScreenHandler={AddScreenHandler} />
        }
    </>;
};



