import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
import userTableModel from "../../../models/table.model";
import { CategoryType } from "../../../models/type.model";
import { useNavigate } from "react-router-dom";
import environment from "../../../environment";
import Layout from "../../../components/global/layout";
import { HiOutlineArrowDown } from "react-icons/hi";
import AddEditReviewTemplate from "./AddEdit";
import { FiPlus } from "react-icons/fi";

const ReviewEmailTemplate = () => {
    let user = useSelector((state) => state.user);
    const searchState = useSelector((state) => state.search);
    const [filters, setFilter] = useState({
        page: 1,
        count: 50,
        search: "",
        user_id: user?.supplier_id ? user?.supplier_id?._id : user?.id || user?._id,
    });
    const [AddShow, setAddShow] = useState(false);
    const [data, setData] = useState([]);
    const [tab, setTab] = useState("list");
    const [total, setTotal] = useState(0);
    const [loaging, setLoader] = useState(false);
    const [tableCols, setTableCols] = useState([]);
    const [form, setform] = useState(CategoryType);
    const history = useNavigate();
    useEffect(() => {
        let cols = [];
        for (let i = 0; i <= 2; i++) {
            cols.push(userTableModel.category[i]);
        }
        setTableCols(cols);
    }, []);


    const sorting = (key) => {
        let sorder = "asc";
        if (filters.key == key) {
            if (filters.sorder == "asc") {
                sorder = "desc";
            } else {
                sorder = "asc";
            }
        }

        let sortBy = `${key} ${sorder}`;
        setFilter({ ...filters, sortBy, key, sorder });
        getData({ sortBy, key, sorder });
    };

    useEffect(() => {
        if (user && user.loggedIn) {
            setFilter({ ...filters, search: searchState.data });
            getData({ search: searchState.data, page: 1 });
        }
    }, [searchState]);

    const getData = (p = {}) => {
        loader(true);
        let filter = { ...filters, ...p };
        ApiClient.get("api/review/invite/templates", filter, "", "", { apiCall: true }).then(
            (res) => {
                if (res.success) {
                    setData(
                        res.data.map((itm) => {
                            itm.id = itm._id;
                            return itm;
                        })
                    );
                    setTotal(res.total);
                }
                setLoader(false);
                loader(false);
            }
        );
    };

    const clear = () => {
        setFilter({ ...filters, search: "", page: 1 });
        getData({ search: "", page: 1 });
    };

    const deleteItem = (id) => {
        if (window.confirm("Do you want to delete this")) {
            loader(true);
            ApiClient.delete("api/template", { id: id }).then((res) => {
                if (res.success) {
                    // toast.success(res.message)
                    clear();
                }
                loader(false);
            });
        }
    };

    const pageChange = (e) => {
        setFilter({ ...filters, page: e });
        getData({ page: e });
    };

    const modalClosed = () => {
        setFilter({ ...filters, page: 1 });
        getData({ page: 1 });
    };

    const modalResult = (e) => {
        modalClosed();
    };

    const openModal = (itm) => {
        let extra = new Date().getTime();
        setform({ ...itm, extra });
        document.getElementById("openuserModal").click();
    };

    const ChangeRole = (e) => {
        setFilter({ ...filters, catType: e, page: 1 });
        getData({ catType: e, page: 1 });
    };

    const exportCsv = () => {
        loader(true);
        ApiClient.get("").then((res) => {
            if (res.success) {
                let url = res.path;
                let downloadAnchor = document.getElementById("downloadJS");
                downloadAnchor.href = url;
                downloadAnchor.click();
            }
            loader(false);
        });
    };

    const colClick = (col, itm) => {
        if (col.key == "healthClinicId") {
        }
    };

    const statusChange = (itm) => {
        let modal = "category";
        let status = "active";
        if (itm.status == "active") status = "deactive";

        if (
            window.confirm(
                `Do you want to ${status == "active" ? "Activate" : "Deactivate"} this`
            )
        ) {
            loader(true);
            ApiClient.put(``, { id: itm.id, status }).then((res) => {
                if (res.success) {
                    getData();
                }
                loader(false);
            });
        }
    };

    const edit = (id) => {
        setEdit(id);
        setAddShow(true);
    };

    const tabChange = (tab) => {
        setTab(tab);
    };

    const isAllow = (key = "") => {
        let permissions = user.role?.permissions[0];
        let value = permissions?.[key];
        if (user.role.id == environment.adminRoleId) value = true;
        return value;
    };
    const urlAllow = (url) => {
        if (user?.subRole?.id == environment.SubRolePartner) {
            return true;
        }
        let permissions =
            user?.subRole &&
            user?.subRole?.permissions &&
            user?.subRole?.permissions[0];
        let arr = url?.split(",");
        let value = false;
        arr.map((itm) => {
            if (permissions?.[itm]) value = true;
        });
        if (user?.subRole?.id || user?.subRole?._id) {
            return value;
        } else {
            return true;
        }
    };

    const [Edit, setEdit] = useState(null);

    const AddScreenRemover = () => {
        setEdit(null);
        setAddShow(false);
        getData()
    }


    return (
        <>
            {!AddShow ? <>
                <div className="flex items-center justify-between mb-6">
                    <div>
                        <h3 className="text-2xl font-semibold text-[#111827]">
                            Review Email Template
                        </h3>
                        <p class="text-sm font-normal text-[#75757A]">
                            Customise Your Email Templates - Edit and Preview
                        </p>
                    </div>
                    <button type="button" onClick={() => { setAddShow(true); setEdit(null) }} className="!px-4 whitespace-nowrap py-2 text-sm font-normal text-primary   border border-primary  flex items-center justify-center gap-2 bg-white rounded-extra_large shadow-btn hover:bg-primary hover:text-white transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">
                        <FiPlus className="text-xl" /> Add
                    </button>
                </div>

                <div className="bg-white shadow-box border !border-grey rounded-large w-full">
                    <div className="scrollbar rounded-large w-full overflow-auto">
                        <table class="w-full rounded-large">
                            <thead className="">
                                <tr className="">
                                    <th
                                        scope="col"
                                        className="cursor-pointer text-[#82838B] font-normal text-sm !border-r border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3 "
                                    // onClick={(e) => sorting("title")}
                                    >
                                        Title{" "}
                                        {/* <span className="ml-2">
                                            <HiOutlineArrowDown className="shrink-0 inline text-sm" />
                                        </span> */}
                                    </th>
                                    <th
                                        scope="col"
                                        className="cursor-pointer text-[#82838B] font-normal text-sm !border-r border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3 "
                                    // onClick={(e) => sorting("subject")}
                                    >
                                        Subject{" "}
                                        {/* <span className="ml-2">
                                            <HiOutlineArrowDown className="shrink-0 inline text-sm" />
                                        </span> */}
                                    </th>
                                    <th
                                        scope="col"
                                        className="cursor-pointer text-[#82838B] font-normal text-sm px-3.5 text-left bg-[#F7FAFF] !py-3 ">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {!loaging &&
                                    data &&
                                    data.map((itm, i) => {
                                        return (
                                            <tr className="">
                                                <td className="!text-typo !border-l-0 !border-b-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]">
                                                    <span className="" onClick={(e) => edit(itm.id)}>
                                                        {itm.title}
                                                    </span>
                                                </td>
                                                <td className="!text-typo !border-l-0 !border-b-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]">
                                                    <span className="" onClick={(e) => edit(itm.id)}>
                                                        {itm.subject}
                                                    </span>
                                                </td>

                                                <td className="!text-typo !border-x-0 !border-b-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]">
                                                    <div className="flex items-center justify-start gap-1.5">

                                                        {/* {urlAllow("editemail-list") && ( */}
                                                        <a
                                                            className="border cursor-pointer border-primary hover:opacity-70 rounded-large bg-[#48119614] w-10 h-10 !text-primary   flex items-center justify-center text-xl"
                                                            onClick={(e) => { setEdit(itm.id); setAddShow(true) }}>
                                                            <svg
                                                                stroke="#481196"
                                                                fill="none"
                                                                stroke-width="2"
                                                                viewBox="0 0 24 24"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                height="1em"
                                                                width="1em"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12 20h9"></path>
                                                                <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                                                            </svg>
                                                        </a>
                                                        {/* )} */}
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                </div>

                {!loaging && total == 0 ? (
                    <div className="py-3 text-center ">No Data</div>
                ) : (
                    <></>
                )}
                {!loaging && total > filters.count ? (
                    <div className="paginationWrapper mt-15 flex items-center justify-between">
                        <span className="text-sm text-gray-600">
                            Show {filters.count} from {total} Email Template
                        </span>
                        <Pagination
                            currentPage={filters.page}
                            totalSize={total}
                            sizePerPage={filters.count}
                            changeCurrentPage={pageChange}
                        />
                    </div>
                ) : (
                    <></>
                )}
                {loaging ? (
                    <div className="py-4 mx-5 text-center">
                        <img src="/assets/img/loader.gif" className="pageLoader" />
                    </div>
                ) : (
                    <></>
                )}
            </> : <AddEditReviewTemplate AddScreenRemover={AddScreenRemover} id={Edit} />}
        </>
    );
};

export default ReviewEmailTemplate;
