import React from "react";
import { Link } from "react-router-dom";
import methodModel from "../../../methods/methods";
import Sidebar from "../sidebar";
import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { FaRegUser } from "react-icons/fa";
import { IoSettingsOutline } from "react-icons/io5";
import { FiMenu, FiX, FiLogOut, FiChevronDown } from "react-icons/fi";
import ApiClient from "./../../../methods/api/apiClient";
import datepipeModel from "../../../models/datepipemodel";
import { IoIosNotificationsOutline } from "react-icons/io";
import { IoHelpOutline } from "react-icons/io5";
import { useResizeListener } from "../../../hooks/useResizeListener";
import { useNavigate } from "react-router-dom";
import { FcIdea } from "react-icons/fc";
import { SidebarItems } from "../../../utils/constants";
import { CiDatabase } from "react-icons/ci";
import FloatingChatbot from "./FloatingChatbot";
import ChatbotApiClient from "../../../methods/api/chatbotapiClient";
import loader from "../../../methods/loader";
import environment from "../../../environment";
import { useDispatch, useSelector } from "react-redux";
import { login_success } from "../../../actions/user";
import { Subscription } from "../../../actions/Subscription";
import { BiCheck } from "react-icons/bi";
import NavMenu from "./NavMenu";

const Html = ({
  isOpen,
  toggle,
  searchHandle,
  search,
  user,
  unreadCount,
  isOpen1,
  searchChange,
  clear,
  Logout,
  setIsOpen,
}) => {
  const WebDetails = useSelector((state) => state.logo);

  const CurrentServer = window.location.href?.includes("app.dazhboards.com")
    ? false
    : true;
  const [PinnedStates, setPinnedStates] = useState(null);
  useEffect(() => {
    if (user?.pinnedTabs && CurrentServer) {
      setPinnedStates([...user?.pinnedTabs]);
    } else {
      setPinnedStates([]);
    }
  }, [user]);
  const PathName = window.location.pathname;

  useResizeListener(1040, setIsOpen, setIsOpen);

  const [isChatbotVisible, setIsChatbotVisible] = useState(false);

  const handleToggleVisibility = () => {
    setIsChatbotVisible((prev) => !prev);
  };

  const handleCloseChatbot = () => {
    setIsChatbotVisible(false);
  };

  const history = useNavigate();

  const GetSidebarData = (data) => {
    SidebarItems?.find((item) => {
      if (item?.name == data) {
        return item?.icon || item?.name;
      }
    });
  };
  const logo = () => {
    let value = WebDetails?.logo;
    return value;
  };
  const logos = () => {
    let value = "/assets/img/logo-short.webp";
    return value;
  };

  const [AccessedAccount, setAccessedAccount] = useState([]);
  const GetAccountsAccess = () => {
    ApiClient.get(`api/user/account/access?id=${user?._id || user?.id}`).then(
      (res) => {
        if (res?.success) {
          setAccessedAccount(res?.data);
        }
      }
    );
  };
  useEffect(() => {
    GetAccountsAccess();
  }, []);

  const dispatch = useDispatch();
  const CompanyProfileUpdater = (item) => {
    loader(true);
    ApiClient.put(`api/change/company/profile`, {
      id: user?._id || user?.id,
      supplier_id: item?.supplier,
      subRole: item?.subRole,
    }).then((res) => {
      if (res.success) {
        ApiClient.get(`api/user/profile`, { id: user?._id || user?.id }).then(
          (res) => {
            if (res.success) {
              dispatch(Subscription(user));
              dispatch(login_success(res?.data));
              window.location = window.location;
            }
            loader(false);
          }
        );
      }
    });
  };
  return (
    <nav
      className={`w-full shadow-btn py-1.5 bg-white  fixed transition-[width] gap-2 duration-300 ml-auto right-0 z-50 flex-col  items-center !px-5
      `}>
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center">
          <button
            onClick={toggle}
            className="h-9 w-9 shrink-0 shadow-btn hover:shadow-none p-1 rounded-lg border border-gray-100 !text-primary  ">
            {isOpen ? (
              <FiMenu className="w-full h-full" />
            ) : (
              <FiX className="w-full h-full" />
            )}
          </button>
          <div
            className="!bg-transparent flex items-center grow ml-3"
            style={{ height: "50px" }}>
            <label className="editLogo">
              <img
                src={logo()}
                className="logocls show-logo"
                width="140"
                height="40"
              />
              <img src={logos()} className="hide-logo" width="24" height="24" />
            </label>
          </div>
        </div>
        {user?.sidebar ? <NavMenu /> : ""}
        <div>
          <Menu as="div" className="relative z-10 flex items-center">
            <div className="mr-2">
              {methodModel.CurrentServer() && (
                <div className="">
                  <button
                    type="button"
                    onClick={handleToggleVisibility}
                    className="mr-2 !px-4 text-typo text-xs 2xl:text-sm h-10 font-normal py-2.5 flex items-center justify-center gap-2 bg-[#fff] rounded-extra_large shadow-btn hover:bg-[#F3F2F5] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed min-w-[120px]">
                    <CiDatabase className="text-typo text-xl" />
                    AI Analyst
                  </button>
                  <FloatingChatbot
                    isVisible={isChatbotVisible}
                    onClose={handleCloseChatbot}
                  />
                </div>
              )}
            </div>
            <div className="flex items-center gap-0">
              {user?.order_syncing ? (
                <>
                  <div className="mr-4 min-w-[240px] text-xs 2xl:text-sm font-medium  text-[#2B2B2B] flex items-center gap-[3px] py-2 px-4 rounded-large bg-[#E0F4D3]">
                    Your Sales data is syncing now
                  </div>
                </>
              ) : (
                <></>
              )}

              {/* <div className="hover:bg-primary border-1 hover:text-white p-2 mr-4 duration-300 ease-out bg-gray-100 border rounded-full shadow-sm cursor-pointer">
                <div className="relative" onClick={() => window.open('https://dazhboards.com/faq/', "_blank")}>
                  <IoHelpOutline title="Help" className="text-[24px]" />
                </div>
              </div> */}
              <div className="hover:bg-primary border-1 hover:text-white p-2 mr-2 duration-300 ease-out bg-gray-100 border rounded-full shadow-sm cursor-pointer">
                <div className="relative">
                  <IoIosNotificationsOutline
                    className="text-[24px]"
                    onClick={(e) => history(`/omni-channels/live-chat`)}
                  />
                  {unreadCount && unreadCount != 0 ? (
                    <span className="noti-fi -top-2 -right-2 absolute w-2 h-2"></span>
                  ) : null}
                </div>
              </div>

              {user?.product_syncing ? (
                <>
                  <div className="mr-4 min-w-[240px] text-sm font-medium  text-[#2B2B2B] flex items-center gap-[3px] py-2 px-4 rounded-large bg-[#E0F4D3]">
                    Your Products data is syncing now
                  </div>
                </>
              ) : (
                <></>
              )}
              <Menu.Button className="hover:bg-black/5 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 rounded-large flex items-center justify-center w-full gap-2 px-2 py-2">
                <img
                  alt="image"
                  src={methodModel.userImg(user.image)}
                  className="object-cover w-10 h-10 rounded-full"
                  width="40"
                  height="40"
                />
                <div>
                  <p className="font-medium text-[#2D3036] text-sm">
                    {user.fullName}
                  </p>
                </div>
                <FiChevronDown className="w-5 h-5 text-[#2D3036]" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95">
              <Menu.Items className="ring-1 ring-black/5 z-[999999] focus:outline-none w-60 !rounded-large absolute top-14 right-0 mt-1.5 origin-top-right bg-white divide-y divide-gray-100 shadow-lg">
                <div className=" px-2 py-2">
                  <Menu.Item>
                    <Link
                      to={
                        user?.role?.name == "Affiliate"
                          ? "/affiliate/profile"
                          : "/profile"
                      }
                      className="group hover:bg-gray-100 hover:no-underline hover:!text-primary   flex items-center w-full gap-3 px-2 py-2 text-sm font-normal text-gray-900 rounded-large">
                      <FaRegUser />
                      Profile
                    </Link>
                  </Menu.Item>
                  {AccessedAccount?.length > 0 ? (
                    <Menu.Item>
                      <div className="justify-space-between flex">
                        <button
                          onClick={(e) =>
                            CompanyProfileUpdater({
                              supplier: null,
                              subRole: null,
                            })
                          }
                          className="group hover:bg-gray-100 hover:no-underline hover:!text-primary   flex items-center w-full gap-3 px-2 py-2 text-sm font-normal text-gray-900 rounded-large ">
                          <img
                            src={methodModel.userImg(user?.logo)}
                            width={15}
                            height={15}
                            className="rounded-full"
                          />
                          {user?.companyName}
                        </button>
                        {!user?.supplier_id && (
                          <BiCheck className="text-info mt-2" />
                        )}
                      </div>
                    </Menu.Item>
                  ) : null}
                  {AccessedAccount?.map((item) => (
                    <Menu.Item>
                      <div className="justify-space-between flex">
                        <button
                          onClick={(e) => CompanyProfileUpdater(item)}
                          className="group hover:bg-gray-100 hover:no-underline hover:!text-primary   flex items-center w-full gap-3 px-2 py-2 text-sm font-normal text-gray-900 rounded-large">
                          <img
                            src={methodModel.userImg(item?.logo)}
                            width={15}
                            height={15}
                            className="rounded-full"
                          />
                          {item?.companyName}
                        </button>
                        {item?.supplier == user?.supplier_id?._id && (
                          <BiCheck className="text-info mt-2" />
                        )}
                      </div>
                    </Menu.Item>
                  ))}

                  <Menu.Item>
                    <Link
                      to="/profile/change-password"
                      className="group hover:bg-gray-100 hover:no-underline hover:!text-primary   flex items-center w-full gap-3 px-2 py-2 text-sm font-normal text-gray-900 rounded-large">
                      <IoSettingsOutline />
                      Change Password
                    </Link>
                  </Menu.Item>
                </div>
                <div className="px-2 py-2">
                  <Menu.Item>
                    <button
                      onClick={() => Logout()}
                      className="group hover:bg-gray-100 hover:no-underline hover:!text-primary   flex items-center w-full gap-3 px-2 py-2 text-sm font-normal text-gray-900 rounded-large">
                      <FiLogOut />
                      Logout
                    </button>
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          {isOpen1 ? (
            <div className="mobi-dropdown w-full">
              <Sidebar />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Html;
