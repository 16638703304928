import React, { useState, useEffect, useRef } from "react";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
import { useSelector } from "react-redux";
import methodModel from "../../../methods/methods";
import { ReviewemailType } from "../../../models/type.model";
import { useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { IoArrowBackSharp } from "react-icons/io5";
import tinymcModel from "../../../models/tinymc.model";
import DOMPurify from "dompurify";
import parse from 'html-react-parser'
import SelectDropdown from "../../../components/common/SelectDropdown";

const AddEditReviewTemplate = ({ AddScreenRemover, id }) => {
  const defaultvalue = () => {
    let keys = { ...ReviewemailType };
    Object.keys(ReviewemailType).map((itm) => {
      if (itm != "permissions") keys[itm] = "";
    });
    keys.status = "active";
    return keys;
  };
  const [form, setform] = useState(ReviewemailType);
  const [tab, setTab] = useState("form");
  const history = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const user = useSelector((state) => state.user);
  const specialChars = useRef([]);
  const [variables, setVariables] = useState([
    "orderNumber",
    "userName",
    "productName",
    "supplierLogo",
    "companyName",
    "supplierName",
    "question"
  ]);
  const [htmlCode, setHtmlCode] = useState(false);
  const formValidation = [{ key: "subject", required: true }];

  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    setSubmitted(true);
    let invalid = methodModel.getFormError(formValidation, form);
    if (!form?.title || !form?.subject || !form?.type) {
      return
    }
    if (!form?.body) {
      return
    }
    let method = "post";
    let url = "api/review/invite/template";
    let value = {
      ...form,
      body: form?.content
    };
    if (value.id) {
      method = "put";
      url = `api/review/invite/template`;
    } else {
      delete value.id;
    }
    loader(true);
    ApiClient.allApi(url, value, method).then((res) => {
      if (res.success) {
        AddScreenRemover()
      }
      loader(false);
    });
  };

  const NavigatorCall = () => {
    history('/email/templates')
  }
  useEffect(() => {
    // getConstants()
    if (id) {
      loader(true);
      ApiClient.get("api/review/invite/template", { id }, "", true, { apiCall: true, Callfunction: NavigatorCall }).then(
        (res) => {
          if (res.success) {
            let value = res.data;
            let payload = ReviewemailType;
            Object.keys(payload).map((itm) => {
              payload[itm] = value[itm];
            });
            setform({
              ...payload,
              body: res?.data?.body,
              content: res?.data?.body
            });
          }
          loader(false);
        }
      );
    } else {
      setform(defaultvalue());
    }
  }, [id]);


  const textAreaRef = useRef(null);

  const insertVariable = (variable) => {
    if (!htmlCode) {
      return
    }
    try {
      const textarea = textAreaRef.current;
      const cursorPos = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const textBeforeCursor = form.content.substring(0, cursorPos);
      const textAfterCursor = form.content.substring(
        cursorPos,
        form.content.length
      );

      // Insert the variable at the cursor position
      const updatedText =
        textBeforeCursor + "{" + variable + "}" + textAfterCursor;
      textarea.value = updatedText;
      setform({ ...form, content: updatedText });

      // Ensure the textarea maintains focus after insertion
      textarea.focus();
      textAreaRef.current.selectionEnd = end + variable.length + 2;
    } catch (err) { }
  };

  function replaceSupplierLogo(template) {
    try {
      return template.replace(/{supplierLogo}/g, `<img style="display: block; margin-left: auto; margin-right: auto; height: 64px; object-fit: contain;" src=${methodModel.userImg(user?.logo)} />`);
    } catch {
      return template
    }
  }
  const [POSData, setPOSData] = useState([]);
  const GetPOSlisting = () => {
    try {
      const UserDomain = user?.email?.split("@");
      const filters = { status: "active", domains: UserDomain[1] || "" };
      ApiClient.get(`api/bookingsystem/listing`, filters, "", "", {
        apiCall: true,
      }).then((res) => {
        if (res.success) {
          setPOSData(res?.data)
        }
      });
    } catch {
    }
  }
  useEffect(() => {
    GetPOSlisting()
  }, [])


  const StaticTemplate = `<p>&nbsp;</p>
<div id="email-template-mobile-responsive" style="background-color: #f2f2f2; padding: 25px 0; border-radius: 20px;">
<table style="margin: 0 auto; max-width: 650px; overflow: hidden; border-radius: 30px;" border="0" width="100%" cellspacing="0" cellpadding="0" align="center" bgcolor="#F6F4FF">
<tbody>
<tr>
<td align="center">
<table class="wrapper" style="max-width: 650px;" border="0" width="100%" cellspacing="0" cellpadding="0" align="center" bgcolor="#FFFFFF">
<tbody>
<tr>
<td>
<table class="wrapper" border="0" width="100%" cellspacing="0" cellpadding="0" bgcolor="#ffffff">
<tbody>
<tr>
<td align="center">
<table class="wrapper" border="0" width="100%" cellspacing="0" cellpadding="0">
<tbody>
<tr>
<td class="wrapper" style="text-align: center;" width="100%">
{supplierLogo}
</tr>
<tr>
<td>
<table style="width: 90%; margin: auto;">
<tbody>
<tr>
<td style="border-bottom: 1px solid #cccccc;" height="15">&nbsp;</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
<table role="presentation" border="0" width="100%" cellspacing="0" cellpadding="0">
<tbody>
<tr>
<td align="center" valign="top" width="100%" height="100%">
<table style="max-width: 650px; height: 732.781px;" border="0" width="100%" cellspacing="0" cellpadding="0" align="center" bgcolor="#FFFFFF">
<tbody>
<tr style="height: 392.984px;">
<td class="decrease-padding" style="font-family: Inter, sans-serif; font-size: 16px; line-height: 1; color: #232323; font-weight: 400; padding: 0px 60px 0px 40px;" align="left" valign="top"><!-- Start of content here -->
<p style="margin: 0px !important;">&nbsp;</p>
<p style="margin: 0px !important;">&nbsp;</p>
<p style="line-height: 1.3; margin: 0px !important;">Hi {userName},</p>
<p style="line-height: 1.3; margin: 0px !important;">&nbsp;</p>
<p>Thank you for choosing {productName}! We hope you had an amazing experience with us. Your feedback helps us improve and also helps future travellers make informed decisions.</p>
<p>&nbsp;</p>
<p>Would you mind taking a minute to leave us a review? It would mean a lot to us!<br>&nbsp;</p>
<p style="text-align: center;"><button class="send_mail" style="border: none; background: #481196; width: 217px; color: #fff; height: 40px; border-radius: 40px; font-size: 16px;" type="button"> <a style="color: white; text-decoration: none;" href="{reviewLink}">&nbsp;Click here to review</a></button></p>
<p style="line-height: 1.3; margin: 0px !important;"><!-- <p>&nbsp;</p> --></p>
<p style="margin: 8px 0;">&nbsp;</p>
<p style="line-height: 1.3; margin: 0px !important;">Thank you for your time, and we hope to see you again soon!</p>
<p style="line-height: 1.3; margin: 0px !important;">&nbsp;&nbsp;</p>
<p style="line-height: 1.3; margin: 0px !important;">Thank you,<br>Team at {companyName}</p>
<!-- End of content here --></td>
</tr>
<tr style="height: 339.797px;">
<td style="line-height: 1;" align="center" valign="top" width="100%" height="100%">
<table border="0" width="100%" cellspacing="0" cellpadding="0" align="center" bgcolor="#ffffff">
<tbody>
<tr>
<td style="font-size: 16px;" align="center" valign="top"><!-- 1st box table start --><!-- 1st box table end --><!-- footer start --></td>
</tr>
<tr>
<td align="center" valign="top" width="100%" height="100%">
<table style="height: 140px;" border="0" width="100%" cellspacing="0" cellpadding="0" align="center" bgcolor="#ffffff"><!-- content start -->
<tbody>
<tr style="height: 24px;">
<td class="hide_section" height="23">&nbsp;</td>
</tr>
<!-- content start -->
<tr style="height: 17px;">
<td height="14">&nbsp;</td>
</tr>
<tr style="height: 26px;">
<td class="decrease-padding" style="font-family: 'Inter',                                                          Sans-Serif; font-size: 16px; line-height: 25px; padding-left: 33px; color: #500084; font-weight: 400; padding-bottom: 0; padding-top: 0;" align="left" valign="top">&nbsp;</td>
</tr>
<tr style="height: 26px;">
<td class="decrease-padding" style="font-family: 'Inter',                                                          sans-serif; font-size: 16px; line-height: 25px; font-weight: 400; padding: 0 40px 0 40px; color: #481196;" align="left" valign="top">&nbsp;</td>
</tr>
<tr style="height: 26px;">
<td class="decrease-padding" style="font-family: Inter, sans-serif; font-size: 16px; line-height: 25px; color: #481196; font-weight: 500; padding-top: 0px; padding-right: 40px; padding-bottom: 0px;" align="left" valign="top">&nbsp;</td>
</tr>
<!-- content end -->
<tr style="height: 21px;">
<td class="hide_section" style="font-size: 10px; line-height: 20px;" height="10">&nbsp;</td>
</tr>
<!-- Footer social icon  start--></tbody>
</table>
<table class="wrapper" role="presentation" border="0" width="100%" cellspacing="0" cellpadding="0" bgcolor=" #fff">
<tbody>
<tr>
<td class="wrapper" align="center" valign="top" width="100%" height="100%">
<table class="wrapper" style="max-width: 650px; padding: 0 0px; background-color: #ffffff;" border="0" width="100%" cellspacing="0" cellpadding="0" align="center">
<tbody>
<tr>
<td style="padding: 10px;" align="center" valign="top">
<table style="width: 100%;" border="0" width=" 100%" cellspacing="0" cellpadding="0" align="center">
<tbody>
<tr>
<td align="center" valign="top">
<table border="0" width="100%" cellspacing="0" cellpadding="0"><!-- scoil icon url -->
<tbody>
<tr>
<td align="center">
<table style="width: auto; margin: 0                                                                                    auto;" border="0" cellspacing="0" cellpadding="0" align="center">
<tbody>
<tr>
<td style="color: hsl(                                                                                          0,                                                                                          0%,                                                                                          100%                                                                                        );" align="center"><a style="color: #666666; text-decoration: none;" href="https://www.facebook.com/dazhboards/" target="_blank" rel="noopener"> <img style="margin: 0; width: 34px; border: 0; padding: 0; display: block;" src="https://dazhboards.com/wp-content/uploads/2024/07/email-facebook.png" alt="32x32x3" width="34" height="34"></a></td>
<td width="5">&nbsp;</td>
<td style="color: #666666;" align="center"><a style="color: #666666; text-decoration: none;" href="https://instagram.com/Dazhboards" target="_blank" rel="noopener"> <img style="margin: 0; width: 34px; border: 0; padding: 0; display: block;" src="https://dazhboards.com/wp-content/uploads/2024/07/email-instagram.png" alt="32x32x4" width="34" height="34"></a></td>
<td width="5">&nbsp;</td>
<td style="color: #666666;" align="center"><a style="color: #666666; text-decoration: none;" href="https://www.youtube.com/" target="_blank" rel="noopener"> <img style="margin: 0; width: 34px; border: 0; padding: 0; display: block;" src="https://dazhboards.com/wp-content/uploads/2024/07/email-youtube.png" alt="32x32x6" width="34" height="34"></a></td>
</tr>
</tbody>
</table>
</td>
</tr>
<!-- Unsubscribe section start -->
<tr></tr>
<tr>
<td>
<table style="max-width: 493px; width: 100%; margin: auto;">
<tbody>
<tr>
<td style="border-bottom: 1px                                                                                          solid                                                                                          #cccccc;" height="15">&nbsp;</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr></tr>
<tr>
<td class="wrapper" align="center" valign="top" width="100%" height="100%">
<table style="max-width: 600px; width: 100%;" border="0" width="100%" cellspacing="0" cellpadding="0" align="center" bgcolor="#FFFFFF">
<tbody>
<tr>
<td style="padding: 10px;" align="center" valign="top">
<table style="margin: auto; text-align: center; max-width: 600px; width: 100%;" border="0" width="100%" cellspacing="0" cellpadding="0" align="center">
<tbody>
<tr>
<td style="font-family: 'Inter',                                                                                                  sans-serif; font-size: 12px; line-height: 21px; padding: 0                                                                                                  0px; color: #b1b1b1; padding-bottom: 8px; text-align: center; font-weight: 400; max-width: 310px; display: block; margin: auto;" align="center" valign="top">&copy; Copyright 2024, All Rights Reserved <br>by {companyName}</td>
</tr>
<tr>
<td style="margin: auto; text-align: center; font-family: 'Inter',                                                                                                  sans-serif; font-size: 12px; line-height: 21px; padding: 0                                                                                                  0px; color: #b1b1b1; font-weight: 400;" align="center" valign="top">Privacy Policy | <a style="color: #a4a4a4; text-decoration: underline;" href="{% unsubscribe_link %}">Unsubscribe</a></td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
<!-- Unsubscribe end --></tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</div>
`
  return (
    <>
      <div className="max-w-[1420px]">
        {tab == "form" ? (
          <>
            <form onSubmit={handleSubmit}>
              <div className="pprofile1">
                <div className="flex justify-between">
                  <div className="flex items-center mb-8">
                    {/* <Tooltip placement="top" title="Back"> */}
                    <p
                      onClick={() => AddScreenRemover()}
                      className="cursor-pointer flex items-center justify-center  rounded-full shadow-box border !border-grey bg-white h-10 w-10 hover:bg-[#F3F2F5] border  transition-all    mr-3">
                      <IoArrowBackSharp />
                    </p>
                    {/* </Tooltip> */}
                    <div>
                      <h3 className="text-2xl font-semibold text-[#111827]">
                        {form && form.id ? "Edit" : "Add"} Review  Email Template
                      </h3>
                      <p class="text-sm font-normal text-[#75757A]">
                        Here you can see all about your Email
                      </p>
                    </div>

                  </div>
                  <div className="flex justify-end">
                    <button
                      type="button"
                      className="mx-2 ml-auto !px-4 text-sm font-normal text-primary   border border-primary h-9 flex items-center justify-center gap-2 bg-white rounded-extra_large shadow-btn hover:bg-primary hover:text-white transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed"
                      onClick={e => setform(prev => ({ ...prev, body: StaticTemplate, content: StaticTemplate }))}>Copy Sample content</button>
                  </div>
                </div>

                <div className="grid grid-cols-12 gap-4">
                  <div className="md:col-span-12 col-span-12">
                    <label className="text-sm font-normal text-[#75757A] !mb-3">
                      Title<span className="star">*</span>
                    </label>
                    <input
                      type="text"
                      className="shadow-box border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-4 !ring-primary !outline-primary disabled:!bg-gray-200"
                      value={form.title}
                      onChange={(e) =>
                        setform({ ...form, title: e.target.value, name: e.target.value })
                      }
                      required={submitted}
                    />
                    {submitted && !form?.title ? <p className="text-red-400 ml-3">Required</p> : null}
                  </div>
                  <div className="md:col-span-12 col-span-12">
                    <label className="text-sm font-normal text-[#75757A] !mb-3">
                      Subject<span className="star">*</span>
                    </label>
                    <input
                      type="text"
                      className="shadow-box border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-4 !ring-primary !outline-primary disabled:!bg-gray-200"
                      value={form.subject}
                      onChange={(e) =>
                        setform({ ...form, subject: e.target.value })
                      }
                      required={submitted}
                    />
                    {submitted && !form?.subject ? <p className="text-red-400 ml-3">Required</p> : null}
                  </div>

                  <div className="md:col-span-12 col-span-12">
                    <label className="text-sm font-normal text-[#75757A] !mb-3">
                      Type<span className="star">*</span>
                    </label>
                    <SelectDropdown
                      placeholder="Select Type"
                      options={[{ id: 'review', name: 'Review' }, { id: 'survey', name: 'Survey' }]}
                      intialValue={form?.type}
                      result={(e) =>
                        setform({ ...form, type: e?.value })
                      }
                      required={submitted}
                    />
                    {submitted && !form?.type ? <p className="text-red-400 ml-3">Required</p> : null}
                  </div>
                  <div className="md:col-span-12 col-span-12">
                    <div className="grid grid-cols-12 gap-4">
                      <div className="md:col-span-8 col-span-12">
                        <label className="text-sm font-normal text-[#75757A] block !mb-3">
                          Body<span className="star">*</span>
                        </label>
                        <div className="shadow-box border !border-grey w-full p-4 bg-white rounded-large">
                          <ul class="nav nav-tabs flex mb-3">
                            <li className="nav-item flex ml-2 mr-2 cursor-pointer">
                              <a
                                className={` pt-[1px] px-[4px] pb-[11px] block text-sm font-normal hover:!text-primary   hover:no-underline ${htmlCode
                                  ? "border-b-2 !border-primary !text-primary   !font-medium"
                                  : "!text-[#667085]"
                                  }`}
                                onClick={(e) => setHtmlCode(true)}>
                                Html Code
                              </a>
                            </li>
                            <li className="nav-item cursor-pointer">
                              <a
                                className={` pt-[1px] px-[4px] pb-[11px] block text-sm font-normal hover:!text-primary   hover:no-underline ${!htmlCode
                                  ? "border-b-2 !border-primary !text-primary   !font-medium"
                                  : "!text-[#667085]"
                                  }`}
                                onClick={(e) => setHtmlCode(false)}>
                                Editor
                              </a>
                            </li>
                          </ul>
                          {htmlCode ? (
                            <div className="shadow-box border !border-grey w-full p-4 bg-white rounded-large">
                              <textarea
                                ref={textAreaRef}
                                className="w-full   text-sm placeholder:text-gray-500 rounded-large h-[400px]  flex items-center gap-2 overflow-auto px-2 !ring-primary !outline-primary disabled:!bg-gray-200"
                                value={form.content}
                                onChange={(e) =>
                                  setform({
                                    ...form,
                                    content: e.target.value,
                                    body: e.target.value
                                  })
                                }></textarea>
                            </div>
                          ) : (
                            <>


                              <Editor ref={textAreaRef} textareaName='content'
                                value={form.content ? form.content : ''} className='tuncketcls'
                                onEditorChange={(newValue, editor) => {
                                  setform({ ...form, content: replaceSupplierLogo(newValue), body: replaceSupplierLogo(newValue) })
                                }}
                                apiKey={tinymcModel.apiKey}

                                init={{
                                  selector: 'textarea#autocompleter-cardmenuitem',
                                  height: 800,
                                  plugins: tinymcModel.plugins,
                                  toolbar: tinymcModel.toolbar,

                                }}
                                required
                              />



                            </>
                          )}
                          {!form?.body ? <p className="text-left pl-3 text-red-400">Body is required</p> : null}
                        </div>
                      </div>
                      <div className={`md:col-span-4 col-span-12 ${!htmlCode ? 'disabled' : ''}`}>
                        <label className="text-sm font-normal text-[#75757A] block !mb-3">
                          Variables
                        </label>

                        <div className="text-sm shadow-box border !border-grey p-3 bg-white rounded-large">
                          <ul className=" list-disc list-inside">
                            {variables.map((itm) => {
                              return (
                                <li
                                  className="pb-1 cursor-pointer"
                                  onClick={(e) => insertVariable(itm)}>
                                  {itm}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex text-right">
                  <button
                    type="button"
                    className="mx-2 ml-auto !px-4 text-sm font-normal text-primary   border border-primary h-9 flex items-center justify-center gap-2 bg-white rounded-extra_large shadow-btn hover:bg-primary hover:text-white transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed"
                    onClick={(e) => setTab("preview")}>
                    Preview
                  </button>
                  <button
                    type="submit"
                    className="!px-4 text-sm font-normal text-primary   border border-primary h-9 flex items-center justify-center gap-2 bg-white rounded-extra_large shadow-btn hover:bg-primary hover:text-white transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </>
        ) : (
          <>
            <div className="pprofile1">
              <h4 class="text-2xl font-semibold text-[#111827] mb-3">
                Preview
              </h4>
              <div
                className="shadow-box border !border-grey bg-white rounded-large"
              >
                {parse(`${methodModel.ConverterHtmlCode(DOMPurify.sanitize(replaceSupplierLogo(form?.content)))}`)}
              </div>
              <div className="flex justify-end gap-2 mt-3 text-right">
                <button
                  type="button"
                  className="!px-2.5 text-[#3C3E49] text-sm h-9 font-normal py-2.5 flex items-center justify-center gap-2 bg-[#fff] rounded-large shadow-btn hover:bg-[#F3F2F5] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed"
                  onClick={(e) => setTab("form")}>
                  Back
                </button>
                <button
                  className="!px-4 text-sm font-normal text-primary   border border-primary h-9 flex items-center justify-center gap-2 bg-white rounded-extra_large shadow-btn hover:bg-primary hover:text-white transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed"
                  onClick={(e) => handleSubmit()}>
                  Save
                </button>
              </div>
            </div>
          </>
        )}

        <div>
          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Body
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span
                  >
                    {parse(`${DOMPurify.sanitize(methodModel.ConverterHtmlCode(replaceSupplierLogo(form.content)))}`)}
                  </span>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEditReviewTemplate;
