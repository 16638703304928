import { useRef, useState, useEffect } from "react";
import Layout from '../../components/global/layout';
import { Link } from 'react-router-dom';

const MicrosoftHelp = ({ setProvider }) => {

    const microsoftRef = useRef(null);
    const godaddyRef = useRef(null);
    
    const [activeSection, setActiveSection] = useState("microsoft");

    const handleScroll = (ref, section) => {
        setActiveSection(section);
        ref.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    };

    // Auto-detect which section is in view
    useEffect(() => {
        const handleScroll = () => {
            const microsoftTop = microsoftRef.current?.getBoundingClientRect().top;
            const godaddyTop = godaddyRef.current?.getBoundingClientRect().top;

            if (microsoftTop <= 120 && godaddyTop > 120) {
                setActiveSection("microsoft");
            } else if (godaddyTop <= 120) {
                setActiveSection("godaddy");
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <div>
            <Layout>

                <div className='max-w-[1200px] mx-auto'>



                    <div className="grid grid-cols-12 gap-4">
                        {/* Left Section */}
                        <div className="col-span-12 md:col-span-7 xl:col-span-9">
                            <div className="sideinners">
                                <div className="max-w-4xl mx-auto p-4">
                                    <h1 className="text-3xl mb-1 max-w-lg font-bold">
                                        SMTP settings for Microsoft accounts (Microsoft Connector)
                                    </h1>
                                    <p className="text-gray-600 text-sm mb-4">Updated over a month ago</p>

                                    {/* Microsoft Direct Purchase Section */}
                                    <section ref={microsoftRef} className="mb-8">
                                        <h2 className="text-xl font-semibold">
                                            For Microsoft accounts (purchased directly from Microsoft)
                                        </h2>
                                        <p className="text-gray-700">
                                            Microsoft accounts are personal accounts created and managed by Microsoft.
                                            Here are the instructions on how to enable the SMTP setting for Microsoft 365/Outlook
                                            accounts purchased directly from Microsoft. You can watch the full video walkthrough{" "}
                                            <Link
                                                to="https://www.loom.com/share/865a8966ab5c47579533070cff432fc5?sid=f08cccc1-5e23-4331-8bd6-e46d017ba85d"
                                                className="text-primary underline"
                                            >
                                                here
                                            </Link>
                                        </p>
                                        <div className="my-4">
                                            <img
                                                className="bg-transparent"
                                                src="https://downloads.intercomcdn.com/i/o/1032998848/5c38d56943f05f5def0c5514/SMTP+settings+for+Microsoft+accounts+%28Microsoft+Connector%29.gif"
                                            />
                                        </div>

                                        <ol className="list-decimal list-inside mt-2 space-y-2">
                                            <li>Login to your <a href="#" className="text-blue-500">Microsoft Admin Center</a>.</li>
                                            <li>Navigate to <span className="font-semibold">Active Users</span>.</li>
                                            <li>Click on <span className="font-semibold">Mail</span> tab and select <span className="font-semibold">Manage email apps</span>.</li>
                                            <li>Check <span className="font-semibold">IMAP</span> and <span className="font-semibold">Authenticated SMTP</span>.</li>
                                            <li>Click <span className="font-semibold">Save changes</span>.</li>
                                            <li>Wait for an hour and connect your account to Instantly.</li>
                                        </ol>
                                    </section>

                                    {/* GoDaddy Section */}
                                    <section ref={godaddyRef} className="mb-8 border-t-4 pt-4 border-gray-200">
                                        <h2 className="text-xl font-semibold">
                                            For Microsoft accounts purchased from GoDaddy
                                        </h2>
                                        <p className="text-gray-700">
                                            Here are the instructions on how to enable the SMTP setting for Microsoft 365/Outlook
                                            accounts purchased from GoDaddy. You can watch the full video walkthrough{" "}
                                            <Link
                                                to="https://www.loom.com/share/865a8966ab5c47579533070cff432fc5?sid=f08cccc1-5e23-4331-8bd6-e46d017ba85d"
                                                className="text-primary underline"
                                            >
                                                here
                                            </Link>
                                        </p>
                                        <div className="my-4">
                                            <img
                                                className="bg-transparent"
                                                src="https://downloads.intercomcdn.com/i/o/1033001871/f7bcd12528cf97507293f9ad/SMTP+settings+for+Microsoft+accounts+%28Microsoft+Connector%29+%281%29.gif"
                                            />
                                        </div>

                                        <ol className="list-decimal list-inside mt-2 space-y-2">
                                            <li>Login to your <span className="font-semibold">GoDaddy account</span>.</li>
                                            <li>Navigate to <span className="font-semibold">My Products</span>.</li>
                                            <li>Scroll down and select <span className="font-semibold">Email and Office</span> section.</li>
                                            <li>Click <span className="font-semibold">Manage</span> and find the user you want to enable SMTP for.</li>
                                            <li>Click <span className="font-semibold">Manage</span> and select <span className="font-semibold">Advanced Settings</span>.</li>
                                            <li>Click on <span className="font-semibold">SMTP Authentication</span>.</li>
                                            <li>Wait for an hour and connect your account to Instantly.</li>
                                        </ol>
                                    </section>
                                </div>
                            </div>
                        </div>

                        {/* Right Section - Sticky Sidebar */}
                        <div className="col-span-12 md:col-span-5 xl:col-span-3">
                            <div className="sticky top-20">
                                <div className="micos_tit pt-6">
                                    <ul className="space-y-2 text-[14px] font-[500] pl-6">
                                        <li
                                            onClick={() => handleScroll(microsoftRef, "microsoft")}
                                            className={`cursor-pointer hover:text-primary border-l-2 ${activeSection === "microsoft" ? "border-primary text-primary" : "border-gray-200"
                                                } pl-4 py-1`}
                                        >
                                            For Microsoft accounts (purchased directly from Microsoft)
                                        </li>
                                        <li
                                            onClick={() => handleScroll(godaddyRef, "godaddy")}
                                            className={`cursor-pointer hover:text-primary border-l-2 ${activeSection === "godaddy" ? "border-primary text-primary" : "border-gray-200"
                                                } pl-4 py-1`}
                                        >
                                            For Microsoft accounts purchased from GoDaddy
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </Layout>
        </div>

    );
};

export default MicrosoftHelp;
