import { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import loader from "../../methods/loader"
import ApiClient from "../../methods/api/apiClient"
import "react-phone-input-2/lib/style.css";
import { IoChevronBack, IoClose } from "react-icons/io5"
import { IoMdStar } from "react-icons/io";
import moment from "moment";
import methodModel from "../../methods/methods";
import { FaCheckCircle, FaStar, FaStarHalfAlt } from "react-icons/fa";
import parse from 'html-react-parser'

const ReviewRating = ({ data = { modal: false } }) => {
    const Preview = methodModel.getPrams('preview') || false
    const { orderId, companyName } = useParams()
    const navigate = useNavigate();
    const [SubmittedSuccess, setSubmittedSuccess] = useState(false);
    const [orderDetail, setOrderDetail] = useState({})
    const SettingId = methodModel.getPrams('sid');
    const [hoverRating, setHoverRating] = useState(0);
    const [form, setForm] = useState({ rating: "" })
    const [step, setStep] = useState(1)
    const [googleReviews, setGoogleReviews] = useState([])
    const [readMore, setReadMore] = useState({ value: false, index: "" })
    const [submit, setSubmit] = useState(false)

    useEffect(() => {
        if (Preview) {
            let data = JSON.parse(localStorage.getItem('reviewRating') || `{}`)
            getGoogleReviews(data?.supplier?._id || data?.supplier?.id)
            setOrderDetail(data)
        } else {
            if (orderId) {
                getOrderDetail()
            }
        }

    }, [orderId])

    const getOrderDetail = () => {
        loader(true)
        // ApiClient.get(`api/order/detail/review?orderNumber=${orderId}&posName=${companyName}`).then(res=>{
        let filter = {
            orderNumber: orderId,
            sid: SettingId
        }
        if (!SettingId) {
            delete filter?.sid
        }
        if (filter?.orderNumber == 'null') {
            delete filter?.orderNumber
        }

        ApiClient.get(`api/order/detail/review`, filter).then(res => {
            if (res.success) {
                getGoogleReviews(res?.data?.settings?.addedBy)
                setOrderDetail(res?.data)
            }
            loader(false)
        })
    }

    const getGoogleReviews = (supplierId = "") => {
        loader(true)
        ApiClient.get(`api/reviews/listing/open`, { plateform: "Google", page: 1, count: 4, addedBy: supplierId }).then(res => {
            if (res.success) {
                setGoogleReviews(res)
            }
            loader(false)
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmit(true)
        const checkEmailValidation = methodModel.emailvalidation(form?.email || "")
        if (!checkEmailValidation && form?.email) return
        const payload = {
            orderId: orderDetail?._id || orderDetail?.id,
            reviewData: form,
            supplier: orderDetail?.supplier?.id || orderDetail?.supplier?._id,
            ...form,
            sid: SettingId

        }
        if (!SettingId) {
            delete filter?.sid
        }
        let productId = methodModel.getPrams('pid');
        if (productId) {
            payload['productId'] = productId
        }
        loader(true)
        ApiClient.put(`api/setting/review/feedback`, payload).then(res => {
            loader(false)
            setSubmittedSuccess(true)
        })
    }

    const handleRating = (rate) => {
        if (rate > 3) {
            if (Preview) {
                setStep(3)
            } else {
                ApiClient.put(`api/setting/review/invite`, { orderId: orderDetail?._id || orderDetail?.id, rating: rate }).then(res => {
                    if (res.success) {
                        setStep(3)
                    }
                    loader(false)
                })
            }
        } else {
            setStep(2)
        }
    }

    const handleReadMore = (index) => {
        const checkValue = readMore?.index === index ? !readMore?.value : true
        setReadMore({ value: checkValue, index: index })
    }

    const [Platforms, setPlatforms] = useState([])

    const getData2 = () => {
        let url = "api/review/platforms"
        let filter = {}
        ApiClient.get(url,).then((res) => {
            if (res.success) {
                setPlatforms(res.data)
            }
        })
    }
    useEffect(() => {
        getData2()
    }, [])

    // useEffect(()=>{
    //     if(!orderDetail?.page_settings?.show_google_widgets){
    //         setStep(2)
    //     }else{
    //         setStep(1)
    //     }
    // },[orderDetail?.page_settings?.show_google_widgets])

    const LogoReturner = (platform) => {
        let name = platform?.toLowerCase();
        let finLogo = Platforms?.find((_itm) => _itm?.platform?.toLowerCase() == name)?.logo
        return methodModel.userImg(finLogo || '')

    }



    function generateStars(rating) {
        rating = Math.round(Number(rating) * 2) / 2; // Ensure correct rounding

        const fullStars = Math.floor(rating); // Full stars count
        const hasHalfStar = rating % 1 !== 0; // Check for half-star
        const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0); // Remaining empty stars

        return (
            <div className="flex items-center gap-0.5">
                {/* Full Stars */}
                {[...Array(fullStars)].map((_, i) => (
                    <FaStar key={`full-${i}`} className="w-4 h-4 text-orange-500 pl-[2px] fill-orange-500" />
                ))}

                {/* Half Star */}
                {hasHalfStar && <FaStarHalfAlt key="half" className="w-4 h-4 text-orange-500 pl-[2px] fill-orange-500" />}

                {/* Empty Stars */}
                {[...Array(emptyStars)].map((_, i) => (
                    <FaStar key={`empty-${i}`} className="w-4 h-4 text-gray-300" />
                ))}
            </div>
        );
    }



    return (
        <>  <div className="relative h-screen flex 2xl:items-center py-10 px-6 overflow-auto justify-center z-50" style={{ backgroundColor: orderDetail?.page_settings?.background || "#d1d5db" }}>


            <div className={`${orderDetail?.page_settings?.show_google_widgets ? "max-w-[1200px] 2xl:max-w-[1400px] w-[100%]" : "max-w-[550px] w-[100%]"} mx-auto  transform  rounded-2xl  text-left align-middle  transition-all`}>
                <div
                    className={`${!orderDetail?.page_settings?.show_google_widgets ? "rounded-2xl w-full bg-white" : ""}`}
                    style={{ backgroundColor: orderDetail?.page_settings?.background || "#d1d5db" }}
                // style={{ backgroundImage: "url('/assets/img/review/review.jpg')", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}
                >
                    <div className=" flex items-center justify-center w-full">
                        <div className="flex flex-wrap lg:flex-nowrap gap-4 w-full">
                            {orderDetail?.page_settings?.show_google_widgets &&
                                <div className="relative w-[100%] lg:w-[380px] xl:w-[422px] shrink-0">
                                    <div className=" ">
                                        <div className="flex p-4 bg-white rounded-lg h-[124px] border border-gray-[#eaedf4] mb-3">
                                            <div className="flex items-center">
                                                {/* {orderDetail?.page_settings?.show_logo && */}

                                                <div className="w-12 h-12  bg-white opacity-50 rounded-full flex items-center shrink-0 justify-center">
                                                    <img
                                                        src={orderDetail?.supplier?.logo || "/assets/img/reviewpages.png"}
                                                        alt="globalicon"
                                                        className=" object-contain"
                                                    />
                                                </div>

                                                {/* } */}

                                                <div className="ml-4">
                                                    <h2 className="font-semibold leading-[22px] line-clamp-2 text-[20px]">{orderDetail?.supplier?.companyName || orderDetail?.supplier?.companyName}</h2>

                                                    <p style={{ cursor: 'pointer' }} className="text-xs text-primary mt-2 hover:underline flex items-center gap-2" onClick={() => window.open(orderDetail?.supplier?.website)}>
                                                        <p className="text-primary">{orderDetail?.supplier?.website}</p>
                                                        <img src="/assets/img/sends.svg" alt="Profile Picture" className="h-4 " />
                                                    </p>

                                                </div>
                                            </div>
                                        </div>





                                        {googleReviews?.data?.length !== 0 &&

                                            <div className="p-4 bg-white rounded-lg  ">
                                                <div className="bg-white pb-4  border-b border-[#EAEDF4] mb-4">
                                                    <div className="flex items-center gap-2 mb-4">
                                                        <img src="/assets/img/google.svg" alt="Profile Picture" className="w-8 h-8 object-contain rounded-lg" />
                                                        <p className="ml-2 font-semibold text-black-2 text-base">Google Rating</p>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <p className="font-semibold text-black-2 text-sm">{parseFloat(googleReviews?.avgRating || 0).toFixed(1)}</p>

                                                        <div className="flex items-center ml-2">
                                                            {generateStars(googleReviews?.avgRating || 0)}
                                                        </div>
                                                        <p className="text-[#595959] text-sm ml-2 font-normal">({googleReviews?.total || 0} reviews)</p>
                                                    </div>
                                                </div>
                                                <div className=" pr-4 top-0 overflow-auto scrollbar">
                                                    {googleReviews?.data?.map((item, index) => {
                                                        const comment = item?.ownerResponse?.comment || "";
                                                        const showReadMore = comment?.length > 100;

                                                        return <div className="border-t border-gray-[#eaedf4] first:border-t-0 pt-4 first:pt-0 pb-4">
                                                            <div className="flex items-center justify-between">
                                                                <div className="flex items-center">
                                                                    <img src="/assets/img/google.svg" alt="Profile Picture" className="w-8 h-8 object-contain rounded-lg" />
                                                                    <h3 className="ml-2 font-medium text-md">{item?.reviewBy}</h3>
                                                                </div>
                                                                <span className="text-gray-500 text-sm">{moment(item?.reviewDate).format("MMM, DD")}</span>
                                                            </div>
                                                            <div className="flex items-center mt-2 gap-1">
                                                                {generateStars(item?.rating || 0)}
                                                            </div>
                                                            {/* {item?.ownerResponse?.comment && */}
                                                            <div className="">
                                                                <p className={`${readMore?.index === index && readMore?.value ? "" : "line-clamp-2 "} min-h-[10px] text-[#595959]  mt-2 text-sm`}>{comment}</p>
                                                                {showReadMore && (
                                                                    <p className="mt-2 text-sm text-primary cursor-pointer" onClick={e => handleReadMore(index)}>
                                                                        {readMore?.index === index && readMore?.value ? "Read Less" : "Read More"}
                                                                    </p>
                                                                )}
                                                            </div>
                                                            {/* } */}
                                                        </div>
                                                    })}



                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }


                            <div className={`${!orderDetail?.page_settings?.show_google_widgets ? "p-6  pb-[13.36px] bg-white border border-gray-[#eaedf4] rounded-xl w-full  flex flex-col gap-4" : "bg-white px-4     py-4 rounded-lg border border-[#EAEDF4] w-full flex flex-col item-center justify-center"}`}>
                                {step === 1 &&
                                    <div className="rounded-lg">
                                        {orderDetail?.page_settings?.main_image &&
                                            <img src={`${orderDetail?.page_settings?.main_image || "/assets/img/reviewpages.png"}`} alt="Profile Picture" className="w-full rounded-lg h-52 xl:h-72 object-cover" />
                                        }
                                    </div>
                                }
                                <div className={`${!orderDetail?.page_settings?.show_google_widgets ? "flex flex-col gap-4    " : `flex flex-col gap-4    ${orderDetail?.page_settings?.main_image && step === 1 ? "p-6 max-w-lg w-full mx-auto h-full" : " p-6 max-w-lg w-full mx-auto"}`}`}>

                                    <>
                                        {orderDetail?.page_settings?.logo && orderDetail?.page_settings?.show_logo && <img className="mx-auto h-16 object-contain xl:h-20 " src={methodModel.userImg(orderDetail?.page_settings?.logo)} />}

                                        <h1 className="text-xl text-center text-black-2 font-semibold ">
                                            {orderDetail?.page_settings?.page_title || "You recently did a tour / activity with us, how will you rate your experience?"}
                                        </h1>
                                    </>

                                    <>
                                        {SubmittedSuccess ? null : <p className="text-black-2 font-normal text-sm  mb-4 text-center">
                                            {orderDetail?.page_settings?.message_for_cust || "You recently did a tour / activity with us, how will you rate your experience?"}
                                        </p>}
                                    </>
                                    {step === 1 &&
                                        <>
                                            <div className="flex justify-center gap-2 w-full">
                                                {[1, 2, 3, 4, 5].map((star) => (
                                                    <svg
                                                        key={star}
                                                        onClick={() => setForm((prev) => ({ ...prev, rating: star }))}
                                                        onMouseEnter={() => setHoverRating(star)}
                                                        onMouseLeave={() => setHoverRating(0)}
                                                        className={`w-10 h-10 cursor-pointer transform transition duration-300 ease-in-out ${hoverRating >= star || form?.rating >= star ? 'text-[#fc7130]' : 'text-[#d1d5db]'}`}
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20"
                                                    >
                                                        <polygon points="10 15.27 16.18 19 14.54 12.97 20 8.63 13.81 8.63 10 2 6.19 8.63 0 8.63 5.46 12.97 3.82 19" />
                                                    </svg>
                                                ))}
                                            </div>
                                            <div className="mt-4 w-full flex items-center justify-center">
                                                <button type="button" disabled={!form?.rating} onClick={e => handleRating(form?.rating)} className="border border-primary disabled:cursor-not-allowed h-10 w-full shadow-btn px-6 bg-primary text-white text-sm transition-all rounded-extra_large">Next</button>
                                            </div>
                                        </>
                                    }
                                    {step === 2 && (
                                        <div className="">
                                            {SubmittedSuccess ? <div className="text-center">
                                                <div className="text-center">
                                                    <FaCheckCircle className="text-[50px] mb-4  mx-auto text-primary" />
                                                </div>
                                                <h3 className="text-center text-2xl"> Submitted Successfully</h3>

                                            </div> : <form onSubmit={handleSubmit} className="">

                                                <div className="space-y-4">


                                                    {orderDetail?.page_settings?.formfields?.map((item, index) => {
                                                        const key = item?.name?.replaceAll(" ", "_").toLowerCase()
                                                        return <div key={index}>
                                                            <label className="flex mb-1 text-sm font-medium text-black-2 undefined">{item?.name}</label>
                                                            {item?.type === "email" ?
                                                                <>
                                                                    <input value={form?.[key]} onChange={e => setForm(prev => ({ ...prev, [key]: e.target.value }))} type={item?.type} className="text-sm font-normal border border-gray text-black-2 !placeholder-gray-2 focus:!border-primary hover:!border-primary h-10 flex w-full bg-white px-3 py-2.5 file:border-0 file:bg-transparent file:font-normal placeholder:text-muted-foreground focus-visible:outline-none rounded-lg pl-4 !border-gray-[#eaedf4] w-full" placeholder={`Enter ${item?.name}`} required />
                                                                    {!methodModel.emailvalidation(form?.[key]) && form?.[key] && submit ? <p className="text-red-600">Enter valid email</p> : null}
                                                                </>
                                                                :
                                                                item?.type === "number" ?
                                                                    <input value={form?.[key]} onChange={e => setForm(prev => ({ ...prev, [key]: methodModel.isNumber(e) }))} maxLength={15} type={"text"} className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 py-3 px-2" placeholder={`Enter ${item?.name}`} required />
                                                                    :
                                                                    item?.type?.toLowerCase() == 'textarea' ?
                                                                        <textarea
                                                                            value={form?.[key]} onChange={e => setForm(prev => ({ ...prev, [key]: e.target.value }))} className="text-sm font-normal border border-gray text-black-2 !placeholder-gray-2 focus:!border-primary hover:!border-primary h-24 flex w-full bg-white px-3 py-2.5 file:border-0 file:bg-transparent file:font-normal placeholder:text-muted-foreground focus-visible:outline-none rounded-lg pl-4 !border-gray-[#eaedf4] w-full" placeholder={`Enter ${item?.name}`} required
                                                                        >

                                                                        </textarea>
                                                                        :
                                                                        <input value={form?.[key]} onChange={e => setForm(prev => ({ ...prev, [key]: e.target.value }))} type={item?.type} className="text-sm font-normal border border-gray text-black-2 !placeholder-gray-2 focus:!border-primary hover:!border-primary h-10 flex w-full bg-white px-3 py-2.5 file:border-0 file:bg-transparent file:font-normal placeholder:text-muted-foreground focus-visible:outline-none rounded-lg pl-4 !border-gray-[#eaedf4] w-full" placeholder={`Enter ${item?.name}`} required />
                                                            }
                                                        </div>
                                                    })}
                                                </div>
                                                <button type="submit" disabled={Preview} className="flex items-center justify-center shrink-0 whitespace-nowrap rounded-lg hover:opacity-80 hover:border-transparent transition-all group px-2 h-10 text-sm font-medium h-10 text-white bg-primary w-full mt-8 undefined">Send</button>
                                            </form>}
                                            {SubmittedSuccess ? null : <p onClick={() => setStep(3)} className="font-normal text-sm text-primary underline mt-4 text-center cursor-pointer">Leave a public review</p>}
                                        </div>
                                    )}
                                    {step === 3 && (
                                        <div className="flex flex-col space-y-3 mb-2 px-4 items-center justify-center">
                                            {Preview ?
                                                <>
                                                    {orderDetail?.page_settings?.review_invite_links?.map((item, index) => {
                                                        const logo = orderDetail?.page_settings?.reviewPlatforms?.find((itm) => itm?.platform === item?.platform)?.logo
                                                        const name = orderDetail?.page_settings?.reviewPlatforms?.find((itm) => itm?.platform === item?.platform)?.name
                                                        if (!item?.checked) {
                                                            return null
                                                        }

                                                        return <button
                                                            key={index}
                                                            type="button"
                                                            onClick={() => window.open(item?.link, "_blank")}
                                                            className="flex items-center w-[454px] justify-center  py-3 bg-[#FDFDFD] rounded-lg border border-gray-[#eaedf4] hover:bg-gray-200 transition"
                                                        >
                                                            <img src={LogoReturner(item?.platform)} alt="Google" className="h-5 w-5 mr-2" />
                                                            {item?.location}
                                                        </button>
                                                        // }
                                                    })}
                                                </>
                                                :
                                                <>
                                                    {orderDetail?.page_settings?.review_invite_links?.map((item, index) => {
                                                        // if (orderDetail?.page_settings?.reviewPlatforms?.find((itm) => itm?.platformId?._id === item?.linkFor?.platform)) {
                                                        return <a
                                                            key={index}
                                                            target="_self"
                                                            href={item?.link}

                                                            className="flex items-center justify-center border border-gray-300 rounded-full py-2 px-4 bg-white text-gray-800 font-bold hover:bg-gray-200 transition"
                                                        >
                                                            <img src={LogoReturner(item?.platform)} alt="Google" className="h-5 w-5 mr-2" />
                                                            {item?.linkFor?.location}
                                                        </a>
                                                        // }
                                                    })}
                                                </>
                                            }
                                            {/* <p onClick={() => setStep(2)} className="mt-2 text-center text-sm text-blue-600 cursor-pointer">Leave a normal review</p> */}
                                        </div>
                                    )}
                                    {/* <p className="text-sm text-gray-[#eaedf4]00 text-center">Powered by <span className="font-semibold">Quick Reviews</span></p> */}
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>

        </>
    )
}

export default ReviewRating