import { useEffect, useState } from "react";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import Sidebar from "../sidebar";
import Header from "../header";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import methodModel from "../../../methods/methods";
import { FaArrowUp } from "react-icons/fa";
import environment from "../../../environment";
import swal from "sweetalert";
import socket from "../../../models/socket.model";
import { SidebarItems } from "../../../utils/constants";
import { useResizeListener } from "../../../hooks/useResizeListener";
import ApiClient from "../../../methods/api/apiClient";
import datepipeModel from "../../../models/datepipemodel";

const Layout = ({ children, classes = "" }) => {
  const WebDetails = useSelector((state) => state.logo);
  const [isOpen, setIsOpen] = useState(false);
  const user = useSelector((state) => state.user);
  const searchState = useSelector((state) => state.search);
  const history = useNavigate();
  const URLPath = window.location.pathname;
  const [showIcon, setshowIcon] = useState(false);

  useEffect(() => {
    if (!user?.loggedIn || !localStorage.getItem("token")) {
      history("/login");
    } else {
      if (window.location.pathname == "/") {
        if (!user.companyName) {
          history();
        } else {
          history("/dashboard");
        }
      }
    }
  }, [searchState, user]);

  const logo = () => {
    let value = WebDetails?.logo;
    return value;
  };
  const logos = () => {
    let value = "/assets/img/logo-short.webp";
    return value;
  };
  const router = () => {
    let route = localStorage.getItem("route");
    history(route);
  };

  const scrollToTop = () => {
    window.scroll({ top: 0, behavior: "smooth" });
  };

  window.onscroll = function () {
    if (document.documentElement.scrollTop <= 50) {
      setshowIcon(false);
    } else {
      setshowIcon(true);
    }
  };

  const ParnterNavigateHandler = () => {
    if (user?.subRole?.id == environment.SubRolePartner) {
      if (user?.subscriptionId || user?.on_trial || user?.supplier_id) {
      } else {
        if (
          URLPath == "/review-dashboard" ||
          URLPath == "/reviews" ||
          URLPath == "/reseller-dashboard" ||
          URLPath == "/reseller-data" ||
          URLPath == "/crm" ||
          URLPath == "/waiver" ||
          URLPath == "/accounting/dashboard" ||
          URLPath == "/accounting/transactions" ||
          URLPath == "/accounting/invoices" ||
          URLPath == "/omni-channels/live-chat" ||
          URLPath == "/plans" ||
          URLPath == "/activeplan" ||
          URLPath == "/marketing/google-analytics" ||
          URLPath == "/users" ||
          URLPath == "/card" ||
          URLPath == "/pos" ||
          URLPath == "/coupons" ||
          URLPath == "/coupons-data"
        ) {
          history(-1);
          swal({
            text: "If you want to use this email as Supplier account. Ask your admin to make it disable or you can signup with new email as supplier",
            icon: "error",
          });
        }
      }
    }
  };
  useEffect(() => {
    ParnterNavigateHandler();
  }, [URLPath]);
  const CurrentServer = window.location.href?.includes("app.dazhboards.com")
    ? false
    : true;
  const [PinnedStates, setPinnedStates] = useState(null);
  useEffect(() => {
    if (user?.pinnedTabs && CurrentServer) {
      setPinnedStates([...user?.pinnedTabs]);
    } else {
      setPinnedStates([]);
    }
  }, [user]);

  useResizeListener(1440, setIsOpen, setIsOpen);
  const [CompanyData, setCompanyData] = useState(user);
  useEffect(() => {
    if (user?._id) {
      ApiClient.get(`api/user/profile`, {
        id: user?.supplier_id?._id || localStorage.getItem("_id") || user?._id,
      }).then((response) => {
        if (response.success) {
          setCompanyData({ ...response?.data });
        }
      });
    }
  }, [user]);
  const [activePlan, setActivePlan] = useState();

  useEffect(() => {
    // api/my/plan
    if (localStorage.getItem("token")) {
      ApiClient.get("api/my/plan", {}, "", "", { apiCall: true }).then(
        (res) => {
          if (res.success) {
            setActivePlan(res.data);
          }
        }
      );
    }
  }, []);
  return (
    <>
      <div component="layout">
        <div onClick={(e) => router()} id="routerDiv"></div>
        <Header />
        <div className={`main-wrapper flex !w-full `}>
          <div
            className={`main-sidebar ${
              user?.sidebar ? "active-sidebar w-0 p-0" : ""
            } scrollbar transition-[width] duration-300`}>
            <div
              className="sidebar-brand p-3 pt-3 pl-5 text-left !bg-transparent !mb-0"
              style={{ height: "50px" }}>
              <label className="editLogo">
                <img
                  src={logo()}
                  className="logocls show-logo"
                  width="250"
                  height="35"
                />
                <img
                  src={logos()}
                  className="hide-logo"
                  width="24"
                  height="24"
                />
              </label>
            </div>
            {user?.logo ? (
              <>
                <div
                  className="flex items-center justify-center mt-2"
                  style={{ flexDirection: "column" }}>
                  <img
                    src={methodModel.userImg(CompanyData?.logo || "")}
                    alt="photo"
                    className="object-contain w-16 h-16 mt-2"
                  />
                  <br />
                  <div className="text-center">{CompanyData?.companyName}</div>
                </div>
                {activePlan?.on_trial &&
                new Date(activePlan?.validUpTo) > new Date() &&
                !activePlan?.freePlan ? (
                  <>
                    <div className="mx-3   text-xs 2xl:text-sm font-medium  text-[#2B2B2B] flex items-center gap-[3px] py-2 px-4 rounded-large bg-[#E0F4D3]">
                      Your trial expire on{" "}
                      <span className="underline-offset-4 underline">
                        {datepipeModel.date(
                          activePlan?.validUpTo,
                          user.companyDateFormat
                        )}
                      </span>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : null}
            <Sidebar />
          </div>
          <main
            className={`main transition-[width] duration-500 scrollbar ${
              user?.sidebar ? "!w-full" : ""
            }`}>
            <div className="!bg-[#F8F9FB] !p-5 mt-[69px] layout-drops">
              {CurrentServer && false && user?.pinnedTabs?.length > 0 && (
                <div className="rounded-xl flex flex-wrap gap-2 p-4 mb-8 bg-white">
                  {user?.pinnedTabs.map((item) => (
                    <div
                      style={{ cursor: "pointer" }}
                      className="flex flex-wrap justify-start gap-4"
                      key={item}
                      onClick={(e) =>
                        history(
                          SidebarItems?.find((itemo) => itemo?.name == item)
                            ?.Link || ""
                        )
                      }>
                      <div className="border-gray px-2 py-1 text-xs bg-gray-100 border rounded-full">
                        {typeof item === "string" ? item : ""}
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {children}
            </div>
          </main>
        </div>
        {URLPath == "/sales" ||
        URLPath == "/dashboard" ||
        URLPath == "/review-dashboard" ||
        URLPath == "/product-dashboard" ||
        URLPath == "/customers" ||
        URLPath == "/marketing/google-analytics" ||
        URLPath == "/accounting/dashboard" ? (
          <div
            className={`z-[999] h-10 w-10 fixed bottom-16 right-16 cursor-pointer shadow-box border !border-grey bg-white flex items-center justify-center d-${
              showIcon ? "block" : "none"
            }`}>
            <FaArrowUp
              className={`d-${showIcon ? "block" : "none"}`}
              onClick={(e) => scrollToTop()}
              id="arrow-up"
              title="Back Top"
            />
          </div>
        ) : null}
      </div>
      <ToastContainer position="top-right" />
    </>
  );
};
export default Layout;
