export const CostingTourAPIS = {
  list: "api/template/product/expense/all",
  statusChange: "api/template/product/expense/status",
  delete: "api/template/product/expense",
  add: "api/template/product/expense",
  edit: "api/template/product/expense",
  view: "api/template/product/expense",
  export: "",
};
export const CostingTourURL = {
  add: "/costing/add",
  edit: "/costing/edit",
  copy: "/costing/add",
  list: "/costing/template",
};
export const CostingTourConfig = {
  ListHeader: "Costing  Templates",
  AddHear: " Costing  Template",
  permissionKey: "costing",
};

export const CostingTourNormalInputs = [
  {
    name: "title",
    required: true,
    placeHolder: "Enter Title here",
    outerClass: "col-span-2 mb-2 lg:col-span-12",
    label: "Title",
    type: "text",
    className:
      "shadow-box border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary",
  },
  {
    name: "people_per_vehicle",
    required: true,
    placeHolder: "Enter People Per Vehicle here",
    outerClass: "col-span-2 mb-2 lg:col-span-12",
    label: "People Per Vehicle",
    type: "number",
    className:
      "shadow-box border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary",
  },
];
