import React from "react";
import methodModel from "../../methods/methods";
import { BiDownload } from "react-icons/bi";
import { FiFileText } from "react-icons/fi";

export default function SignComplete() {
    const pdfUrl = methodModel.getPrams("link");
    const documentId = methodModel.getPrams("documentId");
    const date = methodModel.getPrams("date");

    return (
        <div className="h-screen w-screen flex flex-col items-center justify-center bg-gradient-to-br from-blue-400 to-indigo-600 text-white p-6">
            <div className="w-full max-w-4xl bg-white p-8 rounded-2xl shadow-2xl text-center relative transform transition-all ">
                <div className="flex flex-col items-center">
                    <FiFileText size={60} className="text-blue-500" />
                    <h2 className="text-3xl font-bold mt-3 text-gray-800">Signed Document</h2>
                    <p className="text-gray-600 text-sm mt-2">Document ID: <span className="font-medium">{documentId}</span></p>
                    <p className="text-gray-600 text-sm">Signed on: <span className="font-medium">{date}</span></p>

                    {/* Document Viewer */}
                    <div className="w-full mt-3 rounded-lg overflow-hidden  customscrollbar">
                        <iframe
                            src={`${pdfUrl}#toolbar=0`}
                            className="w-full h-[70vh]"
                            type="application/pdf"
                            title="Signed Document Preview"
                        />
                    </div>
                </div>
            </div>

            {/* Floating Download Button */}
            <button
                className="fixed bottom-6 right-6 bg-blue-600 hover:bg-blue-700 text-white p-4 rounded-full shadow-lg transition-all transform hover:scale-110 flex items-center gap-2"
                onClick={() => window.open(pdfUrl, "_blank")}
            >
                <BiDownload size={24} />
                <span className="hidden sm:inline">Download PDF</span>
            </button>
        </div>
    );
}
