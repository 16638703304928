import { useEffect } from "react";

export const useResizeListener = (width, onTrue, onFailed) => {
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= width) {
        onTrue(true);
      } else {
        onFailed(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
};
