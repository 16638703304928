import { useDispatch, useSelector } from "react-redux";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import { RiHome6Line, RiContractLine } from "react-icons/ri";
import { Disclosure, Transition } from "@headlessui/react";
import { TiArrowSortedDown } from "react-icons/ti";
import { TbChartBar, TbFileReport } from "react-icons/tb";
import { PiHandCoins, PiHandbagBold } from "react-icons/pi";
import {
  FiCloud,
  FiCloudOff,
  FiPackage,
  FiSettings,
  FiThumbsUp,
  FiUsers,
} from "react-icons/fi";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { HiOutlineInbox } from "react-icons/hi2";
import { RiCoupon2Fill } from "react-icons/ri";
import { LuFolderSymlink, LuLock } from "react-icons/lu";
import { FaRegStar, FaStar } from "react-icons/fa";
import styles from "./index.module.css";
import {
  IoFileTrayFullOutline,
  IoChatboxEllipsesOutline,
} from "react-icons/io5";
import environment from "../../../environment";
import { useEffect, useRef, useState } from "react";
import ApiClient from "../../../methods/api/apiClient";
import { login_success, logout } from "../../../actions/user";
import ChatbotIcon from "../../../assets/chatbot-icon";
import { CostingConfig, CostingURL } from "../../../pages/CostingTemplate/Api";
import { GmailTempURL } from "../../../pages/GmailTemplate/Api";
import { CostingTourURL } from "../../../pages/CostPerTourTemplate/Api";
import { BannerURL } from "../../../pages/Banner/Api";
import { rule_base } from "../../../actions/rules";
import { sidebar_base } from "../../../actions/sidebar";
import { MdLockOutline, MdOutlineReviews } from "react-icons/md";
import methodModel from "../../../methods/methods";
import { PlanModules } from "./Constant";
import { GrChannel } from "react-icons/gr";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";

const Html = ({
  ListItemLink,
  tabclass,
  urlAllow,
  route,
  isOpen,
  scrollToId,
  SidebarLoad,
  setSidebarLoad,
  CollapsedState,
  setCollapsedState,
  expandAll,
  setExpandAll,
}) => {
  const [ActivePlanData, setActivePlanData] = useState({});

  // const CurrentServer = true
  const CurrentServer = window.location.href?.includes("app.dazhboards.com")
    ? false
    : true;
  const location = useLocation();
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const ValidationNumber =
    user?.supplier_id?.id ||
      user?.subRole?.id == environment.SubRolePartner ||
      user?.subRole?._id == environment.SubRolePartner
      ? 0
      : 0;
  const navigate = useNavigate();
  const dataConnectionLink = [
    "/pos",
    "/api/review-connected",
    "/api/review",
    "/marketing-analytics",
    "/api/accounting",
    "/marketing-software",
  ];

  const dataConnection = dataConnectionLink.includes(location.pathname);
  const [CompanyData, setCompanyData] = useState(user);
  useEffect(() => {
    if (localStorage.getItem("token") && user?._id) {
      ApiClient.get(
        `api/user/profile`,
        { id: localStorage.getItem("_id") || user?._id },
        "",
        "",
        {
          apiCall: true,
        }
      ).then((res) => {
        if (res.success) {
          if (localStorage.getItem("_id") != res?.data?._id) {
          } else {
            setData(res.data);
            const data = res?.data;
            if (data?.subRole && data?.subRole?._id) {
              data["subRole"] = { ...data?.subRole, id: data?.subRole?._id };
            }
            dispatch(login_success({ ...data, image: res?.data?.image }));
          }
        }
      });
    }
  }, []);

  const [RuleData, setRuleData] = useState({});
  const RuleBasedData = () => {
    if (localStorage.getItem("token")) {
      ApiClient.get(`api/users/sidebar/data-count`, {}, "", "", {
        apiCall: true,
      }).then((res) => {
        if (res.success) {
          setRuleData(res.data);
          dispatch(rule_base(res.data));
        }
      });
    }
  };

  useEffect(() => {
    RuleBasedData();
  }, []);

  const FavUnFavReturn = (name = "") => {
    if (true) {
      return null;
    }
    if (user?.pinnedTabs?.includes(name)) {
      return (
        <span onClick={(e) => FavUnFavUpdater(e, true, name)}>
          {" "}
          <FaStar className="text-primary  " />
        </span>
      );
    } else {
      return (
        <span onClick={(e) => FavUnFavUpdater(e, false, name)}>
          {" "}
          <FaRegStar className="text-primary  " />
        </span>
      );
    }
  };
  const FavUnFavUpdater = (e, type, name) => {
    e.preventDefault();
    if (!CurrentServer) {
      return null;
    }
    let data5 = user?.pinnedTabs || [];
    if (type) {
      data5 = user?.pinnedTabs?.filter((item) => item != name);
    } else {
      data5.push(name);
    }

    dispatch(login_success({ ...user, pinnedTabs: data5 }));
    ApiClient.put(`api/user/profile`, { id: user?._id, pinnedTabs: data5 });
  };
  const SubsciptionData = useSelector((state) => state.Subscription);

  const PlanFeatureAvailable = (feature = "", type = null) => {
    let data = SubsciptionData;
    // if (user?.on_trial) {
    //   return true
    // } else {
    // return true
    if (!type) {
      return data?.includes(feature);
    } else {
      if (!data?.includes(feature)) {
        return <MdLockOutline />;
      }
    }

    // }
  };

  const ParentModuleSubscriptionFinder = (data) => {
    let value = false;
    data?.map((_check) => {
      if (PlanFeatureAvailable(_check)) {
        value = true;
      }
    });
    return value;
  };
  const DataConnectCheck = () => {
    if (
      ParentModuleSubscriptionFinder([
        "product-data",
        "product-insights",
        "sales-data",
        "sales-insights",
      ])
    ) {
      return "/pos";
    } else if (
      ParentModuleSubscriptionFinder(["review-management", "review-insights"])
    ) {
      return "/api/review-connected?show=false";
    } else if (ParentModuleSubscriptionFinder(["chat"])) {
      return "/omni-channel-setting/copy";
    } else if (
      ParentModuleSubscriptionFinder([
        "google-analytics-summary",
        "google-ads",
        "facebook-ads",
        "manage-google-tags",
      ])
    ) {
      return "/marketing-analytics";
    } else if (
      ParentModuleSubscriptionFinder(["profitability-data", "insights"])
    ) {
      return "/api/accounting";
    } else {
      return "/plans";
    }
  };

  const ParentModulePermission = (data) => {
    let value = data?.some((_check) => urlAllow(`read${_check}`));
    return value;
  };

  const navContainerRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (navContainerRef.current) {
        const activeLink = navContainerRef.current.querySelector(
          '[aria-current="page"]'
        );

        if (activeLink) {
          activeLink.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
    }, 100);
  }, [location.pathname]);

  return (
    <>
      <div
        className={`px-[8px] ${isOpen && styles.sm_sidebar}`}
        ref={navContainerRef}
      >
        <h6
          className={`${isOpen ? "py-[12px] text-center" : "py-[12px]"
            } text-xs font-medium text-[#7E8B99] mt-[12px]`}
        >
          <p className="flex items-center justify-between gap-4 max-xl:flex-wrap">
            <span className="sidebar_text"> MAIN MENU </span>
            <p
              className=" flex items-center gap-1 xl:gap-2 max-lg:text-[8px] whitespace-nowrap"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                setExpandAll(!CollapsedState ? true : false);
                setCollapsedState(!CollapsedState);
                dispatch(
                  sidebar_base({
                    value: !CollapsedState,
                    allState: !CollapsedState,
                  })
                );
                setSidebarLoad(true);
                setTimeout(() => {
                  setSidebarLoad(false);
                }, 100);
              }}
            >
              {!CollapsedState ? "Expand All" : "Collapse All"}
              <TiArrowSortedDown
                className={`${!CollapsedState
                  ? "-rotate-90 transform"
                  : "rotate-0 transform"
                  } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
              />
            </p>
          </p>
        </h6>
        { 
          !(user?.bokun_user && (user.contacts.length < 1)) && (
            user?.subscriptionId ||
            user?.on_trial ||
            user?.supplier_id?.id ||
            user?.subRole?.id == environment.SubRolePartner ||
            user?.subRole?._id == environment.SubRolePartner
          ) ? (
          <>
            {SidebarLoad ? null : (
              <ul className="space-y-2 !list-none">
                {urlAllow("readdashboard") &&
                  ((PlanFeatureAvailable("dashboard") && !expandAll) ||
                    expandAll) ? (
                  <li
                    className={
                      PlanFeatureAvailable("dashboard")
                        ? ""
                        : "pointer-events-none"
                    }
                  >
                    <NavLink
                      to="/dashboard"
                      id="1"
                      className={({ isActive }) =>
                        "p-2.5 rounded-md flex items-center gap-[12px] text-sm font-normal text-[#4A545E] hover:!text-[#00b884] hover:bg-[#00b884]/10 !no-underline transition-all " +
                        (isActive &&
                          " !text-[#00b884] !bg-[#EDECF9] !font-medium")
                      }
                    >
                      <RiHome6Line className="text-[#00b884] shrink-0 text-lg" />
                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                        Dashboard {FavUnFavReturn("Dashboard")}{" "}
                        {PlanFeatureAvailable("dashboard", "dashboard")}
                      </span>
                    </NavLink>
                  </li>
                ) : null}

                <li
                  className={
                    ParentModuleSubscriptionFinder(
                      PlanModules["Customer Relations"]
                    )
                      ? ""
                      : "pointer-events-none"
                  }
                >
                  <Disclosure as="div" defaultOpen={tabclass("omni")}>
                    {({ open }) => (
                      <>
                        {ParentModulePermission(
                          PlanModules["Customer Relations"]
                        ) && (
                            <Disclosure.Button
                              onClick={(e) => {
                                setCollapsedState(true);
                                dispatch(sidebar_base({ value: true }));
                              }}
                              className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-primary   hover:bg-primary/10 transition-all"
                            >
                              <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                                <GrChannel className="text-[#00b884] shrink-0 text-lg" />
                                <span className="sidebar_text">
                                  Omni Channel
                                  {ParentModuleSubscriptionFinder(
                                    PlanModules["Customer Relations"]
                                  ) ? null : (
                                    <MdLockOutline />
                                  )}
                                </span>
                              </span>
                              <TiArrowSortedDown
                                className={`${open ? "" : "-rotate-90 transform"
                                  } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                              />
                            </Disclosure.Button>
                          )}
                        <Transition
                          enter="transition duration-300 ease-in-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-300 opacity-300"
                          leave="transition duration-300 ease-in-out"
                          leaveFrom="transform scale-300 opacity-300"
                          leaveTo="transform scale-95 opacity-0"
                        >
                          <Disclosure.Panel
                            className={`${!isOpen && "pl-[25px]"} mt-[4px] `}
                          >
                            <ul className="space-y-2 !list-none">
                              {urlAllow("readchatbot") &&
                                ((PlanFeatureAvailable("chatbot") &&
                                  !expandAll) ||
                                  expandAll) ? (
                                <li
                                  className={
                                    PlanFeatureAvailable("chatbot")
                                      ? ""
                                      : "pointer-events-none"
                                  }
                                >
                                  <NavLink
                                    to="/chatbot"
                                    id="2"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md flex items-center gap-[12px] text-sm font-normal text-[#4A545E] hover:!text-primary   hover:bg-primary/10 !no-underline transition-all " +
                                      (isActive &&
                                        " !text-primary   !bg-[#705EE6]/10 !font-medium")
                                    }
                                  >
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      {" "}
                                      Chatbot{FavUnFavReturn("Chatbot")}
                                      {PlanFeatureAvailable(
                                        "chatbot",
                                        "chatbot"
                                      )}
                                    </span>
                                  </NavLink>
                                </li>
                              ) : null}

                              {urlAllow("readchat") &&
                                ((PlanFeatureAvailable("chat") && !expandAll) ||
                                  expandAll) ? (
                                <li
                                  id="/omni-channels/live-chat"
                                  className={
                                    PlanFeatureAvailable("chat")
                                      ? ""
                                      : "pointer-events-none"
                                  }
                                >
                                  <NavLink
                                    to="/omni-channels/live-chat"
                                    id="3"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-primary   hover:bg-primary/10 !no-underline transition-all " +
                                      (isActive &&
                                        " !text-primary   !bg-[#705EE6]/10 !font-medium")
                                    }
                                  >
                                    {isOpen && (
                                      <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Inbox {FavUnFavReturn("Chat")}
                                      {PlanFeatureAvailable("chat", "chat")}
                                    </span>
                                  </NavLink>
                                </li>
                              ) : null}

                              <Disclosure
                                as="div"
                                defaultOpen={tabclass("templates")}
                              >
                                {({ open }) => (
                                  <>
                                    <Disclosure.Button
                                      onClick={(e) => {
                                        setCollapsedState(true);
                                        dispatch(sidebar_base({ value: true }));
                                      }}
                                      className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-primary   hover:bg-primary/10 transition-all"
                                    >
                                      <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                                        <span className="sidebar_text">
                                          Templates
                                        </span>
                                      </span>
                                      <TiArrowSortedDown
                                        className={`${open ? "" : "-rotate-90 transform"
                                          } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                                      />
                                    </Disclosure.Button>
                                    <Transition
                                      enter="transition duration-300 ease-in-out"
                                      enterFrom="transform scale-95 opacity-0"
                                      enterTo="transform scale-300 opacity-300"
                                      leave="transition duration-300 ease-in-out"
                                      leaveFrom="transform scale-300 opacity-300"
                                      leaveTo="transform scale-95 opacity-0"
                                    >
                                      <Disclosure.Panel
                                        className={`${!isOpen && "pl-[25px]"
                                          } mt-[4px] `}
                                      >
                                        <ul className="space-y-2 !list-none">
                                          {urlAllow("readwhatsapp") &&
                                            ((PlanFeatureAvailable("whatsapp") &&
                                              !expandAll) ||
                                              expandAll) ? (
                                            <li
                                              id="/whatsapp/template"
                                              className={
                                                PlanFeatureAvailable("whatsapp")
                                                  ? ""
                                                  : "pointer-events-none"
                                              }
                                            >
                                              <NavLink
                                                to="/whatsapp/template"
                                                className={({ isActive }) =>
                                                  "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-primary   hover:bg-primary/10 !no-underline transition-all " +
                                                  (isActive &&
                                                    " !text-primary   !bg-[#705EE6]/10 !font-medium")
                                                }
                                              >
                                                {isOpen && (
                                                  <HiOutlineSpeakerphone className="text-inherit shrink-0 text-lg" />
                                                )}
                                                <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                  WhatsApp{" "}
                                                  {FavUnFavReturn(
                                                    "WhatsApp Template"
                                                  )}
                                                  {PlanFeatureAvailable(
                                                    "whatsapp",
                                                    "whatsapp"
                                                  )}
                                                </span>
                                              </NavLink>
                                            </li>
                                          ) : null}

                                          <li id={GmailTempURL.list}>
                                            <NavLink
                                              to={GmailTempURL.list}
                                              className={({ isActive }) =>
                                                "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-primary   hover:bg-primary/10 !no-underline transition-all " +
                                                ((isActive ||
                                                  location.pathname.includes(
                                                    "gmail"
                                                  )) &&
                                                  !location.pathname.includes(
                                                    "@gmail"
                                                  ) &&
                                                  " !text-primary   !bg-[#705EE6]/10 !font-medium")
                                              }
                                            >
                                              {isOpen && (
                                                <HiOutlineSpeakerphone className="text-inherit shrink-0 text-lg" />
                                              )}
                                              <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                Email
                                                {FavUnFavReturn(
                                                  "Email Template"
                                                )}
                                              </span>
                                            </NavLink>
                                          </li>
                                        </ul>
                                      </Disclosure.Panel>
                                    </Transition>
                                  </>
                                )}
                              </Disclosure>

                              {urlAllow("readwidget-settings") &&
                                ((PlanFeatureAvailable("widget-settings") &&
                                  !expandAll) ||
                                  expandAll) ? (
                                <>
                                  <li
                                    id="/omni-channel-setting/widget"
                                    className={
                                      PlanFeatureAvailable("widget-settings")
                                        ? ""
                                        : "pointer-events-none"
                                    }
                                  >
                                    <NavLink
                                      to="/omni-channel-setting/widget"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-primary   hover:bg-primary/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-primary   !bg-[#705EE6]/10 !font-medium")
                                      }
                                    >
                                      {isOpen && (
                                        <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Widget Settings
                                        {FavUnFavReturn("Chat Widget")}
                                        {PlanFeatureAvailable(
                                          "widget-settings",
                                          "widget-settings"
                                        )}
                                      </span>
                                    </NavLink>
                                  </li>
                                </>
                              ) : null}
                            </ul>
                          </Disclosure.Panel>
                        </Transition>
                      </>
                    )}
                  </Disclosure>
                </li>
                {/* {urlAllow("readReviews") && ( */}
                {(ParentModuleSubscriptionFinder(PlanModules["Reviews"]) &&
                  !expandAll) ||
                  expandAll ? (
                  <li
                    className={
                      ParentModuleSubscriptionFinder(PlanModules["Reviews"])
                        ? ""
                        : "pointer-events-none"
                    }
                  >
                    {" "}
                    <Disclosure as="div" defaultOpen={tabclass("reviews")}>
                      {({ open }) => (
                        <>
                          {ParentModulePermission(PlanModules["Reviews"]) && (
                            <Disclosure.Button
                              className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#705EE6] hover:bg-[#705EE6]/10 transition-all"
                              onClick={(e) => {
                                setCollapsedState(true);
                                dispatch(sidebar_base({ value: true }));
                                // setTimeout(() => {
                                //   scrollToId("/reviews");
                                // }, 100);
                                var element = document.getElementById("Godiv");
                                element.scrollBy({
                                  top: -50, // Scroll up by 50 pixels
                                  behavior: "smooth", // Optional: Smooth scroll
                                });
                              }}
                            >
                              <span
                                className="text-sm font-normal text-inherit flex items-center gap-[12px] justify-center"
                                id="Godiv"
                              >
                                <MdOutlineReviews className="text-[#ffc800] shrink-0 text-lg" />
                                {/* <FiThumbsUp className="text-[#705EE6] shrink-0 text-lg" /> */}
                                <span className="sidebar_text">
                                  Reviews Management
                                </span>
                                {ParentModuleSubscriptionFinder(
                                  PlanModules["Reviews"]
                                ) ? null : (
                                  <MdLockOutline />
                                )}
                              </span>
                              <TiArrowSortedDown
                                className={`${open ? "" : "-rotate-90 transform"
                                  } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                              />
                            </Disclosure.Button>
                          )}
                          <Transition
                            enter="transition duration-300 ease-in-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-300 opacity-300"
                            leave="transition duration-300 ease-in-out"
                            leaveFrom="transform scale-300 opacity-300"
                            leaveTo="transform scale-95 opacity-0"
                          >
                            <Disclosure.Panel
                              className={`${!isOpen && "pl-[25px]"} mt-[4px] `}
                            >
                              <ul className="space-y-2 !list-none">
                                {urlAllow("readreview-insights") &&
                                  ((PlanFeatureAvailable("review-insights") &&
                                    !expandAll) ||
                                    expandAll) ? (
                                  <li
                                    id="/review-dashboard"
                                    className={
                                      PlanFeatureAvailable("review-insights")
                                        ? ""
                                        : "pointer-events-none"
                                    }
                                  >
                                    <NavLink
                                      to="/review-dashboard"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#705EE6] hover:bg-[#705EE6]/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-[#705EE6] !bg-[#705EE6]/10 !font-medium")
                                      }
                                    >
                                      {isOpen && (
                                        <FiThumbsUp className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Review Insights{" "}
                                        {FavUnFavReturn("Review Insights")}
                                        {PlanFeatureAvailable(
                                          "review-insights",
                                          "review-insights"
                                        )}
                                      </span>
                                    </NavLink>
                                  </li>
                                ) : null}
                                {urlAllow("readreview-management") &&
                                  ((PlanFeatureAvailable("review-management") &&
                                    !expandAll) ||
                                    expandAll) ? (
                                  <li
                                    id="/reviews"
                                    className={
                                      PlanFeatureAvailable("review-management")
                                        ? ""
                                        : "pointer-events-none"
                                    }
                                  >
                                    <NavLink
                                      to="/reviews"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#705EE6] hover:bg-[#705EE6]/10 !no-underline transition-all " +
                                        ((location.pathname == "/reviews" ||
                                          location?.pathname?.includes(
                                            "/review/"
                                          )) &&
                                          " !text-[#705EE6] !bg-[#705EE6]/10 !font-medium")
                                      }
                                    >
                                      {isOpen && (
                                        <FiThumbsUp className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Review Management{" "}
                                        {FavUnFavReturn("Review Management")}
                                        {PlanFeatureAvailable(
                                          "review-management",
                                          "review-management"
                                        )}
                                      </span>
                                    </NavLink>
                                  </li>
                                ) : null}

                                {urlAllow("readreviews-settings") &&
                                  ((PlanFeatureAvailable("reviews-settings") &&
                                    !expandAll) ||
                                    expandAll) ? (
                                  <li
                                    className={
                                      PlanFeatureAvailable("reviews-settings")
                                        ? ""
                                        : "pointer-events-none"
                                    }
                                  >
                                    <NavLink
                                      to="/reviews/settings"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#705EE6] hover:bg-[#705EE6]/10 !no-underline transition-all " +
                                        (location.pathname ==
                                          "/reviews/settings" &&
                                          " !text-[#705EE6] !bg-[#705EE6]/10 !font-medium")
                                      }
                                    >
                                      {isOpen && (
                                        <FiThumbsUp className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Reviews Settings{" "}
                                        {FavUnFavReturn("Review Settings")}
                                        {PlanFeatureAvailable(
                                          "reviews-settings",
                                          "reviews-settings"
                                        )}
                                      </span>
                                    </NavLink>
                                  </li>
                                ) : null}
                              </ul>
                            </Disclosure.Panel>
                          </Transition>
                        </>
                      )}
                    </Disclosure>
                  </li>
                ) : null}
                {/* )} */}

                {/*  TOUR GUIDES */}
                {methodModel.CurrentServer() && false && (
                  <li
                    className={
                      ParentModuleSubscriptionFinder(PlanModules["Reviews"])
                        ? ""
                        : "pointer-events-none"
                    }
                  >
                    {" "}
                    <Disclosure as="div" defaultOpen={tabclass("tourGuides")}>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#705EE6] hover:bg-[#705EE6]/10 transition-all"
                            onClick={(e) => {
                              setCollapsedState(true);
                              dispatch(sidebar_base({ value: true }));
                              var element = document.getElementById("Godiv");
                              element.scrollBy({
                                top: -50,
                                behavior: "smooth",
                              });
                            }}
                          >
                            <span
                              className="text-sm font-normal text-inherit flex items-center gap-[12px]"
                              id="Godiv"
                            >
                              <span className="sidebar_text">Tour Guides</span>
                              {/* {ParentModuleSubscriptionFinder(PlanModules['Reviews']) ? null : <MdLockOutline />} */}
                            </span>
                            <TiArrowSortedDown
                              className={`${open ? "" : "-rotate-90 transform"
                                } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                            />
                          </Disclosure.Button>
                          <Transition
                            enter="transition duration-300 ease-in-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-300 opacity-300"
                            leave="transition duration-300 ease-in-out"
                            leaveFrom="transform scale-300 opacity-300"
                            leaveTo="transform scale-95 opacity-0"
                          >
                            <Disclosure.Panel
                              className={`${!isOpen && "pl-[25px]"} mt-[4px] `}
                            >
                              <ul className="space-y-2 !list-none">
                                <li id="/tour/guide-dashboard">
                                  <NavLink
                                    to="/tour/guide-dashboard"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#705EE6] hover:bg-[#705EE6]/10 !no-underline transition-all " +
                                      (isActive &&
                                        " !text-[#705EE6] !bg-[#705EE6]/10 !font-medium")
                                    }
                                  >
                                    {isOpen && (
                                      <FiThumbsUp className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Tour Guide Insights
                                    </span>
                                  </NavLink>
                                </li>
                              </ul>
                            </Disclosure.Panel>
                          </Transition>
                        </>
                      )}
                    </Disclosure>
                  </li>
                )}

                {/* {urlAllow("readSales") && ( */}
                {(ParentModuleSubscriptionFinder(PlanModules["Sales"]) &&
                  !expandAll) ||
                  expandAll ? (
                  <li
                    className={
                      ParentModuleSubscriptionFinder(PlanModules["Sales"])
                        ? ""
                        : "pointer-events-none"
                    }
                  >
                    {" "}
                    <Disclosure as="div" defaultOpen={tabclass("sales")}>
                      {({ open }) => (
                        <>
                          {ParentModulePermission(PlanModules["Sales"]) && (
                            <Disclosure.Button
                              onClick={(e) => {
                                setCollapsedState(true);
                                dispatch(sidebar_base({ value: true }));
                              }}
                              className="w-full p-2.5 rounded-md flex items-center justify-between text-[#4A545E]  hover:!text-[#1b88c0] gap-[12px] hover:bg-[#1b88c0]/10 transition-all duration-300"
                            >
                              <span className="text-sm font-normal text-inherit flex items-center gap-[12px] crm">
                                <TbChartBar className="text-[#1b88c0] shrink-0 text-lg" />
                                <span className="sidebar_text">Sales</span>{" "}
                                {ParentModuleSubscriptionFinder(
                                  PlanModules["Sales"]
                                ) ? null : (
                                  <MdLockOutline />
                                )}
                              </span>
                              <TiArrowSortedDown
                                className={`${open ? "" : "-rotate-90 transform"
                                  } h-4 w-4 transition-all duration-500  text-[#7E8B99]`}
                              />
                            </Disclosure.Button>
                          )}
                          <Transition
                            enter="transition duration-300 ease-in-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-300 opacity-300"
                            leave="transition duration-300 ease-in-out"
                            leaveFrom="transform scale-300 opacity-300"
                            leaveTo="transform scale-95 opacity-0"
                          >
                            <Disclosure.Panel
                              className={`${!isOpen && "pl-[25px]"} mt-[4px] `}
                            >
                              <ul className="space-y-2 !list-none">
                                {urlAllow("readsales-insights") &&
                                  ((PlanFeatureAvailable("sales-insights") &&
                                    !expandAll) ||
                                    expandAll) ? (
                                  <li
                                    className={
                                      PlanFeatureAvailable("sales-insights")
                                        ? ""
                                        : "pointer-events-none"
                                    }
                                  >
                                    <NavLink
                                      to="/sales"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#1b88c0] hover:bg-[#1b88c0]/10 !no-underline transition-all " +
                                        (location?.pathname == "/sales" &&
                                          " !text-[#1b88c0] !bg-[#1b88c0]/10 !font-medium")
                                      }
                                    >
                                      {isOpen && (
                                        <TbChartBar className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Sales Insights{" "}
                                        {FavUnFavReturn("Sales Insight")}
                                        {PlanFeatureAvailable(
                                          "sales-insights",
                                          "sales-insights"
                                        )}
                                      </span>
                                    </NavLink>
                                  </li>
                                ) : null}
                                {urlAllow("readsales-data") &&
                                  ((PlanFeatureAvailable("sales-data") &&
                                    !expandAll) ||
                                    expandAll) ? (
                                  <li
                                    className={
                                      PlanFeatureAvailable("sales-data")
                                        ? ""
                                        : "pointer-events-none"
                                    }
                                  >
                                    <NavLink
                                      to="/sales-data"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#1b88c0] hover:bg-[#1b88c0]/10 !no-underline transition-all " +
                                        ((location?.pathname == "/sales-data" ||
                                          location.pathname.includes(
                                            "orders"
                                          )) &&
                                          " !text-[#1b88c0] !bg-[#1b88c0]/10 !font-medium")
                                      }
                                    >
                                      {isOpen && (
                                        <TbChartBar className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Sales Data{" "}
                                        {FavUnFavReturn("Sales Data")}
                                        {PlanFeatureAvailable(
                                          "sales-data",
                                          "sales-data"
                                        )}
                                      </span>
                                    </NavLink>
                                  </li>
                                ) : null}
                                {urlAllow("readsales-data") &&
                                  ((PlanFeatureAvailable("sales-data") &&
                                    !expandAll) ||
                                    expandAll) ? (
                                  <li
                                    className={
                                      PlanFeatureAvailable("sales-data")
                                        ? ""
                                        : "pointer-events-none"
                                    }
                                  >
                                    <NavLink
                                      to="/sales-data/promo"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#1b88c0] hover:bg-[#1b88c0]/10 !no-underline transition-all " +
                                        (location?.pathname ==
                                          "/sales-data/promo" &&
                                          " !text-[#1b88c0] !bg-[#1b88c0]/10 !font-medium")
                                      }
                                    >
                                      {isOpen && (
                                        <TbChartBar className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Sales Promo Code Data{" "}
                                        {FavUnFavReturn("Sales Data")}
                                        {PlanFeatureAvailable(
                                          "sales-data",
                                          "sales-data"
                                        )}
                                      </span>
                                    </NavLink>
                                  </li>
                                ) : null}
                                {(PlanFeatureAvailable("email-orders") &&
                                  !expandAll) ||
                                  expandAll ? (
                                  <li
                                    id="/omni-channels/live-chat"
                                    className={
                                      PlanFeatureAvailable("email-orders")
                                        ? ""
                                        : "pointer-events-none"
                                    }
                                  >
                                    <NavLink
                                      to="/email/bookings"
                                      id="3"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-primary   hover:bg-primary/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-primary   !bg-[#705EE6]/10 !font-medium")
                                      }
                                    >
                                      {isOpen && (
                                        <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Email Orders{" "}
                                        {PlanFeatureAvailable(
                                          "email-orders",
                                          "email-orders"
                                        )}
                                      </span>
                                    </NavLink>
                                  </li>
                                ) : null}
                                {/* {urlAllow("readcustomer-insights") && (
                                <li

                                >
                                  {" "} */}
                                {/* <Disclosure as="div" defaultOpen={tabclass("customers")}>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            onClick={(e) => {
                              setCollapsedState(true);
                              dispatch(sidebar_base({ value: true }));
                            }}
                            className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#705EE6] hover:bg-[#705EE6]/10 transition-all">
                            <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                              <FiUsers className="text-[#705EE6] shrink-0 text-lg" />
                              <span className="sidebar_text">Customers</span>
                            </span>
                            <TiArrowSortedDown
                              className={`${
                                open ? "" : "-rotate-90 transform"
                              } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                            />
                          </Disclosure.Button>
                          <Transition
                            enter="transition duration-300 ease-in-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-300 opacity-300"
                            leave="transition duration-300 ease-in-out"
                            leaveFrom="transform scale-300 opacity-300"
                            leaveTo="transform scale-95 opacity-0">
                            <Disclosure.Panel
                              className={`${!isOpen && "pl-[25px]"} mt-[4px] `}> */}
                                {/* <ul className="space-y-2 !list-none"> */}
                                {urlAllow("readcustomer-insights") &&
                                  ((PlanFeatureAvailable("customer-insights") &&
                                    !expandAll) ||
                                    expandAll) ? (
                                  <li
                                    className={
                                      PlanFeatureAvailable("customer-insights")
                                        ? ""
                                        : "pointer-events-none"
                                    }
                                  >
                                    <NavLink
                                      to="/customers"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#705EE6] hover:bg-[#705EE6]/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-[#705EE6] !bg-[#705EE6]/10 !font-medium")
                                      }
                                    >
                                      {isOpen && (
                                        <FiUsers className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Customer Insights{" "}
                                        {FavUnFavReturn("Customer Insights")}{" "}
                                        {PlanFeatureAvailable(
                                          "customer-insights",
                                          "customer-insights"
                                        )}
                                      </span>
                                    </NavLink>
                                  </li>
                                ) : null}
                                {urlAllow("readcustomer-data") &&
                                  ((PlanFeatureAvailable("customer-data") &&
                                    !expandAll) ||
                                    expandAll) ? (
                                  <li
                                    className={
                                      PlanFeatureAvailable("customer-data")
                                        ? ""
                                        : "pointer-events-none"
                                    }
                                  >
                                    <NavLink
                                      to="/customer-data"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#705EE6] hover:bg-[#705EE6]/10 !no-underline transition-all " +
                                        ((isActive ||
                                          location.pathname.includes(
                                            `customerdetails`
                                          )) &&
                                          " !text-[#705EE6] !bg-[#705EE6]/10 !font-medium")
                                      }
                                    >
                                      {isOpen && (
                                        <FiUsers className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Customer Data{" "}
                                        {FavUnFavReturn("Customer Data")}
                                        {PlanFeatureAvailable(
                                          "customer-data",
                                          "customer-data"
                                        )}
                                      </span>
                                    </NavLink>
                                  </li>
                                ) : null}
                                {/* </ul> */}
                                {/* </Disclosure.Panel> */}
                                {/* </Transition> */}
                                {/* </> */}
                                {/* )} */}
                                {/* </Disclosure> */}
                                {/* </li> */}
                                {/* )} */}

                                {RuleData?.retrievable_order_count == 0 ||
                                  urlAllow(
                                    "readhistorical-data"
                                  ) ? null : (PlanFeatureAvailable(
                                    "historical-data"
                                  ) &&
                                    !expandAll) ||
                                    expandAll ? (
                                  <li
                                    className={
                                      PlanFeatureAvailable("historical-data")
                                        ? ""
                                        : "pointer-events-none"
                                    }
                                  >
                                    <NavLink
                                      to="/sales/retrievable"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#1b88c0] hover:bg-[#1b88c0]/10 !no-underline transition-all " +
                                        (location?.pathname ==
                                          "/sales/retrievable" &&
                                          " !text-[#1b88c0] !bg-[#1b88c0]/10 !font-medium")
                                      }
                                    >
                                      {isOpen && (
                                        <TbChartBar className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Historical Data{" "}
                                        {FavUnFavReturn("Historial Sales")}{" "}
                                        {PlanFeatureAvailable(
                                          "sales"
                                        ) ? null : (
                                          <MdLockOutline />
                                        )}
                                      </span>
                                    </NavLink>
                                  </li>
                                ) : null}
                                {urlAllow("readrefundcancel-data") &&
                                  ((PlanFeatureAvailable("refundcancel-data") &&
                                    !expandAll) ||
                                    expandAll) ? (
                                  <li
                                    className={
                                      PlanFeatureAvailable("refundcancel-data")
                                        ? ""
                                        : "pointer-events-none"
                                    }
                                  >
                                    <NavLink
                                      to="/sales/refunds"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#1b88c0] hover:bg-[#1b88c0]/10 !no-underline transition-all " +
                                        (location?.pathname ==
                                          "/sales/refunds" &&
                                          " !text-[#1b88c0] !bg-[#1b88c0]/10 !font-medium")
                                      }
                                    >
                                      {isOpen && (
                                        <TbChartBar className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Refund/Cancel Data{" "}
                                        {FavUnFavReturn("Sales Refund Data")}
                                        {PlanFeatureAvailable(
                                          "refundcancel-data",
                                          "refundcancel-data"
                                        )}
                                      </span>
                                    </NavLink>
                                  </li>
                                ) : null}
                                {(ParentModuleSubscriptionFinder(
                                  PlanModules["Waivers"]
                                ) &&
                                  !expandAll) ||
                                  expandAll ? (
                                  <li
                                    className={
                                      ParentModuleSubscriptionFinder(
                                        PlanModules["Waivers"]
                                      )
                                        ? ""
                                        : "pointer-events-none"
                                    }
                                  >
                                    {" "}
                                    <Disclosure
                                      as="div"
                                      defaultOpen={tabclass("waivers")}
                                    >
                                      {({ open }) => (
                                        <>
                                          {ParentModulePermission(
                                            PlanModules["Waivers"]
                                          ) && (
                                              <Disclosure.Button
                                                onClick={(e) => {
                                                  setCollapsedState(true);
                                                  dispatch(
                                                    sidebar_base({ value: true })
                                                  );
                                                }}
                                                className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#705EE6] hover:bg-[#705EE6]/10 transition-all"
                                              >
                                                <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                                                  {/* <FiUsers className="text-[#705EE6] shrink-0 text-lg" /> */}
                                                  <span className="sidebar_text">
                                                    {methodModel.WaiverNameChanger(
                                                      user,
                                                      "s"
                                                    )}
                                                  </span>
                                                  {ParentModuleSubscriptionFinder(
                                                    PlanModules["Waivers"]
                                                  ) ? null : (
                                                    <MdLockOutline />
                                                  )}
                                                </span>
                                                <TiArrowSortedDown
                                                  className={`${open
                                                    ? ""
                                                    : "-rotate-90 transform"
                                                    } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                                                />
                                              </Disclosure.Button>
                                            )}
                                          <Transition
                                            enter="transition duration-300 ease-in-out"
                                            enterFrom="transform scale-95 opacity-0"
                                            enterTo="transform scale-300 opacity-300"
                                            leave="transition duration-300 ease-in-out"
                                            leaveFrom="transform scale-300 opacity-300"
                                            leaveTo="transform scale-95 opacity-0"
                                          >
                                            <Disclosure.Panel
                                              className={`${!isOpen && "pl-[25px]"
                                                } mt-[4px] `}
                                            >
                                              <ul className="space-y-2 !list-none">
                                                {urlAllow(
                                                  "readwaiver-templates"
                                                ) &&
                                                  ((PlanFeatureAvailable(
                                                    "waiver-templates"
                                                  ) &&
                                                    !expandAll) ||
                                                    expandAll) ? (
                                                  <li
                                                    className={
                                                      PlanFeatureAvailable(
                                                        "waiver-templates"
                                                      )
                                                        ? ""
                                                        : "pointer-events-none"
                                                    }
                                                  >
                                                    <NavLink
                                                      to="/waiver/list"
                                                      className={({
                                                        isActive,
                                                      }) =>
                                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#705EE6] hover:bg-[#705EE6]/10 !no-underline transition-all " +
                                                        ((isActive ||
                                                          location.pathname.includes(
                                                            "waiver"
                                                          )) &&
                                                          " !text-[#705EE6] !bg-[#705EE6]/10 !font-medium")
                                                      }
                                                    >
                                                      {isOpen && (
                                                        <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                                      )}
                                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                        {methodModel.WaiverNameChanger(
                                                          user
                                                        )}{" "}
                                                        Templates{" "}
                                                        {FavUnFavReturn(
                                                          "Waiver Templates"
                                                        )}
                                                        {PlanFeatureAvailable(
                                                          "waiver-templates",
                                                          "waiver-templates"
                                                        )}
                                                      </span>
                                                    </NavLink>
                                                  </li>
                                                ) : null}
                                                {urlAllow(
                                                  "readwaiver-settings"
                                                ) &&
                                                  ((PlanFeatureAvailable(
                                                    "waiver-settings"
                                                  ) &&
                                                    !expandAll) ||
                                                    expandAll) ? (
                                                  <li
                                                    className={
                                                      PlanFeatureAvailable(
                                                        "waiver-settings"
                                                      )
                                                        ? ""
                                                        : "pointer-events-none"
                                                    }
                                                  >
                                                    <NavLink
                                                      to="/wavier/settings"
                                                      className={({
                                                        isActive,
                                                      }) =>
                                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#705EE6] hover:bg-[#705EE6]/10 !no-underline transition-all " +
                                                        (isActive &&
                                                          " !text-[#705EE6] !bg-[#705EE6]/10 !font-medium")
                                                      }
                                                    >
                                                      {isOpen && (
                                                        <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                                      )}
                                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                        {methodModel.WaiverNameChanger(
                                                          user
                                                        )}{" "}
                                                        Settings{" "}
                                                        {FavUnFavReturn(
                                                          "Waiver Settings"
                                                        )}
                                                        {PlanFeatureAvailable(
                                                          "waiver-settings",
                                                          "waiver-settings"
                                                        )}
                                                      </span>
                                                    </NavLink>
                                                  </li>
                                                ) : null}
                                                {urlAllow(
                                                  "readwaiver-database"
                                                ) &&
                                                  ((PlanFeatureAvailable(
                                                    "waiver-database"
                                                  ) &&
                                                    !expandAll) ||
                                                    expandAll) ? (
                                                  <li
                                                    className={
                                                      PlanFeatureAvailable(
                                                        "waiver-database"
                                                      )
                                                        ? ""
                                                        : "pointer-events-none"
                                                    }
                                                  >
                                                    <NavLink
                                                      to="/waidatabase"
                                                      className={({
                                                        isActive,
                                                      }) =>
                                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#705EE6] hover:bg-[#705EE6]/10 !no-underline transition-all " +
                                                        (isActive &&
                                                          " !text-[#705EE6] !bg-[#705EE6]/10 !font-medium")
                                                      }
                                                    >
                                                      {isOpen && (
                                                        <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                                      )}
                                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                        {methodModel.WaiverNameChanger(
                                                          user
                                                        )}{" "}
                                                        Database{" "}
                                                        {FavUnFavReturn(
                                                          "Waiver Database"
                                                        )}
                                                        {PlanFeatureAvailable(
                                                          "waiver-database",
                                                          "waiver-database"
                                                        )}
                                                      </span>
                                                    </NavLink>
                                                  </li>
                                                ) : null}
                                              </ul>
                                            </Disclosure.Panel>
                                          </Transition>
                                        </>
                                      )}
                                    </Disclosure>
                                  </li>
                                ) : null}
                              </ul>
                            </Disclosure.Panel>
                          </Transition>
                        </>
                      )}
                    </Disclosure>
                  </li>
                ) : null}

                {/* // )} */}
                {/* {urlAllow("readprice-management") && (
                  <li
                    className={PlanFeatureAvailable("price-management")
                      ? ""
                      : "pointer-events-none"}
                  >
                    {" "}

                    <li>
                      <NavLink
                        to="/dynamicprice-list"
                        className={({ isActive }) =>
                          "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#1b88c0] hover:bg-[#1b88c0]/10 !no-underline transition-all " +
                          ((isActive ||
                            location.pathname.includes(
                              "dynamicprice"
                            )) &&
                            " !text-[#1b88c0] !bg-[#1b88c0]/10 !font-medium")
                        }>
                        {isOpen && (
                          <PiHandCoins className="text-inherit shrink-0 text-lg" />
                        )}
                        <span className="text-inherit sidebar_text leading-none flex items-center   w-full">
                          <TbChartBar className="text-[#1b88c0] shrink-0 text-lg" />
                          Price Management {FavUnFavReturn('Manage Rules')}{PlanFeatureAvailable('price-management', 'price-management')}
                        </span>
                      </NavLink>
                    </li>

                  </li>
                )} */}

                {/* {urlAllow("readProducts") && ( */}
                {(ParentModuleSubscriptionFinder(PlanModules["Products"]) &&
                  !expandAll) ||
                  expandAll ? (
                  <li
                    className={
                      ParentModuleSubscriptionFinder(PlanModules["Products"])
                        ? ""
                        : "pointer-events-none"
                    }
                  >
                    {" "}
                    <Disclosure
                      as="div"
                      defaultOpen={tabclass("productdashboard")}
                    >
                      {({ open }) => (
                        <>
                          {ParentModulePermission(PlanModules["Products"]) && (
                            <Disclosure.Button
                              onClick={(e) => {
                                setCollapsedState(true);
                                dispatch(sidebar_base({ value: true }));
                              }}
                              className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#4529ff] hover:bg-[#4529ff]/10 transition-all"
                            >
                              <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                                <FiPackage className="text-[#4529ff] shrink-0 text-lg" />
                                <span className="sidebar_text">Products</span>
                                {ParentModuleSubscriptionFinder(
                                  PlanModules["Products"]
                                ) ? null : (
                                  <MdLockOutline />
                                )}
                              </span>
                              <TiArrowSortedDown
                                className={`${open ? "" : "-rotate-90 transform"
                                  } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                              />
                            </Disclosure.Button>
                          )}
                          <Transition
                            enter="transition duration-300 ease-in-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-300 opacity-300"
                            leave="transition duration-300 ease-in-out"
                            leaveFrom="transform scale-300 opacity-300"
                            leaveTo="transform scale-95 opacity-0"
                          >
                            <Disclosure.Panel
                              className={`${!isOpen && "pl-[25px]"} mt-[4px] `}
                            >
                              <ul className="space-y-2 !list-none">
                                {urlAllow("readproduct-insights") &&
                                  ((PlanFeatureAvailable("product-insights") &&
                                    !expandAll) ||
                                    expandAll) ? (
                                  <li
                                    className={
                                      PlanFeatureAvailable("product-insights")
                                        ? ""
                                        : "pointer-events-none"
                                    }
                                  >
                                    <NavLink
                                      to="/product-dashboard"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#4529ff] hover:bg-[#4529ff]/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-[#4529ff] !bg-[#4529ff]/10 !font-medium")
                                      }
                                    >
                                      {isOpen && (
                                        <FiPackage className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Product Insights{" "}
                                        {FavUnFavReturn("Product Insights")}
                                        {PlanFeatureAvailable(
                                          "product-insights",
                                          "product-insights"
                                        )}
                                      </span>
                                    </NavLink>
                                  </li>
                                ) : null}

                                {urlAllow("readproduct-data") &&
                                  ((PlanFeatureAvailable("product-data") &&
                                    !expandAll) ||
                                    expandAll) ? (
                                  <li
                                    className={
                                      PlanFeatureAvailable("product-data")
                                        ? ""
                                        : "pointer-events-none"
                                    }
                                  >
                                    <NavLink
                                      to="/product-data"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#4529ff] hover:bg-[#4529ff]/10 !no-underline transition-all " +
                                        ((isActive ||
                                          location.pathname.includes(
                                            `/product/`
                                          )) &&
                                          !location.pathname.includes(
                                            "/delete/product"
                                          ) &&
                                          " !text-[#4529ff] !bg-[#4529ff]/10 !font-medium")
                                      }
                                    >
                                      {isOpen && (
                                        <FiPackage className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Product Data
                                        {FavUnFavReturn("Product Data")}
                                        {PlanFeatureAvailable(
                                          "product-data",
                                          "product-data"
                                        )}
                                      </span>
                                    </NavLink>
                                  </li>
                                ) : null}
                                {RuleData?.discontinued_products_count == 0 ||
                                  !urlAllow(
                                    "readdeleted-products"
                                  ) ? null : (PlanFeatureAvailable(
                                    "deleted-products"
                                  ) &&
                                    !expandAll) ||
                                    expandAll ? (
                                  <li
                                    className={
                                      PlanFeatureAvailable("deleted-products")
                                        ? ""
                                        : "pointer-events-none"
                                    }
                                  >
                                    <NavLink
                                      to="/delete/product-data/"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#7b68ee] hover:bg-[#7b68ee]/10 !no-underline transition-all " +
                                        ((isActive ||
                                          window.location.pathname.includes(
                                            "/delete/product/"
                                          )) &&
                                          " !text-[#7b68ee] !bg-[#7b68ee]/10 !font-medium")
                                      }
                                    >
                                      {isOpen && (
                                        <FiPackage className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Deleted Products{" "}
                                        {FavUnFavReturn("Deleted Products")}
                                        {PlanFeatureAvailable(
                                          "deleted-products",
                                          "deleted-products"
                                        )}
                                      </span>
                                    </NavLink>
                                  </li>
                                ) : null}

                                {/*  FOr Product Retrivable DATA */}
                                {RuleData?.retrievable_product_count == 0 ||
                                  !urlAllow(
                                    "readhistorical-data"
                                  ) ? null : (PlanFeatureAvailable(
                                    "historical-data"
                                  ) &&
                                    !expandAll) ||
                                    expandAll ? (
                                  <li
                                    className={
                                      PlanFeatureAvailable("historical-data")
                                        ? ""
                                        : "pointer-events-none"
                                    }
                                  >
                                    <NavLink
                                      to="/products/retrivable"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#4529ff] hover:bg-[#4529ff]/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-[#4529ff] !bg-[#4529ff]/10 !font-medium")
                                      }
                                    >
                                      {isOpen && (
                                        <FiPackage className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Historical Data{" "}
                                        {FavUnFavReturn("Historical Product")}
                                        {PlanFeatureAvailable(
                                          "historical-data"
                                        )}
                                      </span>
                                    </NavLink>
                                  </li>
                                ) : null}

                                {urlAllow("readcancellation-policies") &&
                                  ((PlanFeatureAvailable(
                                    "cancellation-policies"
                                  ) &&
                                    !expandAll) ||
                                    expandAll) ? (
                                  <li
                                    className={
                                      PlanFeatureAvailable(
                                        "cancellation-policies"
                                      )
                                        ? ""
                                        : "pointer-events-none"
                                    }
                                  >
                                    <NavLink
                                      to="/cancel/policy"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#4529ff] hover:bg-[#4529ff]/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-[#4529ff] !bg-[#4529ff]/10 !font-medium")
                                      }
                                    >
                                      {isOpen && (
                                        <HiOutlineInbox className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Cancellation Policies{" "}
                                        {FavUnFavReturn(
                                          "Cancellation Policies"
                                        )}
                                        {PlanFeatureAvailable(
                                          "cancellation-policies",
                                          "cancellation-policies"
                                        )}
                                      </span>
                                    </NavLink>
                                  </li>
                                ) : null}
                                {ParentModuleSubscriptionFinder(
                                  PlanModules["ContentUpdates"]
                                ) && (
                                    <li className="">
                                      <Disclosure
                                        as="div"
                                        defaultOpen={"contents"}
                                      >
                                        {({ open }) => (
                                          <>
                                            <Disclosure.Button
                                              onClick={(e) => {
                                                setCollapsedState(true);
                                                dispatch(
                                                  sidebar_base({ value: true })
                                                );
                                              }}
                                              className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#4529ff] hover:bg-[#4529ff]/10 transition-all"
                                            >
                                              <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                                                {/* <FiCloud className="text-[#fd71af] shrink-0 text-lg" /> */}
                                                <span className="sidebar_text">
                                                  Content Updates
                                                </span>
                                              </span>
                                              <TiArrowSortedDown
                                                className={`${open
                                                  ? ""
                                                  : "-rotate-90 transform"
                                                  } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                                              />
                                            </Disclosure.Button>
                                            <Transition
                                              enter="transition duration-300 ease-in-out"
                                              enterFrom="transform scale-95 opacity-0"
                                              enterTo="transform scale-300 opacity-300"
                                              leave="transition duration-300 ease-in-out"
                                              leaveFrom="transform scale-300 opacity-300"
                                              leaveTo="transform scale-95 opacity-0"
                                            >
                                              <Disclosure.Panel
                                                className={`${!isOpen && "pl-[25px]"
                                                  } mt-[4px] `}
                                              >
                                                <ul className="space-y-2 !list-none">
                                                  {urlAllow("readupdate-logs") &&
                                                    ((PlanFeatureAvailable(
                                                      "update-logs"
                                                    ) &&
                                                      !expandAll) ||
                                                      expandAll) ? (
                                                    <li
                                                      className={
                                                        PlanFeatureAvailable(
                                                          "update-logs"
                                                        )
                                                          ? ""
                                                          : "pointer-events-none"
                                                      }
                                                    >
                                                      <NavLink
                                                        to="/content/updates"
                                                        className={({
                                                          isActive,
                                                        }) =>
                                                          "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#4529ff] hover:bg-[#4529ff]/10 !no-underline transition-all " +
                                                          ((isActive ||
                                                            window.location.pathname.includes(
                                                              "/content/detail"
                                                            )) &&
                                                            " !text-[#4529ff] !bg-[#4529ff]/10 !font-medium")
                                                        }
                                                      >
                                                        {isOpen && (
                                                          <FiUsers className="text-inherit shrink-0 text-lg" />
                                                        )}
                                                        <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                          Update Logs{" "}
                                                          {FavUnFavReturn(
                                                            "Update Logs"
                                                          )}
                                                          {PlanFeatureAvailable(
                                                            "update-logs",
                                                            "update-logs"
                                                          )}
                                                        </span>
                                                      </NavLink>
                                                    </li>
                                                  ) : null}
                                                  {urlAllow("readsettings") &&
                                                    ((PlanFeatureAvailable(
                                                      "settings"
                                                    ) &&
                                                      !expandAll) ||
                                                      expandAll) ? (
                                                    <li
                                                      className={
                                                        PlanFeatureAvailable(
                                                          "settings"
                                                        )
                                                          ? ""
                                                          : "pointer-events-none"
                                                      }
                                                    >
                                                      <NavLink
                                                        to="/content/settings"
                                                        className={({
                                                          isActive,
                                                        }) =>
                                                          "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#4529ff] hover:bg-[#4529ff]/10 !no-underline transition-all " +
                                                          (isActive &&
                                                            " !text-[#4529ff] !bg-[#4529ff]/10 !font-medium")
                                                        }
                                                      >
                                                        {isOpen && (
                                                          <FiUsers className="text-inherit shrink-0 text-lg" />
                                                        )}
                                                        <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                          Settings{" "}
                                                          {FavUnFavReturn(
                                                            "Update Logs Setting"
                                                          )}
                                                          {PlanFeatureAvailable(
                                                            "settings",
                                                            "settings"
                                                          )}
                                                        </span>
                                                      </NavLink>
                                                    </li>
                                                  ) : null}
                                                </ul>
                                              </Disclosure.Panel>
                                            </Transition>
                                          </>
                                        )}
                                      </Disclosure>
                                    </li>
                                  )}
                                <li id="/costing">
                                  <Disclosure
                                    as="div"
                                    defaultOpen={"costingTemp"}
                                  >
                                    {({ open }) => (
                                      <>
                                        <Disclosure.Button
                                          onClick={(e) => {
                                            setCollapsedState(true);
                                            dispatch(
                                              sidebar_base({ value: true })
                                            );
                                          }}
                                          className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#4529ff] hover:bg-[#4529ff]/10 transition-all"
                                        >
                                          <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                                            {/* <FiCloud className="text-[#705EE6] shrink-0 text-lg" /> */}
                                            <span className="sidebar_text">
                                              Costing Templates
                                            </span>
                                          </span>
                                          <TiArrowSortedDown
                                            className={`${open ? "" : "-rotate-90 transform"
                                              } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                                          />
                                        </Disclosure.Button>
                                        <Transition
                                          enter="transition duration-300 ease-in-out"
                                          enterFrom="transform scale-95 opacity-0"
                                          enterTo="transform scale-300 opacity-300"
                                          leave="transition duration-300 ease-in-out"
                                          leaveFrom="transform scale-300 opacity-300"
                                          leaveTo="transform scale-95 opacity-0"
                                        >
                                          <Disclosure.Panel
                                            className={`${!isOpen && "pl-[25px]"
                                              } mt-[4px] `}
                                          >
                                            <ul className="space-y-2 !list-none">
                                              {urlAllow("readbudget") &&
                                                ((PlanFeatureAvailable(
                                                  "budget"
                                                ) &&
                                                  !expandAll) ||
                                                  expandAll) ? (
                                                <li
                                                  className={
                                                    PlanFeatureAvailable(
                                                      "budget"
                                                    )
                                                      ? ""
                                                      : "pointer-events-none"
                                                  }
                                                >
                                                  <NavLink
                                                    to={CostingURL.list}
                                                    className={({ isActive }) =>
                                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#4529ff] hover:bg-[#4529ff]/10 !no-underline transition-all " +
                                                      (location.pathname.includes(
                                                        `/budget/`
                                                      ) ||
                                                        location.pathname ==
                                                        `/budget/template`
                                                        ? " !text-[#4529ff] !bg-[#4529ff]/10 !font-medium"
                                                        : "")
                                                    }
                                                  >
                                                    {isOpen && (
                                                      <FiPackage className="text-inherit shrink-0 text-lg" />
                                                    )}
                                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                      Budget{" "}
                                                      {FavUnFavReturn(
                                                        "Budget Per Tour"
                                                      )}
                                                      {PlanFeatureAvailable(
                                                        "budget",
                                                        "budget"
                                                      )}
                                                    </span>
                                                  </NavLink>
                                                </li>
                                              ) : null}

                                              {urlAllow("readcosting") &&
                                                ((PlanFeatureAvailable(
                                                  "costing"
                                                ) &&
                                                  !expandAll) ||
                                                  expandAll) ? (
                                                <li
                                                  className={
                                                    PlanFeatureAvailable(
                                                      "costing"
                                                    )
                                                      ? ""
                                                      : "pointer-events-none"
                                                  }
                                                >
                                                  <NavLink
                                                    to={CostingTourURL.list}
                                                    className={({ isActive }) =>
                                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#4529ff] hover:bg-[#4529ff]/10 !no-underline transition-all " +
                                                      ((isActive ||
                                                        location.pathname.includes(
                                                          "costing/"
                                                        )) &&
                                                        " !text-[#4529ff] !bg-[#4529ff]/10 !font-medium")
                                                    }
                                                  >
                                                    {isOpen && (
                                                      <HiOutlineInbox className="text-inherit shrink-0 text-lg" />
                                                    )}
                                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                      Costing{" "}
                                                      {FavUnFavReturn(
                                                        "Costing Per Tour"
                                                      )}
                                                      {PlanFeatureAvailable(
                                                        "costing",
                                                        "costing"
                                                      )}
                                                    </span>
                                                  </NavLink>
                                                </li>
                                              ) : null}
                                            </ul>
                                          </Disclosure.Panel>
                                        </Transition>
                                      </>
                                    )}
                                  </Disclosure>
                                </li>

                                {/* Sub section of product, Content Updates Settings  */}
                              </ul>
                            </Disclosure.Panel>
                          </Transition>
                        </>
                      )}
                    </Disclosure>
                  </li>
                ) : null}
                {/* )} */}

                {/* {urlAllow("readFinancials") && ( */}
                {(ParentModuleSubscriptionFinder(
                  PlanModules["Profitability"]
                ) &&
                  !expandAll) ||
                  expandAll ? (
                  <li
                    className={
                      ParentModuleSubscriptionFinder(
                        PlanModules["Profitability"]
                      )
                        ? ""
                        : "pointer-events-none"
                    }
                  >
                    {" "}
                    <Disclosure as="div" defaultOpen={tabclass("financial")}>
                      {({ open }) => (
                        <>
                          {ParentModulePermission(
                            PlanModules["Profitability"]
                          ) && (
                              <Disclosure.Button
                                className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#7b68ee] hover:bg-[#7b68ee]/10 transition-all"
                                onClick={(e) => {
                                  setCollapsedState(true);
                                  dispatch(sidebar_base({ value: true }));
                                  setTimeout(() => {
                                    scrollToId("/accounting/bills");
                                  }, 100);
                                }}
                              >
                                <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                                  <PiHandbagBold className="text-[#7b68ee] shrink-0 text-lg" />
                                  <span className="sidebar_text">
                                    Profitability
                                  </span>
                                  {ParentModuleSubscriptionFinder(
                                    PlanModules["Profitability"]
                                  ) ? null : (
                                    <MdLockOutline />
                                  )}
                                </span>
                                <TiArrowSortedDown
                                  className={`${open ? "" : "-rotate-90 transform"
                                    } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                                />
                              </Disclosure.Button>
                            )}
                          <Transition
                            enter="transition duration-500 ease-in-out delay-150"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-300 opacity-300"
                            leave="transition duration-500 ease-in-out delay-150"
                            leaveFrom="transform scale-300 opacity-300"
                            leaveTo="transform scale-95 opacity-0"
                          >
                            <Disclosure.Panel
                              className={`${!isOpen && "pl-[25px]"} mt-[4px] `}
                            >
                              <ul className="space-y-2 !list-none">
                                {urlAllow("readinsights") &&
                                  ((PlanFeatureAvailable("insights") &&
                                    !expandAll) ||
                                    expandAll) ? (
                                  <li
                                    id="/accounting/dashboard"
                                    className={
                                      PlanFeatureAvailable("insights")
                                        ? ""
                                        : "pointer-events-none"
                                    }
                                  >
                                    <NavLink
                                      to="/accounting/dashboard"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#7b68ee] hover:bg-[#7b68ee]/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-[#7b68ee] !bg-[#7b68ee]/10 !font-medium")
                                      }
                                    >
                                      {isOpen && (
                                        <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Insights{" "}
                                        {FavUnFavReturn(
                                          "Profitability Insights"
                                        )}{" "}
                                        {PlanFeatureAvailable(
                                          "insights",
                                          "insights"
                                        )}
                                      </span>
                                    </NavLink>
                                  </li>
                                ) : null}

                                {/*  --- */}
                                {urlAllow("readprofit-analysis-month") &&
                                  ((PlanFeatureAvailable(
                                    "profit-analysis-month"
                                  ) &&
                                    !expandAll) ||
                                    expandAll) ? (
                                  <li
                                    id="/accounting/dashboard"
                                    className={
                                      PlanFeatureAvailable(
                                        "profit-analysis-month"
                                      )
                                        ? ""
                                        : "pointer-events-none"
                                    }
                                  >
                                    <NavLink
                                      to="/accounting/profit/month"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#7b68ee] hover:bg-[#7b68ee]/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-[#7b68ee] !bg-[#7b68ee]/10 !font-medium")
                                      }
                                    >
                                      {isOpen && (
                                        <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Profit Analysis - Month{" "}
                                        {FavUnFavReturn(
                                          "Profit Analysis- Month"
                                        )}{" "}
                                        {PlanFeatureAvailable(
                                          "profit-analysis-month",
                                          "profit-analysis-month"
                                        )}
                                      </span>
                                    </NavLink>
                                  </li>
                                ) : null}
                                {/*  */}
                                {urlAllow("readprofit-analysis-date") &&
                                  ((PlanFeatureAvailable(
                                    "profit-analysis-date"
                                  ) &&
                                    !expandAll) ||
                                    expandAll) ? (
                                  <li
                                    id="/accounting/profit/date"
                                    className={
                                      PlanFeatureAvailable(
                                        "profit-analysis-date"
                                      )
                                        ? ""
                                        : "pointer-events-none"
                                    }
                                  >
                                    <NavLink
                                      to="/accounting/profit/date"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#7b68ee] hover:bg-[#7b68ee]/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-[#7b68ee] !bg-[#7b68ee]/10 !font-medium")
                                      }
                                    >
                                      {isOpen && (
                                        <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Profit Analysis - Date{" "}
                                        {FavUnFavReturn(
                                          "Profit Analysis- Date"
                                        )}{" "}
                                        {PlanFeatureAvailable(
                                          "profit-analysis-date",
                                          "profit-analysis-date"
                                        )}
                                      </span>
                                    </NavLink>
                                  </li>
                                ) : null}
                                {/* {urlAllow("readprofitability-data") &&
                                ((PlanFeatureAvailable("profitability-data") &&
                                  !expandAll) ||
                                  expandAll) ? (
                                  <li
                                    id="/accounting/transactions"
                                    className={
                                      PlanFeatureAvailable("profitability-data")
                                        ? ""
                                        : "pointer-events-none"
                                    }
                                  >
                                    <NavLink
                                      to="/accounting/transactions"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#7b68ee] hover:bg-[#7b68ee]/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-[#7b68ee] !bg-[#7b68ee]/10 !font-medium")
                                      }
                                    >
                                      {isOpen && (
                                        <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Profitability Data
                                        {FavUnFavReturn("Profitability Data")}
                                        {PlanFeatureAvailable(
                                          "profitability-data",
                                          "profitability-data"
                                        )}
                                      </span>
                                    </NavLink>
                                  </li>
                                ) : null} */}
                              </ul>
                            </Disclosure.Panel>
                          </Transition>
                        </>
                      )}
                    </Disclosure>
                  </li>
                ) : null}
                {/* )} */}

                {/* {urlAllow("readReseller") && ( */}
                {(ParentModuleSubscriptionFinder(PlanModules["Reseller"]) &&
                  !expandAll) ||
                  expandAll ? (
                  <li
                    className={
                      ParentModuleSubscriptionFinder(PlanModules["Reseller"])
                        ? ""
                        : "pointer-events-none"
                    }
                  >
                    {" "}
                    <Disclosure as="div" defaultOpen={tabclass("crm")}>
                      {({ open }) => (
                        <>
                          {ParentModulePermission(PlanModules["Reseller"]) && (
                            <Disclosure.Button
                              className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#bb66e1] hover:bg-[#bb66e1]/10 transition-all"
                              onClick={(e) => {
                                setCollapsedState(true);
                                dispatch(sidebar_base({ value: true }));
                                // setTimeout(() => {
                                //   scrollToId("/reseller-data");
                                // }, 100);
                              }}
                            >
                              <span className="text-sm font-normal text-inherit flex items-center gap-[12px] crm">
                                <HiOutlineInbox className="text-[#bb66e1] shrink-0 text-lg" />
                                <span className="sidebar_text">Reseller</span>
                                {ParentModuleSubscriptionFinder(
                                  PlanModules["Reseller"]
                                ) ? null : (
                                  <MdLockOutline />
                                )}
                              </span>
                              <TiArrowSortedDown
                                className={`${open ? "" : "-rotate-90 transform"
                                  } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                              />
                            </Disclosure.Button>
                          )}
                          <Transition
                            enter="transition duration-500 ease-in-out delay-150"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-300 opacity-300"
                            leave="transition duration-500 ease-in-out delay-150"
                            leaveFrom="transform scale-300 opacity-300"
                            leaveTo="transform scale-95 opacity-0"
                          >
                            <Disclosure.Panel
                              className={`${!isOpen && "pl-[25px]"} mt-[4px] `}
                            >
                              <ul className="space-y-2 !list-none">
                                {urlAllow("readresellers-insights") &&
                                  ((PlanFeatureAvailable("resellers-insights") &&
                                    !expandAll) ||
                                    expandAll) ? (
                                  <li
                                    className={
                                      PlanFeatureAvailable("resellers-insights")
                                        ? ""
                                        : "pointer-events-none"
                                    }
                                    id="/reseller-dashboard"
                                  >
                                    <NavLink
                                      to="/reseller-dashboard"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#bb66e1] hover:bg-[#bb66e1]/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-[#bb66e1] !bg-[#bb66e1]/10 !font-medium")
                                      }
                                    >
                                      {isOpen && (
                                        <HiOutlineInbox className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Resellers Insights{" "}
                                        {FavUnFavReturn("Reseller Insights")}
                                        {PlanFeatureAvailable(
                                          "resellers-insights",
                                          "resellers-insights"
                                        )}
                                      </span>
                                    </NavLink>
                                  </li>
                                ) : null}
                                {urlAllow("readlist-of-resellers") &&
                                  ((PlanFeatureAvailable("list-of-resellers") &&
                                    !expandAll) ||
                                    expandAll) ? (
                                  <li
                                    className={
                                      PlanFeatureAvailable("list-of-resellers")
                                        ? ""
                                        : "pointer-events-none"
                                    }
                                    id="/reseller-data"
                                  >
                                    <NavLink
                                      to="/reseller-data"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#bb66e1] hover:bg-[#bb66e1]/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-[#bb66e1] !bg-[#bb66e1]/10 !font-medium")
                                      }
                                    >
                                      {isOpen && (
                                        <HiOutlineInbox className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        List of Resellers{" "}
                                        {FavUnFavReturn("List of Resellers")}
                                        {PlanFeatureAvailable(
                                          "list-of-resellers",
                                          "list-of-resellers"
                                        )}
                                      </span>
                                    </NavLink>
                                  </li>
                                ) : null}
                                {/* <li id="/invoice-commission">
                                  <NavLink
                                    to="/invoice-commission"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#bb66e1] hover:bg-[#bb66e1]/10 !no-underline transition-all " +
                                      (isActive &&
                                        " !text-[#bb66e1] !bg-[#bb66e1]/10 !font-medium")
                                    }>
                                    {isOpen && (
                                      <HiOutlineInbox className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                       Invoice Commission Settings 
                                    </span>
                                  </NavLink>
                                </li> */}
                              </ul>
                            </Disclosure.Panel>
                          </Transition>
                        </>
                      )}
                    </Disclosure>
                  </li>
                ) : null}
                {/* )} */}

                {(ParentModuleSubscriptionFinder(
                  PlanModules["InvoiceTemplates"]
                ) &&
                  !expandAll) ||
                  expandAll ? (
                  <Disclosure as="div" defaultOpen={tabclass("InvoicesRes")}>
                    {({ open }) => (
                      <>
                        <Disclosure.Button
                          onClick={(e) => {
                            setCollapsedState(true);
                            dispatch(sidebar_base({ value: true }));
                          }}
                          className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#bb66e1] hover:bg-[#bb66e1]/10 transition-all"
                        >
                          <span className="text-sm font-normal text-inherit flex items-center gap-[12px] justify-center">
                            <LiaFileInvoiceDollarSolid className="text-[#1f7046] shrink-0 text-lg" />
                            <span className="sidebar_text">
                              Automated Invoicing
                            </span>
                          </span>
                          <TiArrowSortedDown
                            className={`${open ? "" : "-rotate-90 transform"
                              } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                          />
                        </Disclosure.Button>
                        <Transition
                          enter="transition duration-300 ease-in-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-300 opacity-300"
                          leave="transition duration-300 ease-in-out"
                          leaveFrom="transform scale-300 opacity-300"
                          leaveTo="transform scale-95 opacity-0"
                        >
                          <Disclosure.Panel
                            className={`${!isOpen && "pl-[25px]"} mt-[4px] `}
                          >
                            <ul className="space-y-2 !list-none">
                              {urlAllow("readlist-of-invoices") &&
                                ((PlanFeatureAvailable("list-of-invoices") &&
                                  !expandAll) ||
                                  expandAll) ? (
                                <li
                                  className={
                                    PlanFeatureAvailable("list-of-invoices")
                                      ? ""
                                      : "pointer-events-none"
                                  }
                                >
                                  <NavLink
                                    to="/reseller-invoices"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#bb66e1] hover:bg-[#bb66e1]/10 !no-underline transition-all " +
                                      ((isActive ||
                                        location.pathname.includes(
                                          "supplier-invoice-detail"
                                        )) &&
                                        " !text-[#bb66e1] !bg-[#bb66e1]/10 !font-medium")
                                    }
                                  >
                                    {isOpen && (
                                      <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      List of Invoices{" "}
                                      {FavUnFavReturn("Reseller Invoices")}
                                      {PlanFeatureAvailable(
                                        "list-of-invoices",
                                        "list-of-invoices"
                                      )}
                                    </span>
                                  </NavLink>
                                </li>
                              ) : null}
                              {true &&
                                urlAllow("readinvoice-templates") &&
                                ((PlanFeatureAvailable("invoice-templates") &&
                                  !expandAll) ||
                                  expandAll) ? (
                                <li
                                  className={
                                    PlanFeatureAvailable("invoice-templates")
                                      ? ""
                                      : "pointer-events-none"
                                  }
                                >
                                  <NavLink
                                    to="/invoice/template"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#bb66e1] hover:bg-[#bb66e1]/10 !no-underline transition-all " +
                                      ((isActive ||
                                        location?.pathname.includes(
                                          "inovoice/template/"
                                        )) &&
                                        " !text-[#bb66e1] !bg-[#bb66e1]/10 !font-medium")
                                    }
                                  >
                                    {isOpen && (
                                      <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Invoice Templates{" "}
                                      {FavUnFavReturn(" Invoice Templates")}
                                      {PlanFeatureAvailable(
                                        "invoice-templates",
                                        "invoice-templates"
                                      )}
                                    </span>
                                  </NavLink>
                                </li>
                              ) : null}
                            </ul>
                          </Disclosure.Panel>
                        </Transition>
                      </>
                    )}
                  </Disclosure>
                ) : null}

                {(PlanFeatureAvailable("contract-templates") && !expandAll) ||
                  expandAll ? (
                  <li
                    className={
                      PlanFeatureAvailable("contract-templates")
                        ? ""
                        : "pointer-events-none"
                    }
                  >
                    <Disclosure as="div" defaultOpen={tabclass("templatescrm")}>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            onClick={(e) => {
                              setCollapsedState(true);
                              dispatch(sidebar_base({ value: true }));
                            }}
                            className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#bb66e1] hover:bg-[#bb66e1]/10 transition-all"
                          >
                            <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                              <RiContractLine className="text-[#1f7046] shrink-0 text-lg" />
                              <span className="sidebar_text">
                                Contracting{" "}
                                {PlanFeatureAvailable(
                                  "contract-templates",
                                  "contract-templates"
                                )}
                              </span>
                            </span>
                            <TiArrowSortedDown
                              className={`${open ? "" : "-rotate-90 transform"
                                } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                            />
                          </Disclosure.Button>
                          <Transition
                            enter="transition duration-300 ease-in-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-300 opacity-300"
                            leave="transition duration-300 ease-in-out"
                            leaveFrom="transform scale-300 opacity-300"
                            leaveTo="transform scale-95 opacity-0"
                          >
                            <Disclosure.Panel
                              className={`${!isOpen && "pl-[25px]"} mt-[4px] `}
                            >
                              <ul className="space-y-2 !list-none">
                                {urlAllow("readcontract-templates") && (
                                  <li
                                    id="/crm"
                                    className={
                                      PlanFeatureAvailable("contract-templates")
                                        ? ""
                                        : "pointer-events-none"
                                    }
                                  >
                                    <NavLink
                                      to="/crm"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#bb66e1] hover:bg-[#bb66e1]/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-[#bb66e1] !bg-[#bb66e1]/10 !font-medium")
                                      }
                                    >
                                      {isOpen && (
                                        <HiOutlineInbox className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Contract Templates{" "}
                                        {FavUnFavReturn("Contract Templates")}
                                      </span>
                                    </NavLink>
                                  </li>
                                )}
                              </ul>
                            </Disclosure.Panel>
                          </Transition>
                        </>
                      )}
                    </Disclosure>
                  </li>
                ) : null}

                {/* {urlAllow("readMarketing") && ( */}
                {(ParentModuleSubscriptionFinder(PlanModules["Marketing"]) &&
                  !expandAll) ||
                  expandAll ? (
                  <li
                    className={
                      ParentModuleSubscriptionFinder(PlanModules["Marketing"])
                        ? ""
                        : "pointer-events-none"
                    }
                  >
                    {" "}
                    <Disclosure as="div" defaultOpen={tabclass("marketing")}>
                      {({ open }) => (
                        <>
                          {ParentModulePermission(PlanModules["Marketing"]) && (
                            <Disclosure.Button
                              onClick={(e) => {
                                setCollapsedState(true);
                                dispatch(sidebar_base({ value: true }));
                              }}
                              className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#ffc800] hover:bg-[#ffc800]/10 transition-all"
                            >
                              <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                                <HiOutlineSpeakerphone className="text-[#ffc800] shrink-0 text-lg" />
                                <span className="sidebar_text">Marketing</span>
                                {ParentModuleSubscriptionFinder(
                                  PlanModules["Marketing"]
                                ) ? null : (
                                  <MdLockOutline />
                                )}
                              </span>
                              <TiArrowSortedDown
                                className={`${open ? "" : "-rotate-90 transform"
                                  } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                              />
                            </Disclosure.Button>
                          )}
                          <Transition
                            enter="transition duration-300 ease-in-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-300 opacity-300"
                            leave="transition duration-300 ease-in-out"
                            leaveFrom="transform scale-300 opacity-300"
                            leaveTo="transform scale-95 opacity-0"
                          >
                            <Disclosure.Panel
                              className={`${!isOpen && "pl-[25px]"} mt-[4px] `}
                            >
                              <ul className="space-y-2 !list-none">
                                {urlAllow("readmarketing-insights") &&
                                  ((PlanFeatureAvailable("marketing-insights") &&
                                    CurrentServer &&
                                    !expandAll) ||
                                    (expandAll)) ? (
                                  <li
                                    id="/marketing/insights"
                                    className={
                                      PlanFeatureAvailable(
                                        "marketing-insights"
                                      )
                                        ? ""
                                        : "pointer-events-none"
                                    }
                                  >
                                    <NavLink
                                      to="/marketing/insights"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#705EE6] hover:bg-[#EDECF9] !no-underline transition-all " +
                                        (isActive &&
                                          " !text-[#705EE6] !bg-[#EDECF9] !font-medium")
                                      }
                                    >
                                      {isOpen && (
                                        <HiOutlineSpeakerphone className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Marketing Insights
                                        {CurrentServer &&
                                          FavUnFavReturn("Google Marketing")}
                                        {(
                                          PlanFeatureAvailable(
                                            "marketing-insights",
                                            "marketing-insights"
                                          )
                                        )}
                                      </span>
                                    </NavLink>
                                  </li>
                                ) : null}

                                {/* 
                              {urlAllow("readforecasting") && (<li

                                id="/forecasting"


                              >
                                <NavLink
                                  to="/forecasting"
                                  className={({ isActive }) =>
                                    "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                    (isActive &&
                                      " !text-[#ffc800] !bg-[#ffc800]/10 !font-medium")
                                  }>
                                  {isOpen && (
                                    <HiOutlineSpeakerphone className="text-inherit shrink-0 text-lg" />
                                  )}
                                  <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                    Forecasting
                                    {CurrentServer && FavUnFavReturn('Google Marketing')}
                                  </span>
                                </NavLink>
                              </li>)} */}

                                {urlAllow("readgoogle-analytics-summary") &&
                                  ((PlanFeatureAvailable(
                                    "google-analytics-summary"
                                  ) &&
                                    !expandAll) ||
                                    (expandAll)) ? (
                                  <li
                                    id="/marketing/google-analytics"
                                    className={
                                      PlanFeatureAvailable(
                                        "google-analytics-summary"
                                      )
                                        ? ""
                                        : "pointer-events-none"
                                    }
                                  >
                                    <NavLink
                                      to="/marketing/google-analytics"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-[#ffc800] !bg-[#ffc800]/10 !font-medium")
                                      }
                                    >
                                      {isOpen && (
                                        <HiOutlineSpeakerphone className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Google Analytics Summary
                                        {CurrentServer &&
                                          FavUnFavReturn("Google Marketing")}
                                        {(
                                          PlanFeatureAvailable(
                                            "google-analytics-summary",
                                            "google-analytics-summary"
                                          )
                                        )}
                                      </span>
                                    </NavLink>
                                  </li>
                                ) : null}
                                {CurrentServer &&
                                  ((PlanFeatureAvailable(
                                    "social-media-marketing"
                                  ) &&
                                    !expandAll) ||
                                    expandAll) ? (
                                  <li
                                    className={
                                      PlanFeatureAvailable(
                                        "social-media-marketing"
                                      )
                                        ? ""
                                        : "pointer-events-none"
                                    }
                                  >
                                    <Disclosure
                                      as="div"
                                      defaultOpen={tabclass("socialMedia")}
                                    >
                                      {({ open }) => (
                                        <>
                                          <Disclosure.Button
                                            onClick={(e) => {
                                              setCollapsedState(true);
                                              dispatch(
                                                sidebar_base({ value: true })
                                              );
                                            }}
                                            className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#ffc800] hover:bg-[#ffc800]/10 transition-all"
                                          >
                                            <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                                              {/* <HiOutlineSpeakerphone className="text-[#ffc800] shrink-0 text-lg" /> */}
                                              <span className="sidebar_text">
                                                Social Media{" "}
                                                {PlanFeatureAvailable(
                                                  "social-media-marketing"
                                                ) ? (
                                                  ""
                                                ) : (
                                                  <MdLockOutline />
                                                )}{" "}
                                              </span>
                                            </span>
                                            <TiArrowSortedDown
                                              className={`${open
                                                ? ""
                                                : "-rotate-90 transform"
                                                } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                                            />
                                          </Disclosure.Button>
                                          <Transition
                                            enter="transition duration-300 ease-in-out"
                                            enterFrom="transform scale-95 opacity-0"
                                            enterTo="transform scale-300 opacity-300"
                                            leave="transition duration-300 ease-in-out"
                                            leaveFrom="transform scale-300 opacity-300"
                                            leaveTo="transform scale-95 opacity-0"
                                          >
                                            <Disclosure.Panel
                                              className={`${!isOpen && "pl-[25px]"
                                                } mt-[4px] `}
                                            >
                                              <ul className="space-y-2 !list-none">
                                                <li id="/social/media">
                                                  <NavLink
                                                    to="/social/media"
                                                    className={({ isActive }) =>
                                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                                      (isActive &&
                                                        " !text-[#ffc800] !bg-[#ffc800]/10 !font-medium")
                                                    }
                                                  >
                                                    {isOpen && (
                                                      <HiOutlineInbox className="text-inherit shrink-0 text-lg" />
                                                    )}
                                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                      Social Media Schedule{" "}
                                                      {FavUnFavReturn(
                                                        "Social Media"
                                                      )}
                                                    </span>
                                                  </NavLink>
                                                </li>
                                              </ul>
                                            </Disclosure.Panel>
                                          </Transition>
                                        </>
                                      )}
                                    </Disclosure>
                                  </li>
                                ) : null}
                                {urlAllow("readgoogle-ads") &&
                                  ((PlanFeatureAvailable("google-ads") &&
                                    !expandAll) ||
                                    expandAll) ? (
                                  <li
                                    className={
                                      PlanFeatureAvailable("google-ads")
                                        ? ""
                                        : "pointer-events-none"
                                    }
                                    id="/marketing/google-ads"
                                  >
                                    <NavLink
                                      to="/marketing/google-ads"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#705EE6] hover:bg-[#EDECF9] !no-underline transition-all " +
                                        (isActive &&
                                          " !text-[#705EE6] !bg-[#EDECF9] !font-medium")
                                      }
                                    >
                                      {isOpen && (
                                        <HiOutlineSpeakerphone className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Google Ads{" "}
                                        {PlanFeatureAvailable(
                                          "google-ads",
                                          "google-ads"
                                        )}
                                        {/* {!CurrentServer && <span className="ml-2 bg-[#FFD6B2]  text-center py-[4px] px-2 !rounded text-[8px] text-[#3C3E49]">
                                        Coming Soon
                                      </span>} */}
                                      </span>
                                    </NavLink>
                                  </li>
                                ) : null}
                                {urlAllow("readfacebook-ads") &&
                                  ((PlanFeatureAvailable("facebook-ads") &&
                                    !expandAll) ||
                                    (expandAll)) ? (
                                  <li
                                    className={
                                      PlanFeatureAvailable("facebook-ads")
                                        ? ""
                                        : "pointer-events-none"
                                    }
                                    id="/google/tags"
                                  >
                                    <NavLink
                                      to="/marketing/facebook-ads"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#705EE6] hover:bg-[#EDECF9] !no-underline transition-all " +
                                        (isActive &&
                                          " !text-[#705EE6] !bg-[#EDECF9] !font-medium")
                                      }
                                    >
                                      {isOpen && (
                                        <HiOutlineSpeakerphone className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Meta Ads{" "}
                                        {PlanFeatureAvailable(
                                          "facebook-ads",
                                          "facebook-ads"
                                        )}

                                      </span>
                                    </NavLink>
                                  </li>
                                ) : null}
                                {urlAllow("readinstagram-analysis") &&
                                  ((PlanFeatureAvailable("instagram-analysis") &&
                                    CurrentServer &&
                                    !expandAll) ||
                                    (expandAll && CurrentServer)) ? (
                                  <li
                                    className={
                                      PlanFeatureAvailable(
                                        "instagram-analysis"
                                      ) && CurrentServer
                                        ? ""
                                        : "pointer-events-none"
                                    }
                                    id="/google/tags"
                                  >
                                    <NavLink
                                      to="/marketing/instagram-analysis"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#705EE6] hover:bg-[#EDECF9] !no-underline transition-all " +
                                        (isActive &&
                                          " !text-[#705EE6] !bg-[#EDECF9] !font-medium")
                                      }
                                    >
                                      {isOpen && (
                                        <HiOutlineSpeakerphone className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Instagram Analysis
                                        {PlanFeatureAvailable(
                                          "instagram-analysis",
                                          "instagram-analysis"
                                        )}
                                      </span>
                                      {!CurrentServer && (
                                        <span className="ml-2 bg-[#FFD6B2]  text-center py-[4px] px-2 !rounded text-[8px] text-[#3C3E49]">
                                          Coming Soon
                                        </span>
                                      )}
                                    </NavLink>
                                  </li>
                                ) : null}

                                {urlAllow("readmanage-google-tags") &&
                                  ((PlanFeatureAvailable("manage-google-tags") &&
                                    !expandAll) ||
                                    expandAll) ? (
                                  <li
                                    className={
                                      PlanFeatureAvailable("manage-google-tags")
                                        ? ""
                                        : "pointer-events-none"
                                    }
                                  >
                                    <NavLink
                                      to="/google/tags"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                        ((isActive ||
                                          window.location.pathname.includes(
                                            "google/tag"
                                          )) &&
                                          " !text-[#ffc800] !bg-[#ffc800]/10 !font-medium")
                                      }
                                    >
                                      {isOpen && (
                                        <HiOutlineSpeakerphone className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Manage Google Tags{" "}
                                        {CurrentServer &&
                                          FavUnFavReturn("Google Tag Manager")}
                                        {PlanFeatureAvailable(
                                          "manage-google-tags",
                                          "manage-google-tags"
                                        )}
                                      </span>
                                    </NavLink>
                                  </li>
                                ) : null}

                                {false && (
                                  <>
                                    <li>
                                      <NavLink
                                        to="/coupons"
                                        className={({ isActive }) =>
                                          "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                          (isActive &&
                                            " !text-[#4A545E] !bg-[#4A545E]/10 !font-medium")
                                        }
                                      >
                                        {isOpen && (
                                          <HiOutlineSpeakerphone className="text-inherit shrink-0 text-lg" />
                                        )}
                                        <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                          Coupons Insights
                                        </span>
                                      </NavLink>
                                    </li>
                                    <li>
                                      <NavLink
                                        to="/coupons-data"
                                        className={({ isActive }) =>
                                          "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#4A545E] hover:bg-[#ffc800] !no-underline transition-all " +
                                          (isActive &&
                                            " !text-[#4A545E] !bg-[#ffc800]/10 !font-medium")
                                        }
                                      >
                                        {isOpen && (
                                          <HiOutlineSpeakerphone className="text-inherit shrink-0 text-lg" />
                                        )}
                                        <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                          Coupons Data
                                        </span>
                                      </NavLink>
                                    </li>
                                  </>
                                )}

                                {CurrentServer &&
                                  ((PlanFeatureAvailable("affiliates") &&
                                    !expandAll) ||
                                    expandAll) ? (
                                  <li
                                    className={
                                      PlanFeatureAvailable("affiliates")
                                        ? ""
                                        : "pointer-events-none"
                                    }
                                  >
                                    {" "}
                                    <Disclosure
                                      as="div"
                                      defaultOpen={tabclass("affiliates")}
                                    >
                                      {({ open }) => (
                                        <>
                                          <Disclosure.Button
                                            onClick={(e) => {
                                              setCollapsedState(true);
                                              dispatch(
                                                sidebar_base({ value: true })
                                              );
                                            }}
                                            className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#ffc800] hover:bg-[#ffc800]/10   transition-all"
                                          >
                                            <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                                              {/* <LuFolderSymlink className="text-primary   shrink-0 text-lg" /> */}
                                              <span className="sidebar_text">
                                                {!user.sidebar ? (
                                                  <>
                                                    Affiliates{" "}
                                                    {PlanFeatureAvailable(
                                                      "affiliates"
                                                    ) ? null : (
                                                      <MdLockOutline />
                                                    )}
                                                  </>
                                                ) : (
                                                  <>
                                                    Comm
                                                    <br />
                                                    Channel
                                                  </>
                                                )}
                                              </span>
                                            </span>

                                            <TiArrowSortedDown
                                              className={`${open
                                                ? ""
                                                : "-rotate-90 transform"
                                                } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                                            />
                                          </Disclosure.Button>
                                          <Transition
                                            enter="transition duration-300 ease-in-out"
                                            enterFrom="transform scale-95 opacity-0"
                                            enterTo="transform scale-300 opacity-300"
                                            leave="transition duration-300 ease-in-out"
                                            leaveFrom="transform scale-300 opacity-300"
                                            leaveTo="transform scale-95 opacity-0"
                                          >
                                            <Disclosure.Panel
                                              className={`${!isOpen && "pl-[25px]"
                                                } mt-[4px] `}
                                            >
                                              <ul className="space-y-2 !list-none">
                                                <li id="/affiliate">
                                                  <NavLink
                                                    to="/affiliate"
                                                    className={({ isActive }) =>
                                                      "p-2.5 rounded-md flex items-center gap-[12px] text-sm font-normal text-[#4A545E] hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                                      (isActive &&
                                                        " !text-[#ffc800] !bg-[#ffc800]/10 !font-medium")
                                                    }
                                                  >
                                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                      {" "}
                                                      Affiliate DataBase{" "}
                                                      {FavUnFavReturn(
                                                        "Affiliate Database"
                                                      )}
                                                    </span>
                                                  </NavLink>
                                                </li>
                                                <li id="/current-affiliate">
                                                  <NavLink
                                                    to="/current-affiliate"
                                                    className={({ isActive }) =>
                                                      "p-2.5 rounded-md flex items-center gap-[12px] text-sm font-normal text-[#4A545E] hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                                      (isActive &&
                                                        " !text-[#ffc800] !bg-[#705EE6]/10 !font-medium")
                                                    }
                                                  >
                                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                      {" "}
                                                      Current Affiliate{" "}
                                                      {FavUnFavReturn(
                                                        "Current Affiliate"
                                                      )}
                                                    </span>
                                                  </NavLink>
                                                </li>
                                                <li id="/request">
                                                  <NavLink
                                                    to="/request"
                                                    className={({ isActive }) =>
                                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                                      (isActive &&
                                                        " !text-[#ffc800] !bg-[#705EE6]/10 !font-medium")
                                                    }
                                                  >
                                                    {isOpen && (
                                                      <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                                    )}
                                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                      Affiliate Request{" "}
                                                      {FavUnFavReturn(
                                                        "Affiliate Request"
                                                      )}
                                                    </span>
                                                  </NavLink>
                                                </li>
                                                <li id={BannerURL.general}>
                                                  <NavLink
                                                    to={BannerURL.general}
                                                    className={({ isActive }) =>
                                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                                      (isActive &&
                                                        " !text-[#ffc800] !bg-[#705EE6]/10 !font-medium")
                                                    }
                                                  >
                                                    {isOpen && (
                                                      <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                                    )}
                                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                      General Banners{" "}
                                                      {FavUnFavReturn(
                                                        "General Banners"
                                                      )}
                                                    </span>
                                                  </NavLink>
                                                </li>

                                                <li id={BannerURL.product}>
                                                  <NavLink
                                                    to={BannerURL.product}
                                                    className={({ isActive }) =>
                                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                                      (isActive &&
                                                        " !text-[#ffc800] !bg-[#ffc800]/10 !font-medium")
                                                    }
                                                  >
                                                    {isOpen && (
                                                      <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                                    )}
                                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                      Products Banners{" "}
                                                      {FavUnFavReturn(
                                                        "Products Banners"
                                                      )}
                                                    </span>
                                                  </NavLink>
                                                </li>

                                                {urlAllow(
                                                  "default-commission"
                                                ) && (
                                                    <li
                                                      className={
                                                        (user?.booking_system_count &&
                                                          user?.booking_system_count) ||
                                                          0 >= ValidationNumber
                                                          ? ""
                                                          : "pointer-events-none"
                                                      }
                                                      id={"/default-commission"}
                                                    >
                                                      <NavLink
                                                        to={"/default-commission"}
                                                        className={({
                                                          isActive,
                                                        }) =>
                                                          "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ff9900] hover:bg-[#ff9900]/10 !no-underline transition-all " +
                                                          (location.pathname.includes(
                                                            `/costing`
                                                          ) ||
                                                            location.pathname ==
                                                            `/default-commission`
                                                            ? " !text-[#ff9900] !bg-[#ff9900]/10 !font-medium"
                                                            : "")
                                                        }
                                                      >
                                                        {isOpen && (
                                                          <FiPackage className="text-inherit shrink-0 text-lg" />
                                                        )}
                                                        <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                          Default Commission{" "}
                                                          {FavUnFavReturn(
                                                            "Default Commission"
                                                          )}
                                                        </span>
                                                      </NavLink>
                                                    </li>
                                                  )}

                                                <li id="/affiliateInbox">
                                                  <NavLink
                                                    to="/affiliateInbox"
                                                    className={({ isActive }) =>
                                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                                      (isActive &&
                                                        " !text-[#ffc800] !bg-[#ffc800]/10 !font-medium")
                                                    }
                                                  >
                                                    {isOpen && (
                                                      <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                                    )}
                                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                      Inbox{" "}
                                                      {FavUnFavReturn(
                                                        "Affiliate Requese"
                                                      )}
                                                    </span>
                                                  </NavLink>
                                                </li>
                                              </ul>
                                            </Disclosure.Panel>
                                          </Transition>
                                        </>
                                      )}
                                    </Disclosure>
                                  </li>
                                ) : null}
                              </ul>
                            </Disclosure.Panel>
                          </Transition>
                        </>
                      )}
                    </Disclosure>
                  </li>
                ) : null}
                {/* )} */}

                <li>
                  <Disclosure as="div" defaultOpen={tabclass("settings")}>
                    {({ open }) => (
                      <>
                        {ParentModulePermission(PlanModules["Settings"]) && (
                          <Disclosure.Button
                            className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#ff9900] hover:bg-[#ff9900]/10 transition-all"
                            onClick={(e) => {
                              setCollapsedState(true);
                              dispatch(sidebar_base({ value: true }));
                              setTimeout(() => {
                                scrollToId("/users");
                              }, 100);
                            }}
                          >
                            <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                              <FiSettings className="text-[#ff9900] shrink-0 text-lg" />
                              <span className="sidebar_text">Settings</span>
                            </span>
                            <TiArrowSortedDown
                              className={`${open ? "" : "-rotate-90 transform"
                                } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                            />
                          </Disclosure.Button>
                        )}
                        <Transition
                          enter="transition duration-500 ease-in-out delay-150"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-300 opacity-300"
                          leave="transition duration-500 ease-in-out delay-150"
                          leaveFrom="transform scale-300 opacity-300"
                          leaveTo="transform scale-95 opacity-0"
                        >
                          <Disclosure.Panel
                            className={`${!isOpen && "pl-[25px]"} mt-[4px] `}
                          >
                            <ul className="space-y-2 !list-none">
                              {urlAllow("readcompany-details") && (
                                <li id="/company">
                                  <NavLink
                                    to="/company"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ff9900] hover:bg-[#ff9900]/10 !no-underline transition-all " +
                                      (isActive &&
                                        " !text-[#ff9900] !bg-[#ff9900]/10 !font-medium")
                                    }
                                  >
                                    {isOpen && (
                                      <FiSettings className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Company Details{" "}
                                      {FavUnFavReturn("Company Details")}
                                    </span>
                                  </NavLink>
                                </li>
                              )}

                              {(user?.subRole &&
                                user?.subRole?.id ==
                                environment.SubRolePartner) ||
                                !user?.partner_status ? null : urlAllow(
                                  "editpartners"
                                ) &&
                                  ((PlanFeatureAvailable("partners") &&
                                    !expandAll) ||
                                    expandAll) ? (
                                <li
                                  id="/partner"
                                  className={
                                    PlanFeatureAvailable("partners")
                                      ? ""
                                      : "pointer-events-none"
                                  }
                                >
                                  <NavLink
                                    to="/partner"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ff9900] hover:bg-[#ff9900]/10 !no-underline transition-all " +
                                      (isActive &&
                                        " !text-[#ff9900] !bg-[#ff9900]/10 !font-medium")
                                    }
                                  >
                                    {isOpen && (
                                      <FiSettings className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Partners {FavUnFavReturn("Partners")}
                                      {PlanFeatureAvailable(
                                        "partners",
                                        "partners"
                                      )}
                                    </span>
                                  </NavLink>
                                </li>
                              ) : null}

                              {/* {urlAllow("readEmailSetting") && ( */}
                              <li
                                className={
                                  (user?.booking_system_count &&
                                    user?.booking_system_count) ||
                                    0 >= ValidationNumber
                                    ? ""
                                    : "pointer-events-none"
                                }
                              >
                                <Disclosure
                                  as="div"
                                  defaultOpen={tabclass("emailSetting")}
                                >
                                  {({ open }) => (
                                    <>
                                      <Disclosure.Button
                                        onClick={(e) => {
                                          setCollapsedState(true);
                                          dispatch(
                                            sidebar_base({ value: true })
                                          );
                                        }}
                                        className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#ff9900] hover:bg-[#ff9900]/10 transition-all"
                                      >
                                        <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                                          {/* <HiOutlineSpeakerphone className="text-[#1f7046] shrink-0 text-lg" /> */}
                                          <span className="sidebar_text">
                                            Email Settings
                                          </span>
                                        </span>
                                        <TiArrowSortedDown
                                          className={`${open ? "" : "-rotate-90 transform"
                                            } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                                        />
                                      </Disclosure.Button>
                                      <Transition
                                        enter="transition duration-300 ease-in-out"
                                        enterFrom="transform scale-95 opacity-0"
                                        enterTo="transform scale-300 opacity-300"
                                        leave="transition duration-300 ease-in-out"
                                        leaveFrom="transform scale-300 opacity-300"
                                        leaveTo="transform scale-95 opacity-0"
                                      >
                                        <Disclosure.Panel
                                          className={`${!isOpen && "pl-[25px]"
                                            } mt-[4px] `}
                                        >
                                          <ul className="space-y-2 !list-none">
                                            {urlAllow("reademail-list") &&
                                              ((PlanFeatureAvailable(
                                                "email-list"
                                              ) &&
                                                !expandAll) ||
                                                expandAll) ? (
                                              <li
                                                id="/email/list"
                                                className={
                                                  PlanFeatureAvailable(
                                                    "email-list"
                                                  )
                                                    ? ""
                                                    : "pointer-events-none"
                                                }
                                              >
                                                <NavLink
                                                  to="/email/list"
                                                  className={({ isActive }) =>
                                                    "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ff9900] hover:bg-[#ff9900]/10 !no-underline transition-all " +
                                                    (isActive &&
                                                      " !text-[#ff9900] !bg-[#ff9900]/10 !font-medium")
                                                  }
                                                >
                                                  {isOpen && (
                                                    <HiOutlineInbox className="text-inherit shrink-0 text-lg" />
                                                  )}
                                                  <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                    Email List{" "}
                                                    {FavUnFavReturn(
                                                      "Email List"
                                                    )}
                                                    {PlanFeatureAvailable(
                                                      "email-list",
                                                      "email-list"
                                                    )}
                                                  </span>
                                                </NavLink>
                                              </li>
                                            ) : null}

                                            {urlAllow("reademail-templates") &&
                                              ((PlanFeatureAvailable(
                                                "email-list"
                                              ) &&
                                                !expandAll) ||
                                                expandAll) ? (
                                              <li
                                                id="/email/templates"
                                                className={
                                                  PlanFeatureAvailable(
                                                    "email-list"
                                                  )
                                                    ? ""
                                                    : "pointer-events-none"
                                                }
                                              >
                                                <NavLink
                                                  to="/email/templates"
                                                  className={({ isActive }) =>
                                                    "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ff9900] hover:bg-[#ff9900]/10 !no-underline transition-all " +
                                                    ((isActive ||
                                                      location.pathname.includes(
                                                        `email/addedit`
                                                      )) &&
                                                      " !text-[#ff9900] !bg-[#ff9900]/10 !font-medium")
                                                  }
                                                >
                                                  {isOpen && (
                                                    <HiOutlineInbox className="text-inherit shrink-0 text-lg" />
                                                  )}
                                                  <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                    Email Templates{" "}
                                                    {FavUnFavReturn(
                                                      "Email Templates"
                                                    )}
                                                    {PlanFeatureAvailable(
                                                      "email-list",
                                                      "email-list"
                                                    )}
                                                  </span>
                                                </NavLink>
                                              </li>
                                            ) : null}
                                          </ul>
                                        </Disclosure.Panel>
                                      </Transition>
                                    </>
                                  )}
                                </Disclosure>
                              </li>
                              {/* )} */}
                              {urlAllow("readdata-connection") &&
                                ((PlanFeatureAvailable("data-connection") &&
                                  !expandAll) ||
                                  expandAll) ? (
                                <li
                                  id="/pos"
                                  className={
                                    PlanFeatureAvailable("data-connection")
                                      ? ""
                                      : "pointer-events-none"
                                  }
                                >
                                  <NavLink
                                    to={DataConnectCheck()}
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ff9900] hover:bg-[#ff9900]/10 !no-underline transition-all " +
                                      ((dataConnection ||
                                        location.pathname.includes("pos") ||
                                        location.pathname?.includes(
                                          "/api/review"
                                        ) ||
                                        location.pathname?.includes("/smtp") ||
                                        location?.pathname ==
                                        "/omni-channel-setting/copy") &&
                                        " !text-[#ff9900] !bg-[#ff9900]/10 !font-medium")
                                    }
                                  >
                                    {isOpen && (
                                      <HiOutlineInbox className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Data connection{" "}
                                      {FavUnFavReturn("Data Connection")}
                                      {PlanFeatureAvailable(
                                        "data-connection",
                                        "data-connection"
                                      )}
                                    </span>
                                  </NavLink>
                                </li>
                              ) : null}
                              {CurrentServer &&
                                urlAllow("readbooking-page-settings") &&
                                ((PlanFeatureAvailable("booking-page-settings") &&
                                  !expandAll) ||
                                  expandAll) ? (
                                <li
                                  id="/booking/setting"
                                  className={
                                    PlanFeatureAvailable(
                                      "booking-page-settings"
                                    )
                                      ? ""
                                      : "pointer-events-none"
                                  }
                                >
                                  <NavLink
                                    to="/booking/setting"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ff9900] hover:bg-[#ff9900]/10 !no-underline transition-all " +
                                      (isActive &&
                                        " !text-[#ff9900] !bg-[#ff9900]/10 !font-medium")
                                    }
                                  >
                                    {isOpen && (
                                      <HiOutlineInbox className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Order Page Settings{" "}
                                      {FavUnFavReturn("Data Connection")}
                                      {PlanFeatureAvailable(
                                        "booking-page-settings",
                                        "booking-page-settings"
                                      )}
                                    </span>
                                  </NavLink>
                                </li>
                              ) : null}
                              {urlAllow("readusers") &&
                                ((PlanFeatureAvailable("users") && !expandAll) ||
                                  expandAll) ? (
                                <li
                                  className={
                                    PlanFeatureAvailable("users")
                                      ? ""
                                      : "pointer-events-none"
                                  }
                                  id="/users"
                                >
                                  <NavLink
                                    to="/users"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ff9900] hover:bg-[#ff9900]/10 !no-underline transition-all " +
                                      (isActive &&
                                        " !text-[#ff9900] !bg-[#ff9900]/10 !font-medium")
                                    }
                                  >
                                    {isOpen && (
                                      <HiOutlineInbox className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Users {FavUnFavReturn("Users")}
                                      {PlanFeatureAvailable("users", "users")}
                                    </span>
                                  </NavLink>
                                </li>
                              ) : null}
                              {urlAllow("readplan") && (
                                <li id="/activeplan">
                                  <NavLink
                                    to={"/activeplan"}
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ff9900] hover:bg-[#ff9900]/10 !no-underline transition-all " +
                                      ((isActive ||
                                        window.location.pathname.includes(
                                          "plans"
                                        )) &&
                                        " !text-[#ff9900] !bg-[#ff9900]/10 !font-medium")
                                    }
                                  >
                                    {isOpen && (
                                      <FiSettings className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Plan
                                      {FavUnFavReturn("Plans")}
                                    </span>
                                  </NavLink>
                                </li>
                              )}
                              {urlAllow("readbilling") && (
                                <li id="/card">
                                  <NavLink
                                    to="/card"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ff9900] hover:bg-[#ff9900]/10 !no-underline transition-all " +
                                      (isActive &&
                                        " !text-[#ff9900] !bg-[#ff9900]/10 !font-medium")
                                    }
                                  >
                                    {isOpen && (
                                      <FiSettings className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Billing {FavUnFavReturn("Billings")}
                                    </span>
                                  </NavLink>
                                </li>
                              )}
                            </ul>
                          </Disclosure.Panel>
                        </Transition>
                      </>
                    )}
                  </Disclosure>
                </li>

                <li
                  className={
                    (user?.booking_system_count &&
                      user?.booking_system_count) ||
                      0 >= ValidationNumber
                      ? ""
                      : "pointer-events-none"
                  }
                ></li>
              </ul>
            )}
            {SidebarLoad || true ? null : (
              <h6
                className={`${isOpen ? "py-[12px] text-center" : "p-[12px]"
                  } text-xs font-medium text-[#7E8B99] mt-[12px]`}
              >
                <span className="sidebar_text"> Add-ons </span>
              </h6>
            )}
            {SidebarLoad ? null : (
              <ul className="space-y-2 !list-none">
                {urlAllow("readMarketing") && false && (
                  <li
                    className={
                      (user?.booking_system_count &&
                        user?.booking_system_count) ||
                        0 >= ValidationNumber
                        ? ""
                        : "pointer-events-none"
                    }
                  >
                    {" "}
                    <Disclosure as="div" defaultOpen={tabclass("marketing")}>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            onClick={(e) => {
                              setCollapsedState(true);
                              dispatch(sidebar_base({ value: true }));
                            }}
                            className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#ffc800] hover:bg-[#ffc800]/10 transition-all"
                          >
                            <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                              <HiOutlineSpeakerphone className="text-[#ffc800] shrink-0 text-lg" />
                              <span className="sidebar_text">Marketing</span>
                            </span>
                            <TiArrowSortedDown
                              className={`${open ? "" : "-rotate-90 transform"
                                } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                            />
                          </Disclosure.Button>
                          <Transition
                            enter="transition duration-300 ease-in-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-300 opacity-300"
                            leave="transition duration-300 ease-in-out"
                            leaveFrom="transform scale-300 opacity-300"
                            leaveTo="transform scale-95 opacity-0"
                          >
                            <Disclosure.Panel
                              className={`${!isOpen && "pl-[25px]"} mt-[4px] `}
                            >
                              <ul className="space-y-2 !list-none">
                                <li
                                  id="/forecasting"
                                // className={`${CurrentServer ? "" : "pointer-events-none"
                                //   } `}
                                >
                                  <NavLink
                                    to="/forecasting"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                      (isActive &&
                                        " !text-[#ffc800] !bg-[#ffc800]/10 !font-medium")
                                    }
                                  >
                                    {isOpen && (
                                      <HiOutlineSpeakerphone className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Forecasting
                                      {CurrentServer &&
                                        FavUnFavReturn("Google Marketing")}
                                      {/* {CurrentServer ? null : (
                                        <span className="ml-2 bg-[#FFD6B2]  text-center py-[4px] px-2 !rounded text-[8px] text-[#3C3E49]">
                                          Coming Soon
                                        </span>
                                      )} */}
                                    </span>
                                  </NavLink>
                                </li>
                                <li
                                  id="/marketing/google-analytics"
                                  className={`${CurrentServer ? "" : "pointer-events-none"
                                    } `}
                                >
                                  <NavLink
                                    to="/marketing/google-analytics"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                      (isActive &&
                                        " !text-[#ffc800] !bg-[#ffc800]/10 !font-medium")
                                    }
                                  >
                                    {isOpen && (
                                      <HiOutlineSpeakerphone className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Google Analytics Summary
                                      {CurrentServer &&
                                        FavUnFavReturn("Google Marketing")}
                                      {CurrentServer ? null : (
                                        <span className="ml-2 bg-[#FFD6B2]  text-center py-[4px] px-2 !rounded text-[8px] text-[#3C3E49]">
                                          Coming Soon
                                        </span>
                                      )}
                                    </span>
                                  </NavLink>
                                </li>
                                {CurrentServer && (
                                  <li>
                                    <Disclosure
                                      as="div"
                                      defaultOpen={tabclass("socialMedia")}
                                    >
                                      {({ open }) => (
                                        <>
                                          <Disclosure.Button
                                            onClick={(e) => {
                                              setCollapsedState(true);
                                              dispatch(
                                                sidebar_base({ value: true })
                                              );
                                            }}
                                            className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#ffc800] hover:bg-[#ffc800]/10 transition-all"
                                          >
                                            <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                                              {/* <HiOutlineSpeakerphone className="text-[#ffc800] shrink-0 text-lg" /> */}
                                              <span className="sidebar_text">
                                                Social Media
                                              </span>
                                            </span>
                                            <TiArrowSortedDown
                                              className={`${open
                                                ? ""
                                                : "-rotate-90 transform"
                                                } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                                            />
                                          </Disclosure.Button>
                                          <Transition
                                            enter="transition duration-300 ease-in-out"
                                            enterFrom="transform scale-95 opacity-0"
                                            enterTo="transform scale-300 opacity-300"
                                            leave="transition duration-300 ease-in-out"
                                            leaveFrom="transform scale-300 opacity-300"
                                            leaveTo="transform scale-95 opacity-0"
                                          >
                                            <Disclosure.Panel
                                              className={`${!isOpen && "pl-[25px]"
                                                } mt-[4px] `}
                                            >
                                              <ul className="space-y-2 !list-none">
                                                <li id="/social/media">
                                                  <NavLink
                                                    to="/social/media"
                                                    className={({ isActive }) =>
                                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                                      (isActive &&
                                                        " !text-[#ffc800] !bg-[#ffc800]/10 !font-medium")
                                                    }
                                                  >
                                                    {isOpen && (
                                                      <HiOutlineInbox className="text-inherit shrink-0 text-lg" />
                                                    )}
                                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                      Social Media Schedule{" "}
                                                      {FavUnFavReturn(
                                                        "Social Media"
                                                      )}
                                                    </span>
                                                  </NavLink>
                                                </li>
                                              </ul>
                                            </Disclosure.Panel>
                                          </Transition>
                                        </>
                                      )}
                                    </Disclosure>
                                  </li>
                                )}
                                <li className="" id="/marketing/google-ads">
                                  <NavLink
                                    to="/marketing/google-ads"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#705EE6] hover:bg-[#EDECF9] !no-underline transition-all " +
                                      (isActive &&
                                        " !text-[#705EE6] !bg-[#EDECF9] !font-medium")
                                    }
                                  >
                                    {isOpen && (
                                      <HiOutlineSpeakerphone className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Google Ads{" "}
                                    </span>
                                  </NavLink>
                                </li>
                                <li
                                  className="pointer-events-none"
                                  id="/google/tags"
                                >
                                  <NavLink
                                    to="/marketing/facebook-ads"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#705EE6] hover:bg-[#EDECF9] !no-underline transition-all " +
                                      (isActive &&
                                        " !text-[#705EE6] !bg-[#EDECF9] !font-medium")
                                    }
                                  >
                                    {isOpen && (
                                      <HiOutlineSpeakerphone className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Facebook Ads{" "}
                                      {!CurrentServer && (
                                        <span className="ml-2 bg-[#FFD6B2]  text-center py-[4px] px-2 !rounded text-[8px] text-[#3C3E49]">
                                          Coming Soon
                                        </span>
                                      )}
                                    </span>
                                  </NavLink>
                                </li>

                                <li>
                                  <NavLink
                                    to="/google/tags"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                      ((isActive ||
                                        window.location.pathname.includes(
                                          "google/tag"
                                        )) &&
                                        " !text-[#ffc800] !bg-[#ffc800]/10 !font-medium")
                                    }
                                  >
                                    {isOpen && (
                                      <HiOutlineSpeakerphone className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Manage Google Tags{" "}
                                      {CurrentServer &&
                                        FavUnFavReturn("Google Tag Manager")}
                                    </span>
                                  </NavLink>
                                </li>

                                {false && (
                                  <>
                                    <li>
                                      <NavLink
                                        to="/coupons"
                                        className={({ isActive }) =>
                                          "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                          (isActive &&
                                            " !text-[#4A545E] !bg-[#4A545E]/10 !font-medium")
                                        }
                                      >
                                        {isOpen && (
                                          <HiOutlineSpeakerphone className="text-inherit shrink-0 text-lg" />
                                        )}
                                        <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                          Coupons Insights
                                        </span>
                                      </NavLink>
                                    </li>
                                    <li>
                                      <NavLink
                                        to="/coupons-data"
                                        className={({ isActive }) =>
                                          "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#4A545E] hover:bg-[#ffc800] !no-underline transition-all " +
                                          (isActive &&
                                            " !text-[#4A545E] !bg-[#ffc800]/10 !font-medium")
                                        }
                                      >
                                        {isOpen && (
                                          <HiOutlineSpeakerphone className="text-inherit shrink-0 text-lg" />
                                        )}
                                        <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                          Coupons Data
                                        </span>
                                      </NavLink>
                                    </li>
                                  </>
                                )}

                                <li
                                  className={
                                    (user?.booking_system_count &&
                                      user?.booking_system_count) ||
                                      0 >= ValidationNumber
                                      ? ""
                                      : "pointer-events-none"
                                  }
                                >
                                  {" "}
                                  <Disclosure
                                    as="div"
                                    defaultOpen={tabclass("affiliates")}
                                  >
                                    {({ open }) => (
                                      <>
                                        <Disclosure.Button
                                          onClick={(e) => {
                                            setCollapsedState(true);
                                            dispatch(
                                              sidebar_base({ value: true })
                                            );
                                          }}
                                          className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#ffc800] hover:bg-[#ffc800]/10   transition-all"
                                        >
                                          <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                                            {/* <LuFolderSymlink className="text-primary   shrink-0 text-lg" /> */}
                                            <span className="sidebar_text">
                                              {!user.sidebar ? (
                                                "Affiliates"
                                              ) : (
                                                <>
                                                  Comm
                                                  <br />
                                                  Channel
                                                </>
                                              )}
                                            </span>
                                          </span>

                                          <TiArrowSortedDown
                                            className={`${open ? "" : "-rotate-90 transform"
                                              } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                                          />
                                        </Disclosure.Button>
                                        <Transition
                                          enter="transition duration-300 ease-in-out"
                                          enterFrom="transform scale-95 opacity-0"
                                          enterTo="transform scale-300 opacity-300"
                                          leave="transition duration-300 ease-in-out"
                                          leaveFrom="transform scale-300 opacity-300"
                                          leaveTo="transform scale-95 opacity-0"
                                        >
                                          <Disclosure.Panel
                                            className={`${!isOpen && "pl-[25px]"
                                              } mt-[4px] `}
                                          >
                                            <ul className="space-y-2 !list-none">
                                              {urlAllow(
                                                "readaffiliate-dataBase"
                                              ) && (
                                                  <li id="/affiliate">
                                                    <NavLink
                                                      to="/affiliate"
                                                      className={({ isActive }) =>
                                                        "p-2.5 rounded-md flex items-center gap-[12px] text-sm font-normal text-[#4A545E] hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                                        (isActive &&
                                                          " !text-[#ffc800] !bg-[#ffc800]/10 !font-medium")
                                                      }
                                                    >
                                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                        {" "}
                                                        Affiliate DataBase{" "}
                                                        {FavUnFavReturn(
                                                          "Affiliate Database"
                                                        )}
                                                      </span>
                                                    </NavLink>
                                                  </li>
                                                )}
                                              {urlAllow(
                                                "readcurrent-affiliate"
                                              ) && (
                                                  <li id="/current-affiliate">
                                                    <NavLink
                                                      to="/current-affiliate"
                                                      className={({ isActive }) =>
                                                        "p-2.5 rounded-md flex items-center gap-[12px] text-sm font-normal text-[#4A545E] hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                                        (isActive &&
                                                          " !text-[#ffc800] !bg-[#705EE6]/10 !font-medium")
                                                      }
                                                    >
                                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                        {" "}
                                                        Current Affiliate{" "}
                                                        {FavUnFavReturn(
                                                          "Current Affiliate"
                                                        )}
                                                      </span>
                                                    </NavLink>
                                                  </li>
                                                )}
                                              {urlAllow(
                                                "readaffiliate-request"
                                              ) && (
                                                  <li id="/request">
                                                    <NavLink
                                                      to="/request"
                                                      className={({ isActive }) =>
                                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                                        (isActive &&
                                                          " !text-[#ffc800] !bg-[#705EE6]/10 !font-medium")
                                                      }
                                                    >
                                                      {isOpen && (
                                                        <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                                      )}
                                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                        Affiliate Request{" "}
                                                        {FavUnFavReturn(
                                                          "Affiliate Request"
                                                        )}
                                                      </span>
                                                    </NavLink>
                                                  </li>
                                                )}
                                              {urlAllow(
                                                "readgeneral-banners"
                                              ) && (
                                                  <li id={BannerURL.general}>
                                                    <NavLink
                                                      to={BannerURL.general}
                                                      className={({ isActive }) =>
                                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                                        (isActive &&
                                                          " !text-[#ffc800] !bg-[#705EE6]/10 !font-medium")
                                                      }
                                                    >
                                                      {isOpen && (
                                                        <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                                      )}
                                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                        General Banners{" "}
                                                        {FavUnFavReturn(
                                                          "General Banners"
                                                        )}
                                                      </span>
                                                    </NavLink>
                                                  </li>
                                                )}

                                              {urlAllow(
                                                "readproducts-banners"
                                              ) && (
                                                  <li id={BannerURL.product}>
                                                    <NavLink
                                                      to={BannerURL.product}
                                                      className={({ isActive }) =>
                                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                                        (isActive &&
                                                          " !text-[#ffc800] !bg-[#ffc800]/10 !font-medium")
                                                      }
                                                    >
                                                      {isOpen && (
                                                        <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                                      )}
                                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                        Products Banners{" "}
                                                        {FavUnFavReturn(
                                                          "Products Banners"
                                                        )}
                                                      </span>
                                                    </NavLink>
                                                  </li>
                                                )}

                                              {urlAllow(
                                                "readdefault-commission"
                                              ) && (
                                                  <li
                                                    className={
                                                      (user?.booking_system_count &&
                                                        user?.booking_system_count) ||
                                                        0 >= ValidationNumber
                                                        ? ""
                                                        : "pointer-events-none"
                                                    }
                                                    id={"/default-commission"}
                                                  >
                                                    <NavLink
                                                      to={"/default-commission"}
                                                      className={({ isActive }) =>
                                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ff9900] hover:bg-[#ff9900]/10 !no-underline transition-all " +
                                                        (location.pathname.includes(
                                                          `/costing`
                                                        ) ||
                                                          location.pathname ==
                                                          `/default-commission`
                                                          ? " !text-[#ff9900] !bg-[#ff9900]/10 !font-medium"
                                                          : "")
                                                      }
                                                    >
                                                      {isOpen && (
                                                        <FiPackage className="text-inherit shrink-0 text-lg" />
                                                      )}
                                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                        Default Commission{" "}
                                                        {FavUnFavReturn(
                                                          "Default Commission"
                                                        )}
                                                      </span>
                                                    </NavLink>
                                                  </li>
                                                )}

                                              {urlAllow("readinbox") && (
                                                <li id="/affiliateInbox">
                                                  <NavLink
                                                    to="/affiliateInbox"
                                                    className={({ isActive }) =>
                                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                                      (isActive &&
                                                        " !text-[#ffc800] !bg-[#ffc800]/10 !font-medium")
                                                    }
                                                  >
                                                    {isOpen && (
                                                      <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                                    )}
                                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                      Inbox{" "}
                                                      {FavUnFavReturn(
                                                        "Affiliate Requese"
                                                      )}
                                                    </span>
                                                  </NavLink>
                                                </li>
                                              )}
                                            </ul>
                                          </Disclosure.Panel>
                                        </Transition>
                                      </>
                                    )}
                                  </Disclosure>
                                </li>
                              </ul>
                            </Disclosure.Panel>
                          </Transition>
                        </>
                      )}
                    </Disclosure>
                  </li>
                )}

                {CurrentServer && false && urlAllow("readOmniChannel") ? (
                  <>
                    <li
                      className={
                        (user?.booking_system_count &&
                          user?.booking_system_count) ||
                          0 >= ValidationNumber
                          ? ""
                          : "pointer-events-none"
                      }
                    >
                      {" "}
                      <Disclosure as="div" defaultOpen={tabclass("reports")}>
                        {({ open }) => (
                          <>
                            <Disclosure.Button
                              onClick={(e) => {
                                setCollapsedState(true);
                                dispatch(sidebar_base({ value: true }));
                              }}
                              className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-primary   hover:bg-primary/10 transition-all"
                            >
                              <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                                <TbFileReport className="text-primary   shrink-0 text-lg" />
                                <span className="sidebar_text">
                                  {!user.sidebar ? (
                                    "Reports"
                                  ) : (
                                    <>
                                      Comm
                                      <br />
                                      Channel
                                    </>
                                  )}
                                </span>
                              </span>

                              <TiArrowSortedDown
                                className={`${open ? "" : "-rotate-90 transform"
                                  } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                              />
                            </Disclosure.Button>
                            <Transition
                              enter="transition duration-300 ease-in-out"
                              enterFrom="transform scale-95 opacity-0"
                              enterTo="transform scale-300 opacity-300"
                              leave="transition duration-300 ease-in-out"
                              leaveFrom="transform scale-300 opacity-300"
                              leaveTo="transform scale-95 opacity-0"
                            >
                              <Disclosure.Panel
                                className={`${!isOpen && "pl-[25px]"
                                  } mt-[4px] `}
                              >
                                <ul className="space-y-2 !list-none">
                                  <li id="/transactions">
                                    <NavLink
                                      to="/transactions"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md flex items-center gap-[12px] text-sm font-normal text-[#4A545E] hover:!text-primary   hover:bg-primary/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-primary   !bg-[#705EE6]/10 !font-medium")
                                      }
                                    >
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        {" "}
                                        Transactions{" "}
                                        {FavUnFavReturn("Transactions")}
                                      </span>
                                    </NavLink>
                                  </li>
                                  <li id="/report/sales">
                                    <NavLink
                                      to="/report/sales"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md flex items-center gap-[12px] text-sm font-normal text-[#4A545E] hover:!text-primary   hover:bg-primary/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-primary   !bg-[#705EE6]/10 !font-medium")
                                      }
                                    >
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        {" "}
                                        Sales {FavUnFavReturn("Sales")}
                                      </span>
                                    </NavLink>
                                  </li>
                                  <li id="/report/affiliates">
                                    <NavLink
                                      to="/report/affiliates"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-primary   hover:bg-primary/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-primary   !bg-primary/10 !font-medium")
                                      }
                                    >
                                      {isOpen && (
                                        <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Affiliates{" "}
                                        {FavUnFavReturn("Affiliates")}
                                      </span>
                                    </NavLink>
                                  </li>
                                  <li id="/report/customers">
                                    <NavLink
                                      to="/report/customers"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-primary   hover:bg-primary/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-primary   !bg-primary/10 !font-medium")
                                      }
                                    >
                                      {isOpen && (
                                        <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Customers{" "}
                                        {FavUnFavReturn("Affiliate Customers")}
                                      </span>
                                    </NavLink>
                                  </li>
                                </ul>
                              </Disclosure.Panel>
                            </Transition>
                          </>
                        )}
                      </Disclosure>
                    </li>
                  </>
                ) : (
                  <></>
                )}
              </ul>
            )}
          </>
        ) : (
          <>
            <>
              <ul className="space-y-2 !list-none">
                <li id="/company">
                  <NavLink
                    to={
                      user?.role?.name == "Affiliate"
                        ? "/affiliate/profile"
                        : "/company"
                    }
                    className={({ isActive }) =>
                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ff9900] hover:bg-[#ff9900]/10 !no-underline transition-all " +
                      (isActive &&
                        " !text-[#ff9900] !bg-[#ff9900]/10 !font-medium")
                    }
                  >
                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                      {user?.role?.name == "Affiliate"
                        ? "Profile"
                        : "Company Settings"}
                    </span>
                  </NavLink>
                </li>
                {CurrentServer && user?.role?.name == "Affiliate" && (
                  <li className="">
                    <Disclosure as="div" defaultOpen={tabclass("affiliates")}>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            onClick={(e) => {
                              setCollapsedState(true);
                              dispatch(sidebar_base({ value: true }));
                            }}
                            className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#7b68ee] hover:bg-[#7b68ee]/10 transition-all"
                          >
                            <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                              <IoChatboxEllipsesOutline className="text-[#7b68ee] shrink-0 text-lg" />
                              <span className="sidebar_text">
                                {!user.sidebar ? (
                                  "Affiliate Section"
                                ) : (
                                  <>
                                    Comm
                                    <br />
                                    Channel
                                  </>
                                )}
                              </span>
                            </span>
                            <TiArrowSortedDown
                              className={`${open ? "" : "-rotate-90 transform"
                                } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                            />
                          </Disclosure.Button>
                          <Transition
                            enter="transition duration-300 ease-in-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-300 opacity-300"
                            leave="transition duration-300 ease-in-out"
                            leaveFrom="transform scale-300 opacity-300"
                            leaveTo="transform scale-95 opacity-0"
                          >
                            <Disclosure.Panel
                              className={`${!isOpen && "pl-[25px]"} mt-[4px] `}
                            >
                              <ul className="space-y-2 !list-none"></ul>
                            </Disclosure.Panel>
                          </Transition>
                        </>
                      )}
                    </Disclosure>
                  </li>
                )}

                {user?.companyName && !(user?.bokun_user && (user.contacts.length < 1)) ? (
                  <li id="/activeplan">
                    <NavLink
                      to="/plans"
                      className={({ isActive }) =>
                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ff9900] hover:bg-[#ff9900]/10 !no-underline transition-all " +
                        (isActive &&
                          " !text-[#ff9900] !bg-[#ff9900]/10 !font-medium")
                      }
                    >
                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                        Plan
                      </span>
                    </NavLink>
                  </li>
                ) : null}
              </ul>
            </>
          </>
        )}
      </div>
    </>
  );
};

export default Html;
